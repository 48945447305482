<template>
  <div class="container">
    <span>Warehouse</span>
    <div>
      <button
        @click="openModal()"
        class="button is-primary is-medium has-sherpa"
      >
        Open Add Kolli
      </button>
    </div>

    <AddKolli :show="showModal" @close="showModal = false" />
  </div>
</template>
<script>
import AddKolli from "@/components/warehouse/AddKolli.vue";

export default {
  components: {
    AddKolli,
  },
  name: "WareHouse",
  props: {},
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
  },
};
</script>
