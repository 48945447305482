<template>
  <LoaderComponent v-if="showLoader" @escape="showLoader = false" />
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered ml-0">
        <div class="column p-0 register-padding">
          <FraktBestillingLogo />
          <h1
            class="title is-size-2 has-text-mosque"
            style="font-size: 44px !important; margin-bottom: 44px !important"
          >
            {{ $t("login.title") }}<span class="font-light">.no</span>
          </h1>
          <p class="technical18">
            {{ $t("register.subtitle") }}
          </p>
          <div class="has-text-black mt-12" style="height: 51px">
            <p class="float-left body-text-title auth-body-title-login mt-12px">
              {{ $t("register.login_text") }}
            </p>
            <router-link to="/login" class="float-right">
              <button
                class="button-medium-empty-mint body-text-small-title ml-45"
              >
                <span class="sidebaricon">
                  <img
                    src="@/assets/icons/PNG/key.png"
                    style="
                      margin-top: -2px;
                      vertical-align: middle;
                      position: relative;
                    "
                    @mouseover="show = true"
                    @mouseleave="show = false"
                    alt="info"
                  />
                </span>
                {{ $t("login.login") }}
              </button>
            </router-link>
          </div>
          <form @submit.prevent>
            <div class="columns mt-large ml-0 mr-0">
              <div class="column pl-0">
                <div class="field">
                  <label class="label">{{ $t("register.company_name") }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="form.companyName"
                      :placeholder="$t('register.placeholder_company_name')"
                      :class="{ redBorder: v$.form.companyName.$error }"
                      @blur="v$.form.companyName.$touch"
                    />
                  </div>
                  <span v-if="v$.form.companyName.$error" class="field-icon">
                    <i class="fas fa-exclamation-circle is-red"></i>
                  </span>
                  <p class="help">
                    <span v-if="v$.form.companyName.$error"
                      >{{ $t("required_field") }}
                    </span>
                  </p>
                </div>
                <div class="field">
                  <label class="label">{{ $t("register.vatnr") }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      ref="organizationNumber"
                      @keydown.space.prevent
                      v-model="form.organizationNumber"
                      :placeholder="$t('register.placeholder_vatnr')"
                      :class="{
                        redBorder:
                          !this.validOrgNumber ||
                          v$.form.organizationNumber.$error,
                      }"
                      @blur="checkOrganizationNumber"
                    />
                  </div>
                  <span
                    v-if="
                      !this.validOrgNumber || v$.form.organizationNumber.$error
                    "
                    class="field-icon"
                  >
                    <i class="fas fa-exclamation-circle is-red"></i>
                  </span>
                  <p class="help">
                    <span
                      v-if="
                        !this.validOrgNumber ||
                        v$.form.organizationNumber.$error
                      "
                      >{{ $t("required_field") }}
                    </span>
                  </p>
                </div>
                <div class="field w-half-desktop">
                  <label class="label">{{ $t("register.first_name") }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="form.firstName"
                      :placeholder="$t('register.placeholder_first_name')"
                      :class="{ redBorder: v$.form.firstName.$error }"
                      @blur="v$.form.firstName.$touch"
                    />
                  </div>
                  <span v-if="v$.form.firstName.$error" class="field-icon">
                    <i class="fas fa-exclamation-circle is-red"></i>
                  </span>
                  <p class="help">
                    <span v-if="v$.form.firstName.$error"
                      >{{ $t("required_field") }}
                    </span>
                  </p>
                </div>
                <div class="field float-right w-half-desktop">
                  <label class="label">{{ $t("register.last_name") }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="form.lastName"
                      :placeholder="$t('register.placeholder_last_name')"
                      :class="{ redBorder: v$.form.lastName.$error }"
                      @blur="v$.form.lastName.$touch"
                    />
                  </div>
                  <span v-if="v$.form.lastName.$error" class="field-icon">
                    <i class="fas fa-exclamation-circle is-red"></i>
                  </span>
                  <p class="help">
                    <span v-if="v$.form.lastName.$error"
                      >{{ $t("required_field") }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="column padding-mobile-0 pr-0">
                <div class="field email">
                  <label class="label emaillabel">{{
                    $t("register.personal_email")
                  }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="form.email"
                      :placeholder="$t('register.placeholder_personal_email')"
                      :class="{ redBorder: v$.form.email.$error }"
                      @blur="v$.form.email.$touch"
                    />
                  </div>
                  <span v-if="v$.form.email.$error" class="field-icon">
                    <i class="fas fa-exclamation-circle is-red"></i>
                  </span>
                  <p class="help">
                    <span v-if="v$.form.email.$error">{{
                      $t("required_field")
                    }}</span>
                  </p>
                </div>
                <div class="field prefix">
                  <label class="label">{{ $t("register.country_code") }}</label>
                  <div class="control dropdown-trigger">
                    <input
                      class="input"
                      type="text"
                      v-model="form.prefix"
                      :placeholder="$t('addressbook.searchinput')"
                      @click="toggleDropdown()"
                      readonly
                    />
                  </div>
                  <div name="arrowBox" class="arrowBox">
                    <img
                      src="@/assets/icons/PNG/chevron-right.png"
                      alt="right"
                    />
                  </div>
                  <div
                    role="menu"
                    class="dropdown-show-options"
                    v-if="showDropdown"
                  >
                    <div
                      class="dropdown-content prefix-content box-shadow-unset"
                      v-for="(country, i) in countrycodes"
                      :key="i"
                    >
                      <div
                        class="dropdown-item columns mt-0 mb-0"
                        @click="selectPrefix(country.dial_code)"
                      >
                        <div class="column">
                          <span class="body-text-small text-black">{{
                            country.name
                          }}</span>
                          <span class="body-text-small text-gray ml-10px">{{
                            country.dial_code
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field phonenumber">
                  <label class="label">{{
                    $t("register.personal_phone")
                  }}</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="form.mobile"
                      :class="{
                        redBorder: !this.validPhone || v$.form.mobile.$error,
                      }"
                      :placeholder="$t('register.placeholder_personal_phone')"
                      @blur="checkPhone"
                    />
                  </div>
                  <span
                    v-if="!this.validPhone || v$.form.mobile.$error"
                    class="field-icon"
                  >
                    <i class="fas fa-exclamation-circle is-red"></i>
                  </span>
                  <p class="help">
                    <span v-if="!this.validPhone || v$.form.mobile.$error">{{
                      $t("required_field")
                    }}</span>
                  </p>
                </div>
                <div class="field">
                  <label class="label"
                    >{{ $t("register.password") }}
                    <ToolTip
                      vertical="top"
                      horizontal="left"
                      theme="light"
                      :text="$t('register.password_tooltip')"
                  /></label>
                  <div class="control">
                    <input
                      id="password"
                      class="input"
                      type="password"
                      v-model="form.password"
                      :placeholder="$t('register.placeholder_password')"
                      :class="{ redBorder: v$.form.password.$error }"
                      @blur="v$.form.password.$touch"
                      autocomplete="false"
                    />
                  </div>
                  <span class="field-icon">
                    <i
                      id="eye"
                      class="fas fa-eye"
                      @click="showHidePassword()"
                    ></i>
                  </span>
                  <p class="help">
                    <span v-if="v$.form.password.$error"
                      >{{ $t("required_field") }}
                    </span>
                  </p>
                </div>
                <div class="field extrakey">
                  <label class="label">Extra key</label>
                  <div class="control">
                    <input
                      id="key"
                      class="input"
                      type="text"
                      v-model="extrakey"
                      placeholder="Type a safe key"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="tos-reg">
              <div class="field reg-mobile-hr">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    class="sq-25"
                    @change="v$.form.$touch()"
                    v-model="form.tos"
                  />
                </label>
                <p style="margin-top: -33px; margin-left: 43px; color: #66a5ad">
                  {{ $t("register.tos") }}
                  <router-link
                    to="/tos"
                    style="color: #66a5ad !important"
                    target="_blank"
                    ><span class="underline">{{
                      $t("register.tos_link")
                    }}</span></router-link
                  >
                </p>
              </div>
            </div>
            <div class="has-text-right">
              <button
                style="margin-top: 17px; margin-right: 15px"
                class="button-medium"
                :class="{ 'is-loading': loading }"
                @click="register()"
                :disabled="
                  this.v$.form.$invalid || !this.form.tos || !validOrgNumber
                "
              >
                <span class="sidebaricon">
                  <img
                    src="@/assets/icons/PNG/user-white.png"
                    style="
                      margin-top: -2px;
                      vertical-align: middle;
                      position: relative;
                    "
                    @mouseover="show = true"
                    @mouseleave="show = false"
                    alt="info"
                  />
                </span>
                {{ $t("register.button_register") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FraktBestillingLogo from "@/components/FraktBestillingLogo.vue";
import useVuelidate from "@vuelidate/core";
import ToolTip from "@/components/ToolTip.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "@vuelidate/validators";
import countrycodes from "@/countrycodes.json";
import notification from "@/notification.js";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
export default {
  name: "RegisterUser",
  components: {
    FraktBestillingLogo,
    ToolTip,
    LoaderComponent,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      countrycodes,
      isFocusDel: false,
      showDropdown: false,
      selectedPrefix: "+47",
      showLoader: false,
      extrakey: "",
      form: {
        companyName: "",
        organizationNumber: "",
        // organizationNumber: "922924368",
        firstName: "",
        lastName: "",
        email: "",
        prefix: "+47",
        mobile: "",
        password: "",
        tos: false,
      },
      validOrgNumber: true,
      validPhone: true,
      verifiedPhone: false,
      loading: false,
      message: "",
    };
  },
  validations() {
    return {
      form: {
        companyName: {
          required,
          min: minLength(3),
        },
        organizationNumber: {
          required,
          numeric,
          min: minLength(9),
          max: maxLength(9),
        },
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
        },
        mobile: {
          required,
          numeric,
        },
        password: {
          required,
          min: minLength(8),
          valid(value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /\d/.test(value);
            // const containsSpecial = /[#?!@$%^&*-]/.test(value);
            return containsUppercase && containsLowercase && containsNumber;
          },
        },
      },
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectPrefix(prefix) {
      this.selectedPrefix = prefix;
      this.form.prefix = prefix;
      this.showDropdown = false;
    },
    showHidePassword() {
      const x = document.getElementById("password");
      const eye = document.getElementById("eye");
      eye.classList.toggle("fa-eye");
      eye.classList.toggle("fa-eye-slash");
      x.type = x.type === "text" ? "password" : "text";
    },
    async checkOrganizationNumber() {
      this.v$.form.organizationNumber.$touch();
      this.form.organizationNumber.replace(/^\s+|\s+$/gm, "");
      let apiRes = {};
      if (this.form.organizationNumber.length === 9) {
        try {
          apiRes = await this.axios.get(
            `https://data.brreg.no/enhetsregisteret/api/enheter/${this.form.organizationNumber}`
          );
        } catch (err) {
          apiRes = err.response;
          this.validOrgNumber = false;
        } finally {
          if (apiRes.status === 200) {
            this.validOrgNumber = true;
          } else {
            this.validOrgNumber = false;
            notification.toast("error", this.$t("register.wrong_org_number"));
          }
        }
      } else {
        this.v$.form.organizationNumber.$touch();
        //this.$refs.organizationNumber.focus();
      }
    },
    async checkPhone() {
      const that = this;
      const prefix = this.selectedPrefix
        ? this.selectedPrefix
        : this.form.prefix;
      const fullnumber = prefix + this.form.mobile;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      };
      let isnum = /^\d+$/.test(this.form.mobile);
      if (this.form.mobile.length > 2 && isnum) {
        this.axios
          .get(
            `https://authentication.${API_ENV}.${API_URL}/authentication/validate/phone/${fullnumber}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status_message === "Success") {
              this.validPhone = true;
              this.verifiedPhone = true;
            } else {
              this.validPhone = false;
              this.v$.form.organizationNumber.$touch();
              if (this.loading) {
                notification.toast("error", that.$t("register.wrong_phone"));
                this.loading = false;
              }
            }
          });
      }
      if (!isnum) {
        this.validPhone = false;
      }
    },
    register() {
      this.loading = true;
      this.showLoader = true;
      const that = this;
      const prefix = this.selectedPrefix.dial_code
        ? this.selectedPrefix.dial_code
        : this.form.prefix;
      this.form.prefix = prefix;
      this.checkOrganizationNumber();
      if (
        !this.v$.form.$invalid &&
        this.validOrgNumber &&
        this.extrakey.length < 1
      ) {
        const form = {
          companyName: this.form.companyName,
          organizationNumber: this.form.organizationNumber,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          prefix: this.form.prefix,
          mobile: this.form.mobile,
          password: this.form.password,
        };
        this.$store.dispatch("auth/register", form).then(
          (data) => {
            notification.toast("success", that.$t("register.toast_success"));
            that.message = data.message;
            that.$router.push({ path: "/login" });
            that.loading = false;
          },
          (error) => {
            that.showLoader = false;
            that.message =
              error.response &&
              error.response.data &&
              error.response.data.message;
            error.message || error.toString();

            if (that.message == "Network Error") {
              notification.toast("error", that.$t("login.toast_network_error"));
            }
            // else if (that.message == "email exists") {
            //   notification.toast("error", "IT EXISTS ALREADY AN ACCOUNT ASSOCIATED TO THIS EMAIL");
            // }
            else {
              notification.toast("error", that.$t("register.toast_error"));
            }
            that.loading = false;
          }
        );
      } else if (!this.validOrgNumber) {
        this.$refs.organizationNumber.focus();
      }
    },
  },
  watch: {
    "form.organizationNumber": function () {
      this.form.organizationNumber = this.form.organizationNumber.replace(
        /\s/,
        ""
      );
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/fraktbestilling/registerdrop.scss"; /* injected */
</style>
