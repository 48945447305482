/* eslint-disable prettier/prettier */
<template>
  <div v-if="pages > 1" class="pagination-container">
    <button
      class="button is-medium-nav-pag body-text-small-title back-pag"
      @click="backPag()"
      :disabled="page === 1"
    >
      <i
        class="fas fa-solid fa-chevron-left"
        :class="{ disabledChev: page === 1 }"
      ></i>
    </button>
    <!-- NEXT BUTTON BEGIN  -->
    <button
      class="button is-medium-nav-pag body-text-small-title is-hidden-desktop"
      @click="nextPag()"
      :disabled="page === pages"
    >
      <i
        class="fas fa-solid fa-chevron-right"
        :class="{ disabledChev: page === pages }"
      ></i>
    </button>
    <!-- NEXT BUTTON END  -->
    <div v-if="this.pages <= 5" class="dis-inline pagination-pages">
      <button
        class="button is-medium-nav body-text-small-title"
        v-for="i in pages"
        :key="i"
        @click="changePage(i)"
        :class="{ current: this.page == i }"
      >
        {{ i }}
      </button>
    </div>
    <div v-if="this.pages > 5" class="dis-inline pagination-pages">
      <!-- first 5 pages start -->
      <span v-if="first5.includes(page + 1) && !this.showmiddle">
        <button
          class="button is-medium-nav body-text-small-title"
          v-for="i in first5.slice(0, page + 1)"
          :key="i"
          @click="changePage(i)"
          :class="{ current: this.page == i }"
        >
          {{ i }}
        </button>
        <button class="button is-medium-nav body-text-small-title">...</button>
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(pages)"
        >
          {{ pages }}
        </button>
      </span>
      <!-- first 5 pages end -->
      <!-- middle pages start -->
      <span v-if="showmiddle">
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(1)"
          :class="{ current: this.page == 1 }"
        >
          1
        </button>
        <button class="button is-medium-nav body-text-small-title">...</button>
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(page - 1)"
        >
          {{ this.page - 1 }}
        </button>
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(page)"
          :class="{ current: this.page == this.page }"
        >
          {{ this.page }}
        </button>
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(page + 1)"
        >
          {{ this.page + 1 }}
        </button>

        <button class="button is-medium-nav body-text-small-title">...</button>
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(pages)"
          :class="{ current: this.page == this.pages }"
        >
          {{ this.pages }}
        </button>
      </span>
      <!-- middle pages end -->

      <!-- last 5 pages start -->
      <span
        v-if="
          (last5.includes(page) || last5.includes(page + 1)) &&
          !this.showmiddle &&
          this.pages === 6
        "
      >
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(1)"
          :class="{ current: this.page == 1 }"
        >
          1
        </button>
        <button class="button is-medium-nav body-text-small-title">...</button>
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(5)"
          :class="{ current: this.page == 5 }"
        >
          5
        </button>
        <button
          class="button is-medium-nav body-text-small-title"
          v-for="i in last5"
          :key="i"
          @click="changePage(i)"
          :class="{ current: this.page == i }"
        >
          {{ i }}
        </button>
      </span>
      <span
        v-if="
          (last5.includes(page) || last5.includes(page + 1)) &&
          !this.showmiddle &&
          this.pages === 7
        "
      >
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(1)"
          :class="{ current: this.page == 1 }"
        >
          1
        </button>
        <button class="button is-medium-nav body-text-small-title">...</button>
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(5)"
          :class="{ current: this.page == 5 }"
        >
          5
        </button>
        <button
          class="button is-medium-nav body-text-small-title"
          v-for="i in last5"
          :key="i"
          @click="changePage(i)"
          :class="{ current: this.page == i }"
        >
          {{ i }}
        </button>
      </span>

      <span
        v-if="
          last5.includes(page) &&
          !this.showmiddle &&
          this.pages != 6 &&
          this.pages != 7
        "
      >
        <button
          class="button is-medium-nav body-text-small-title"
          @click="changePage(1)"
          :class="{ current: this.page == 1 }"
        >
          1
        </button>
        <button class="button is-medium-nav body-text-small-title">...</button>
        <button
          class="button is-medium-nav body-text-small-title"
          v-for="i in last5"
          :key="i"
          @click="changePage(i)"
          :class="{ current: this.page == i }"
        >
          {{ i }}
        </button>
      </span>
      <!-- last 5 pages end -->
    </div>
    <!-- NEXT BUTTON BEGIN  -->
    <button
      class="button is-medium-nav-pag body-text-small-title is-hidden-mobile"
      @click="nextPag()"
      :disabled="page === pages"
    >
      <i
        class="fas fa-solid fa-chevron-right"
        :class="{ disabledChev: page === pages }"
      ></i>
    </button>
    <!-- NEXT BUTTON END  -->
  </div>
</template>
<script>
export default {
  name: "PanginationComponent",
  props: ["page_size", "pages", "page"],
  emits: ["page"],
  components: {},
  data() {
    return {
      first5: [1, 2, 3, 4, 5],
      last5: [],
      showmiddle: false,
    };
  },
  methods: {
    changePage(value) {
      if (value <= this.pages) {
        this.$emit("page", value);
      }
    },
    backPag() {
      if (this.page > 1) {
        const page = this.page - 1;
        this.$emit("page", page);
      }
    },
    nextPag() {
      if (this.page < this.pages) {
        const page = this.page + 1;
        this.$emit("page", page);
      }
    },
    getLast5() {
      if (this.pages > 5) {
        if (this.pages === 6) {
          this.last5 = [this.pages];
        } else if (this.pages === 7) {
          this.last5 = [this.pages - 1, this.pages];
        } else if (this.pages === 8) {
          this.last5 = [this.pages - 2, this.pages - 1, this.pages];
        } else if (this.pages === 9) {
          this.last5 = [
            this.pages - 3,
            this.pages - 2,
            this.pages - 1,
            this.pages,
          ];
        } else if (this.pages >= 10) {
          this.last5 = [
            this.pages - 4,
            this.pages - 3,
            this.pages - 2,
            this.pages - 1,
            this.pages,
          ];
        }
      }
    },
  },
  mounted() {
    this.getLast5();
  },
  watch: {
    page() {
      if (this.first5.includes(this.page) || this.last5.includes(this.page)) {
        if (this.last5[0] === this.page || this.first5[4] === this.page) {
          if (
            (this.pages === 6 || this.pages === 7) &&
            (this.last5[0] - 1 === this.page || this.last5[0] === this.page)
          ) {
            this.showmiddle = false;
          } else {
            this.showmiddle = true;
          }
        } else {
          this.showmiddle = false;
        }
      } else {
        this.showmiddle = true;
      }
    },
  },
};
</script>
<style>
.current {
  color: rgb(255, 255, 255) !important;
  background: #07575b !important;
  border-radius: 3px;
}
.disabledChev {
  color: #afafaf !important;
}
</style>
