<template>
  <div>
    <h1 class="headline3 is-hidden-mobile" :class="{ bottom64: !showBanner }">
      {{ $t("new_delivery.header") }}
      <span class="headline-thin">{{ $t("new_delivery.step1.header") }}</span>
    </h1>
    <BannerComponent
      :show="showBanner"
      type="info"
      place="step1"
      :text="$t('banner.newdelivery')"
      @close="showBanner = false"
      :class="{ 'mt-5': width > 414 }"
    />
    <div class="columns m0m">
      <StepOneDropdownSender
        :addressbook="addressbook"
        type="sender"
        @selected="selectedSender"
        @reloadAddressbook="reloadAddressbook"
        @saveSenderAddressbook="saveSenderAddressbook"
        :newuser="newUser"
        v-show="ready"
      />

      <!-- recipient -->
      <StepOneDropdownRecipient
        :addressbook="addressbook"
        type="recipient"
        @selected="selectedRecipient"
        @reloadAddressbook="reloadAddressbook"
        @saveRecipientAddressbook="saveRecipientAddressbook"
        :newuser="newUser"
        v-show="ready"
        @emitNextStatus="emitNextStatus"
      />
    </div>
  </div>
</template>
<script>
import countrycodes from "@/countrycodes.json";
import useVuelidate from "@vuelidate/core";
import UserService from "@/services/user.service";
import notification from "@/notification.js";
import StepOneDropdownSender from "@/components/newtask/StepOneDropdownSender.vue";
import StepOneDropdownRecipient from "@/components/newtask/StepOneDropdownRecipient.vue";
import BannerComponent from "@/components/BannerComponent.vue";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
export default {
  name: "StepOne",
  components: {
    StepOneDropdownSender,
    StepOneDropdownRecipient,
    BannerComponent,
  },
  emits: ["evaluated"],
  props: ["active"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      countrycodes,
      width: "",
      ready: false,
      isFocusDel: false,
      isFocusRec: false,
      validPhone: true,
      verifiedPhone: false,
      addressbook: [{}],
      newUser: false,
      showBanner: false,
      searchRecipient: "",
      searchRecipientIcon: false,
      selectedSenderAddress: [],
      selectedRecipientAddress: [],
      pinned: [],
      delivery: {
        sender: {
          id: "",
          accountType: "",
          companyName: "",
          fullName: "",
          prefix: "",
          mobile: "",
          email: "",
          streetName: "",
          streetNumber: "",
          zip: "",
          city: "",
          note: "",
        },
        recipient: {
          id: "",
          companyName: "",
          accountType: "",
          fullName: "",
          prefix: "",
          mobile: "",
          email: "",
          streetName: "",
          streetNumber: "",
          zip: "",
          city: "",
          note: "",
        },
      },
    };
  },
  validations() {
    return {};
  },
  methods: {
    reloadAddressbook() {
      this.getAddressbook();
    },
    selectedSender(value) {
      this.selectedSenderAddress = value;
      if (this.selectedSenderAddress.fullName && this.selectedSenderAddress.fullName.length > 0) {
        this.delivery.sender = value;
        this.$store.commit("addStep1", this.delivery);
      } else {
        this.$store.commit("addStep1", []);
      }

      this.nextStep();
    },
    saveSenderAddressbook(val) {
      this.$store.commit("saveSender", val);
    },
    saveRecipientAddressbook(val) {
      this.$store.commit("saveRecipient", val);
    },
    selectedRecipient(value) {
      this.selectedRecipientAddress = value;
      if (
        this.selectedRecipientAddress.fullName &&
        this.selectedRecipientAddress.fullName.length > 0
      ) {
        this.delivery.recipient = value;
        this.$store.commit("addStep1", this.delivery);
      } else {
        this.$store.commit("addStep1", []);
      }

      this.nextStep();
    },
    emitNextStatus(val) {
      this.$emit("evaluated", { step: 1, valid: val });
    },
    nextStep() {
      if (
        Object.keys(this.selectedSenderAddress).length > 0 &&
        Object.keys(this.selectedRecipientAddress).length > 0
      ) {
        this.$store.commit("addStep1", this.delivery);
        this.$emit("evaluated", { step: 1, valid: true });
      } else {
        this.$emit("evaluated", { step: 1, valid: false });
      }
    },
    getAddressbook() {
      UserService.getAddressbook().then(
        (response) => {
          this.$store.commit("addAddressbook", response.data);
          this.addressbook = this.$store.state.addressbook;
          this.pinned = this.addressbook.filter((item) => item.pinned);
          this.newUser = this.addressbook.length === 0;
          this.showBanner = this.newUser;
          this.ready = true;
        },
        (error) => {
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          this.newUser = true;
          notification.toast("error", this.$t("addressbook.server_connection_error"));
          this.ready = true;
        }
      );
    },
    async checkPhone() {
      const fullnumber = this.delivery.recipient.prefix + this.delivery.recipient.mobile;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      };
      if (this.delivery.recipient.mobile.length > 2) {
        this.axios
          .get(
            `https://authentication.${API_ENV}.${API_URL}/authentication/validate/phone/${fullnumber}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status_message === "Success") {
              this.verifiedPhone = true;
              this.validPhone = true;
            } else {
              this.validPhone = false;
              this.verifiedPhone = false;
              // this.v$.delivery.recipient.mobile.$touch();
            }
          });
      }
    },
  },
  mounted() {
    if (this.active) {
      this.getAddressbook();
      this.width = window.innerWidth;
      this.$store.commit("saveSender", false);
      this.$store.commit("saveRecipient", false);
    }
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
@import "@/assets/fraktbestilling/step1drop.scss";
.column {
  min-height: 100%;
}
.selected-option {
  margin-top: 66px;
  position: absolute;
  height: 58px;
  font-size: 16px;
  line-height: 20px;
}
</style>
