<template>
  <LoaderComponent v-if="showLoader" @escape="showLoader = false" />
  <div class="container">
    <h1 class="title is-1" v-if="!isMobile">{{ $t("settings.menu_item") }}</h1>
    <div id="tabs-with-content">
      <BannerComponent
        :show="showBanner"
        type="error"
        :text="$t('banner.settings')"
        @close="showBanner = false"
      />
      <div class="tabs">
        <ul>
          <li>
            <button
              class="tabBtn"
              :class="{ selected: active == 0 }"
              @click="setActive(0)"
            >
              <span v-if="isMobile && active != 0">
                <img
                  src="@/assets/icons/PNG/user-gray.png"
                  class="settings-btn-img"
                  :alt="$t('settings.tabs.personal_info')"
                />
              </span>
              <span v-if="!isMobile || active == 0">{{
                $t("settings.tabs.personal_info")
              }}</span>
              <span
                v-if="isMobile && active == 0 && !personalMissing"
                class="ml-2"
              >
                <img
                  src="@/assets/icons/PNG/user-mosque.png"
                  class="settings-btn-img"
                  :alt="$t('settings.tabs.personal_info')"
                />
              </span>
              <i
                class="ml-1 fa fa-solid fa-exclamation"
                v-show="personalMissing"
              ></i>
            </button>
          </li>
          <li>
            <button
              class="tabBtn"
              :class="{ selected: active == 1 }"
              @click="setActive(1)"
            >
              <span v-if="isMobile && active != 1">
                <img
                  src="@/assets/icons/PNG/business-gray.png"
                  class="settings-btn-img"
                  :alt="$t('settings.tabs.business_info')"
                />
              </span>
              <span v-if="!isMobile || active == 1">{{
                $t("settings.tabs.business_info")
              }}</span>
              <span
                v-if="isMobile && active == 1 && !businessMissing"
                class="ml-2"
              >
                <img
                  src="@/assets/icons/PNG/business-mosque.png"
                  class="settings-btn-img"
                  :alt="$t('settings.tabs.business_info')"
                />
              </span>
              <i
                class="ml-1 fa-solid fa-exclamation"
                v-show="businessMissing"
              ></i>
            </button>
          </li>
          <li>
            <button
              class="tabBtn"
              :class="{ selected: active == 2 }"
              @click="setActive(2)"
            >
              <span v-if="isMobile && active != 2">
                <img
                  src="@/assets/icons/PNG/globe-gray.png"
                  class="settings-btn-img"
                  :alt="$t('settings.tabs.preferences')"
                />
              </span>
              <span v-if="!isMobile || active == 2">{{
                $t("settings.tabs.preferences")
              }}</span>
              <span v-if="isMobile && active == 2" class="ml-2">
                <img
                  src="@/assets/icons/PNG/globe-mosque.png"
                  class="settings-btn-img"
                  :alt="$t('settings.tabs.preferences')"
                />
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div>
        <section v-show="active == 0" class="tab-content">
          <SettingsPersonalInfo :isMobile="isMobile" :active="active == 0" />
        </section>
        <section v-show="active == 1" class="tab-content">
          <SettingsBusinessInfo
            :isMobile="isMobile"
            :active="active == 1"
            @refresh="getData()"
          />
        </section>
        <section v-show="active == 2" class="tab-content">
          <SettingsPreferences :isMobile="isMobile" :active="active == 2" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import SettingsPersonalInfo from "@/components/settings/SettingsPersonalInfo.vue";
import SettingsBusinessInfo from "@/components/settings/SettingsBusinessInfo.vue";
import SettingsPreferences from "@/components/settings/SettingsPreferences.vue";
import UserService from "@/services/user.service";
import BannerComponent from "@/components/BannerComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export default {
  components: {
    SettingsPersonalInfo,
    SettingsBusinessInfo,
    SettingsPreferences,
    LoaderComponent,
    BannerComponent,
  },
  name: "SettingsView",
  props: {},
  data() {
    return {
      showLoader: true,
      showBanner: false,
      personalMissing: false,
      businessMissing: false,
      active: 0,
      isMobile: false,
      canSave: true,
      user: {
        account_type: "company",
      },
    };
  },
  methods: {
    setActive(value) {
      this.showLoader = true;
      this.active = value;
      this.showLoader = false;
    },
    locale(value) {
      this.$root.$i18n.locale = value;
      ls.set("locale", value);
    },
    detectMobile() {
      this.isMobile = window.innerWidth < 769;
    },
    getData() {
      let belongTo = this.$store.state.userId;
      UserService.getProfile(belongTo).then(
        (response) => {
          if (
            this.isEmpty(response.data.User.firstName) ||
            this.isEmpty(response.data.User.lastName) ||
            this.isEmpty(response.data.User.prefix) ||
            this.isEmpty(response.data.User.mobile) ||
            this.isEmpty(response.data.User.email)
          ) {
            this.personalMissing = true;
            this.showBanner = true;
          } else {
            this.personalMissing = false;
            this.showBanner = false;
          }
          if (
            this.isEmpty(response.data.Company.companyName) ||
            this.isEmpty(response.data.Company.companyNumber) ||
            this.isEmpty(response.data.Company.invoiceEmail) ||
            this.isEmpty(response.data.Company.businessPhone) ||
            this.isEmpty(response.data.Company.streetName) ||
            this.isEmpty(response.data.Company.streetNumber) ||
            this.isEmpty(response.data.Company.city) ||
            this.isEmpty(response.data.Company.zip)
          ) {
            this.businessMissing = true;
            this.showBanner = true;
          } else {
            this.businessMissing = false;
            this.showBanner = false;
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
    isEmpty(val) {
      return val == "" || val == null;
    },
  },
  created() {
    this.detectMobile();
    window.addEventListener("resize", this.detectMobile);
    this.showLoader = false;
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
hr {
  background-color: #e7e1dc;
  border: none;
  height: 1px;
  width: 100%;
}
.tabs ul {
  border-bottom-width: 0 !important;
}
.warn-red {
  color: #ff2d55 !important;
}
.fa-exclamation {
  background-color: #ff2d55 !important;
  width: 1.5em;
  border-radius: 3px;
  height: 1.5em;
  padding-top: 4px;
  color: white !important;
  font-weight: bold;
  font-size: 75%;
  text-align: center;
}
</style>
