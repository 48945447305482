/* eslint-disable no-unused-vars */
import ls from "localstorage-slim";
import AuthService from "../services/auth.service";
import store from ".";

const dayjs = require("dayjs");

ls.config.encrypt = true;
const user = JSON.parse(ls.get("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ _commit }, data) {
      return AuthService.login(data).then(
        (res) => Promise.resolve(res),
        store.commit("loginSuccess"),

        (error) => Promise.reject(error),
        store.commit("loginFailure")
      );
    },
    checktoken({ _commit }) {
      const tokendata = ls.get("token");
      return AuthService.checktoken(tokendata).then(
        (res) => {
          if (res.data?.exp) {
            const tokentime = dayjs.unix(res.data.exp);
            const now = dayjs();
            const diff = tokentime.diff(now, "second");
            if (diff > 0) {
              return Promise.resolve(res);
            }
            ls.set("token", "");
            AuthService.logout();
            store.commit("logout");

            return Promise.resolve(res);
          }
          return Promise.reject(res);
        },
        (error) => {
          store.commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout() {
      AuthService.logout();
      store.commit("logout");
    },
    register({ _commit }, data) {
      store.commit("logout");
      return AuthService.register(data).then(
        (response) => {
          store.commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          store.commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
};
