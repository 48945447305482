<template>
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered">
        <div class="column is-half-desktop is-half-tablet is-full-mobile"></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "TermsOfService",

  data() {
    return {};
  },
  created() {
    window.location.href = "https://fraktbestilling.no/brukervilk%C3%A5r/";
  },

  methods: {},
};
</script>
