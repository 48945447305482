<template>
  <div class="column is-half margin-0-6 pos-relative ml-0">
    <h2 class="body-text-title mt-standard">
      {{ $t(`new_delivery.step1.${type}_title`) }}
    </h2>
    <div v-if="Object.keys(selected).length > 0" class="selectedGreen">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
          fill="#5CDB95"
        />
      </svg>
    </div>
    <label class="label" v-if="!this.addNew">{{ $t("new_delivery.step1.search_label") }}</label>
    <div class="dropdown w100" v-if="Object.keys(selected).length < 1 && !this.addNew">
      <div class="dropdown-trigger w100">
        <div class="control has-icons-right">
          <input
            class="input"
            type="text"
            v-model="search"
            :placeholder="$t('addressbook.searchinput')"
            @click="toggleDropdown()"
          />
          <span class="icon is-small is-right mt-6px">
            <svg
              v-if="searchIcon"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.8594 16.7383L13.6055 12.4844C13.5 12.4141 13.3945 12.3438 13.2891 12.3438H12.832C13.9219 11.0781 14.625 9.39062 14.625 7.5625C14.625 3.55469 11.3203 0.25 7.3125 0.25C3.26953 0.25 0 3.55469 0 7.5625C0 11.6055 3.26953 14.875 7.3125 14.875C9.14062 14.875 10.793 14.207 12.0938 13.1172V13.5742C12.0938 13.6797 12.1289 13.7852 12.1992 13.8906L16.4531 18.1445C16.6289 18.3203 16.9102 18.3203 17.0508 18.1445L17.8594 17.3359C18.0352 17.1953 18.0352 16.9141 17.8594 16.7383ZM7.3125 13.1875C4.18359 13.1875 1.6875 10.6914 1.6875 7.5625C1.6875 4.46875 4.18359 1.9375 7.3125 1.9375C10.4062 1.9375 12.9375 4.46875 12.9375 7.5625C12.9375 10.6914 10.4062 13.1875 7.3125 13.1875Z"
                fill="#6E6E6E"
              />
            </svg>
          </span>
        </div>
      </div>
      <div :id="`dropdown-menu-${type}`" role="menu">
        <div class="dropdown-content pt-def">
          <div class="dropdown-item columns is-mobile" v-for="(data, i) in filtered" :key="i">
            <div
              id="dropdown-parent-sender"
              class="column is-1 p-0 has-text-centered"
              @click="select(data)"
            >
              <svg
                v-if="data.accountType == 'business'"
                width="23"
                height="19"
                viewBox="0 0 23 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mt-12px"
              >
                <path
                  d="M11.5008 13.75H4.75079V8.125H2.50079V17.125C2.50079 17.7578 2.99298 18.25 3.62579 18.25H12.6258C13.2234 18.25 13.7508 17.7578 13.7508 17.125V8.125H11.5008V13.75ZM22.5399 5.27734L19.5516 0.777344C19.3406 0.460938 18.9891 0.25 18.6024 0.25H4.36407C3.97735 0.25 3.62579 0.460938 3.41485 0.777344L0.426569 5.27734C-0.0656183 6.01562 0.461725 7 1.37579 7H21.6258C22.5047 7 23.032 6.01562 22.5399 5.27734ZM18.2508 17.6875C18.2508 18.0039 18.4969 18.25 18.8133 18.25H19.9383C20.2195 18.25 20.5008 18.0039 20.5008 17.6875V8.125H18.2508V17.6875Z"
                  fill="#AFAFAF"
                />
              </svg>
              <svg
                v-if="data.accountType == 'individual'"
                width="16"
                height="19"
                viewBox="0 0 16 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mt-12px"
              >
                <path
                  d="M11.1289 10.9375C10.1094 10.9375 9.65234 11.5 8 11.5C6.3125 11.5 5.85547 10.9375 4.83594 10.9375C2.23438 10.9375 0.125 13.082 0.125 15.6836V16.5625C0.125 17.5117 0.863281 18.25 1.8125 18.25H14.1875C15.1016 18.25 15.875 17.5117 15.875 16.5625V15.6836C15.875 13.082 13.7305 10.9375 11.1289 10.9375ZM14.1875 16.5625H1.8125V15.6836C1.8125 13.9961 3.14844 12.625 4.83594 12.625C5.36328 12.625 6.17188 13.1875 8 13.1875C9.79297 13.1875 10.6016 12.625 11.1289 12.625C12.8164 12.625 14.1875 13.9961 14.1875 15.6836V16.5625ZM8 10.375C10.7773 10.375 13.0625 8.125 13.0625 5.3125C13.0625 2.53516 10.7773 0.25 8 0.25C5.1875 0.25 2.9375 2.53516 2.9375 5.3125C2.9375 8.125 5.1875 10.375 8 10.375ZM8 1.9375C9.82812 1.9375 11.375 3.48438 11.375 5.3125C11.375 7.17578 9.82812 8.6875 8 8.6875C6.13672 8.6875 4.625 7.17578 4.625 5.3125C4.625 3.48438 6.13672 1.9375 8 1.9375Z"
                  fill="#AFAFAF"
                />
              </svg>
            </div>
            <div @click="select(data)" class="column is-9-desktop is-8-mobile p-0 pl-22px">
              <p class="body-text-small-title" v-if="data.accountType == 'business'">
                {{ data.companyName }}
              </p>
              <p class="body-text-small-title" v-else>{{ data.fullName }}</p>
              <p class="caption-text">{{ data.city }}</p>
            </div>
            <div class="column is-2 p-0 pos-relative">
              <div class="columns is-mobile is-vcentered mt-0">
                <div @click="select(data)" class="column is-center">
                  <p class="">
                    <svg
                      width="12"
                      height="20"
                      viewBox="0 0 12 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        v-if="data.pinned"
                        d="M4.75009 12.3807V18.5015L5.61026 19.7914C5.79581 20.0695 6.2048 20.0695 6.39035 19.7914L7.25013 18.5015V12.3807C6.84427 12.4557 6.42746 12.5002 6.00011 12.5002C5.57276 12.5002 5.15595 12.4557 4.75009 12.3807ZM6.00011 0C2.89341 0 0.375 2.51841 0.375 5.62511C0.375 8.73181 2.89341 11.2502 6.00011 11.2502C9.10681 11.2502 11.6252 8.73181 11.6252 5.62511C11.6252 2.51841 9.10681 0 6.00011 0ZM6.00011 2.96881C4.53524 2.96881 3.34381 4.16024 3.34381 5.62511C3.34381 5.88371 3.13365 6.09387 2.87505 6.09387C2.61645 6.09387 2.40629 5.88371 2.40629 5.62511C2.40629 3.64343 4.01882 2.03129 6.00011 2.03129C6.25871 2.03129 6.46887 2.24145 6.46887 2.50005C6.46887 2.75865 6.25871 2.96881 6.00011 2.96881Z"
                        fill="#5CDB95"
                      />
                      <path
                        v-else
                        d="M4.75009 12.3807V18.5015L5.61026 19.7914C5.79581 20.0695 6.2048 20.0695 6.39035 19.7914L7.25013 18.5015V12.3807C6.84427 12.4557 6.42746 12.5002 6.00011 12.5002C5.57276 12.5002 5.15595 12.4557 4.75009 12.3807ZM6.00011 0C2.89341 0 0.375 2.51841 0.375 5.62511C0.375 8.73181 2.89341 11.2502 6.00011 11.2502C9.10681 11.2502 11.6252 8.73181 11.6252 5.62511C11.6252 2.51841 9.10681 0 6.00011 0ZM6.00011 2.96881C4.53524 2.96881 3.34381 4.16024 3.34381 5.62511C3.34381 5.88371 3.13365 6.09387 2.87505 6.09387C2.61645 6.09387 2.40629 5.88371 2.40629 5.62511C2.40629 3.64343 4.01882 2.03129 6.00011 2.03129C6.25871 2.03129 6.46887 2.24145 6.46887 2.50005C6.46887 2.75865 6.25871 2.96881 6.00011 2.96881Z"
                        fill="#D6D6D6"
                      />
                    </svg>
                  </p>
                </div>

                <div class="column is-center">
                  <p @click="showSingleAddressOptions(data.id)">
                    <svg
                      width="3"
                      height="13"
                      viewBox="0 0 3 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="3" height="13" fill="#E5E5E5" />
                      <path
                        d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z"
                        fill="#D6D6D6"
                      />
                      <path
                        d="M3 6.5C3 7.32843 2.32843 8 1.5 8C0.671573 8 0 7.32843 0 6.5C0 5.67157 0.671573 5 1.5 5C2.32843 5 3 5.67157 3 6.5Z"
                        fill="#D6D6D6"
                      />
                      <path
                        d="M3 11.5C3 12.3284 2.32843 13 1.5 13C0.671573 13 0 12.3284 0 11.5C0 10.6716 0.671573 10 1.5 10C2.32843 10 3 10.6716 3 11.5Z"
                        fill="#D6D6D6"
                      />
                    </svg>
                  </p>
                </div>
                <!--/.boxOptions-->
                <div v-if="showOptions == data.id" class="boxOptionsS1">
                  <button class="boxOptionsBtn" @click="saveAsStandard(data.id)">
                    <span class="sidebaricon">
                      <img
                        src="@/assets/icons/PNG/check-circle-gray.png"
                        class="s1-option-img"
                        alt="Save as standard"
                      />
                    </span>
                    {{ $t("addressbook.save_standard") }}
                  </button>
                  <button class="boxOptionsBtn" @click="pinAddress(data.id, data.pinned)">
                    <span v-if="data.pinned">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.75011 13.3807V19.5015L10.6103 20.7914C10.7958 21.0695 11.2048 21.0695 11.3904 20.7914L12.2502 19.5015V13.3807C11.8443 13.4557 11.4275 13.5002 11.0001 13.5002C10.5728 13.5002 10.156 13.4557 9.75011 13.3807ZM11.0001 0.999994C7.89343 0.999994 5.37502 3.5184 5.37502 6.6251C5.37502 9.73181 7.89343 12.2502 11.0001 12.2502C14.1068 12.2502 16.6252 9.73181 16.6252 6.6251C16.6252 3.5184 14.1068 0.999994 11.0001 0.999994ZM11.0001 3.9688C9.53526 3.9688 8.34383 5.16023 8.34383 6.6251C8.34383 6.8837 8.13367 7.09386 7.87507 7.09386C7.61647 7.09386 7.40631 6.8837 7.40631 6.6251C7.40631 4.64343 9.01885 3.03128 11.0001 3.03128C11.2587 3.03128 11.4689 3.24144 11.4689 3.50004C11.4689 3.75864 11.2587 3.9688 11.0001 3.9688Z"
                          fill="#AFAFAF"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M21.7072 0.293303C22.0977 0.683822 22.0977 1.31699 21.7072 1.70752L1.91866 21.4966C1.52815 21.8871 0.89498 21.8871 0.504451 21.4966C0.113921 21.1061 0.113912 20.4729 0.504431 20.0824L20.293 0.293322C20.6835 -0.0972072 21.3167 -0.0972157 21.7072 0.293303Z"
                          fill="#AFAFAF"
                        />
                      </svg>

                      {{ $t("addressbook.unpin") }}
                    </span>
                    <span v-else>
                      <span class="sidebaricon">
                        <svg
                          width="12"
                          height="20"
                          viewBox="0 0 12 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.75009 12.3807V18.5015L5.61026 19.7914C5.79581 20.0695 6.2048 20.0695 6.39035 19.7914L7.25013 18.5015V12.3807C6.84427 12.4557 6.42746 12.5002 6.00011 12.5002C5.57276 12.5002 5.15595 12.4557 4.75009 12.3807ZM6.00011 0C2.89341 0 0.375 2.51841 0.375 5.62511C0.375 8.73181 2.89341 11.2502 6.00011 11.2502C9.10681 11.2502 11.6252 8.73181 11.6252 5.62511C11.6252 2.51841 9.10681 0 6.00011 0ZM6.00011 2.96881C4.53524 2.96881 3.34381 4.16024 3.34381 5.62511C3.34381 5.88371 3.13365 6.09387 2.87505 6.09387C2.61645 6.09387 2.40629 5.88371 2.40629 5.62511C2.40629 3.64343 4.01882 2.03129 6.00011 2.03129C6.25871 2.03129 6.46887 2.24145 6.46887 2.50005C6.46887 2.75865 6.25871 2.96881 6.00011 2.96881Z"
                            fill="#AFAFAF"
                          />
                        </svg>
                      </span>
                      {{ $t("addressbook.pin") }}
                    </span>
                  </button>
                </div>
                <!--/.box-->
              </div>
            </div>
          </div>
          <div class="dropdown-item columns addNewField">
            <p class="s1-addnew-p" @click="addNewAddress()">
              <span class="mr-12px"><img src="@/assets/icons/PNG/plus.png" alt="add" /></span>
              {{ $t(`new_delivery.step1.add-new`) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="Object.keys(selected).length > 0" class="selectedAddress">
      <button class="closeSel" aria-label="close" @click="clear()">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
            fill="#AFAFAF"
          />
        </svg>
      </button>
      <div class="columns is-mobile">
        <div class="column" style="max-width: 45%">
          <p class="selTitle elipsis-text">
            <span v-if="type == 'sender'">{{ $t("new_delivery.step1.sender") }}</span>
            <span v-else>{{ $t("new_delivery.step1.recipient") }}</span>
          </p>
          <p class="selFirst elipsis-text">
            {{ this.selected.companyName }} &nbsp;
            {{ this.selected.accountType }}
          </p>
          <p class="selText elipsis-text">
            {{ this.selected.streetName }} {{ this.selected.streetNumber }}
          </p>
          <p class="selText elipsis-text">{{ this.selected.zip }} {{ this.selected.city }}</p>
        </div>
        <div class="column" style="max-width: 45%">
          <p class="selTitle elipsis-text">
            {{ $t("new_delivery.step1.contact_person") }}
          </p>
          <p class="selFirst elipsis-text">{{ this.selected.fullName }}</p>
          <p class="selText elipsis-text">{{ this.selected.prefix }} {{ this.selected.mobile }}</p>
          <p class="selText elipsis-text">{{ this.selected.email }}</p>
        </div>
      </div>
    </div>
    <div class="columns" v-if="addNew">
      <div class="editform-st1 ml-2 pl-0 pr-0">
        <div class="control">
          <label class="radio" :class="btnbiz()">
            <input
              class="radioselect"
              type="radio"
              value="business"
              v-model="selectedNew.accountType"
            />
            {{ $t("addressbook.modal.business") }}
          </label>
          <label class="radio" :class="btnind()">
            <input
              class="radioselect"
              type="radio"
              value="individual"
              v-model="selectedNew.accountType"
            />
            {{ $t("addressbook.modal.individual") }}
          </label>
        </div>
        <div class="field" v-if="selectedNew.accountType === 'business'">
          <label class="label">{{ $t("addressbook.modal.companyname") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.companyName"
              :placeholder="$t('register.placeholder_company_name')"
              :class="{ redBorder: v$.selectedNew.companyName.$error }"
              @blur="v$.selectedNew.companyName.$touch"
            />
          </div>
          <span v-if="v$.selectedNew.companyName.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>
        <div class="field">
          <label class="label">{{ $t("addressbook.modal.contactperson") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.fullName"
              :placeholder="$t('addressbook.modal.contactperson_placeholder')"
              :class="{ redBorder: v$.selectedNew.fullName.$error }"
              @blur="v$.selectedNew.fullName.$touch"
            />
          </div>
          <span v-if="v$.selectedNew.fullName.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>

        <div class="field prefix">
          <label class="label">{{ $t("register.country_code") }}</label>
          <div class="control dropdown-trigger">
            <input
              class="input"
              type="text"
              v-model="selectedNew.prefix"
              :placeholder="$t('addressbook.searchinput')"
              @click="toggleDropdownPrefix()"
              readonly
            />
          </div>
          <div name="arrowBox" class="arrowBox z-1">
            <img src="@/assets/icons/PNG/chevron-right.png" alt="right" />
          </div>
          <div role="menu" class="dropdown-show-options" v-if="showDropdown">
            <div
              class="dropdown-content prefix-content box-shadow-unset"
              v-for="(country, i) in countrycodes"
              :key="i"
            >
              <div class="dropdown-item columns mtb-0" @click="selectPrefix(country.dial_code)">
                <div class="column">
                  <span class="body-text-small text-black">{{ country.name }}</span>
                  <span class="body-text-small text-gray ml-10px">{{ country.dial_code }}</span>
                </div>
              </div>
            </div>
          </div>
          <p class="help"></p>
        </div>

        <div class="field phonenumber">
          <label class="label">{{ $t("register.personal_phone") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.mobile"
              :class="{
                redBorder: !this.validPhone || v$.selectedNew.mobile.$error,
              }"
              :placeholder="$t('register.placeholder_personal_phone')"
              @blur="checkPhone"
            />
          </div>
          <span v-if="!this.validPhone || v$.selectedNew.mobile.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>

        <div class="field email">
          <label class="label emaillabel">{{ $t("addressbook.modal.email") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.email"
              :placeholder="$t('register.placeholder_personal_email')"
              :class="{ redBorder: v$.selectedNew.email.$error }"
              @blur="v$.selectedNew.email.$touch"
            />
          </div>
          <span v-if="v$.selectedNew.email.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>
        <div class="field post_sted">
          <label class="label">{{ $t("addressbook.modal.street") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.streetName"
              :placeholder="$t('addressbook.modal.placeholder_streetname')"
              :class="{ redBorder: v$.selectedNew.streetName.$error }"
              @blur="v$.selectedNew.streetName.$touch"
            />
          </div>
          <span v-if="v$.selectedNew.streetName.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>
        <div class="field post_number">
          <label class="label">{{ $t("addressbook.modal.number") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.streetNumber"
              :placeholder="$t('addressbook.modal.placeholder_number')"
              :class="{ redBorder: v$.selectedNew.streetNumber.$error }"
              @blur="v$.selectedNew.streetNumber.$touch"
            />
          </div>
          <span v-if="v$.selectedNew.streetNumber.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>
        <div class="field post_zip">
          <label class="label">{{ $t("addressbook.modal.zip") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.zip"
              :placeholder="$t('addressbook.modal.placeholder_number')"
              :class="{ redBorder: v$.selectedNew.zip.$error }"
              @blur="v$.selectedNew.zip.$touch"
            />
          </div>
          <span v-if="v$.selectedNew.zip.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>
        <div class="field post_area">
          <label class="label">{{ $t("addressbook.modal.area") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="selectedNew.city"
              :placeholder="$t('addressbook.modal.placeholder_area')"
              :class="{ redBorder: v$.selectedNew.city.$error }"
              @blur="v$.selectedNew.city.$touch"
            />
          </div>
          <span v-if="v$.selectedNew.city.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help"></p>
        </div>

        <div class="field">
          <label class="label">{{ $t("addressbook.modal.comment_sender") }}</label>
          <div class="control">
            <textarea
              class="textarea is-small"
              v-model="selectedNew.note"
              :placeholder="$t('addressbook.modal.placeholder_comment')"
            ></textarea>
          </div>
        </div>
        <div>
          <div
            class="field mt-5"
            :class="{ 'ml-5': this.width > 414 }"
            style="margin-top: 50px !important"
          >
            <div class="columns is-mobile">
              <div class="column is-1 pr-0 pl-0">
                <input type="checkbox" class="sq-25" v-model="saveSenderAddressbook" />
              </div>
              <div class="column pl-0">
                <span class="technical13-bold sherpa">{{
                  $t("new_delivery.step2.save_to_addressbook")
                }}</span>
              </div>
              <div class="column pt-0">
                <button class="button-medium-cancel-mint float-right" @click="removeAddNew()">
                  <span class="sidebaricon">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                        fill="#66A5AD"
                      />
                    </svg>
                  </span>

                  {{ $t("addressbook.modal.cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countrycodes from "@/countrycodes.json";
import postalcodes from "@/postalcodes.json";
import notification from "@/notification.js";
import useVuelidate from "@vuelidate/core";
import UserService from "@/services/user.service";

import { required, email, minLength, maxLength, requiredIf, numeric } from "@vuelidate/validators";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
export default {
  name: "StepOneDropdown",
  components: {},
  props: {
    addressbook: Object,
    type: String,
    newuser: Boolean,
  },
  emits: ["reloadAddressbook", "selected", "saveSenderAddressbook"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      countrycodes,
      width: "",
      saveSenderAddressbook: true,
      showDropdown: false,
      search: "",
      searchIcon: false,
      isFocusDelEdit: false,
      validPhone: true,
      verifiedPhone: false,
      showOptions: "",
      selected: [],
      selectedNew: {
        accountType: "individual",
        companyName: "",
        fullName: "",
        prefix: "+47",
        mobile: "",
        email: "",
        streetName: "",
        streetNumber: "",
        zip: "",
        city: "",
        country: "",
        note: "",
      },
      pinned: [],
      addNew: false,
    };
  },
  validations() {
    return {
      selectedNew: {
        companyName: {
          required: requiredIf(this.selectedNew.accountType === "business"),
          min: minLength(1),
        },
        fullName: {
          required,
          min: minLength(1),
        },
        email: {
          required,
          email,
        },
        mobile: {
          required,
          numeric,
          min: minLength(3),
        },
        streetName: {
          required,
          min: minLength(1),
        },
        streetNumber: {
          required,
          min: minLength(1),
        },
        zip: {
          required,
          numeric,
          min: minLength(4),
          max: maxLength(4),
        },
        city: {
          required,
          min: minLength(1),
        },
      },
    };
  },
  methods: {
    removeAddNew() {
      this.addNew = false;
      this.clear();
    },
    btnbiz() {
      let res = "";
      if (this.selectedNew.accountType == "business") {
        res = "button-medium-empty-b";
      } else {
        res = "button-medium-empty";
      }
      return res;
    },
    btnind() {
      let res = "";
      if (this.selectedNew.accountType == "individual") {
        res = "button-medium-empty-b";
      } else {
        res = "button-medium-empty";
      }
      return res;
    },
    pinAddress(id, pinned) {
      this.showSingleAddressOptions(id);
      const message = !pinned
        ? this.$t("addressbook.pinned_notification")
        : this.$t("addressbook.unpinned_notification");
      const entry = {
        id,
        pinned: !pinned,
      };
      UserService.pinAddressbook(entry).then(() => {
        this.$emit("reloadAddressbook");
        notification.toast("success", message);
      });
    },
    saveAsStandard(id) {
      this.showSingleAddressOptions(id);
      const entry = {
        id,
        defaultValue: true,
      };
      UserService.pinAddressbook(entry).then(() => {
        this.$emit("reloadAddressbook");
      });
    },
    showSingleAddressOptions(value) {
      this.showOptions = this.showOptions == value ? "" : value;
    },
    toggleDropdown() {
      const el = document.getElementById(`dropdown-menu-${this.type}`);
      el.classList.toggle("is-active");
      this.searchIcon = !this.searchIcon;
    },
    toggleDropdownPrefix() {
      this.showDropdown = !this.showDropdown;
    },
    selectPrefix(prefix) {
      this.selectedNew.prefix = prefix;
      this.showDropdown = false;
    },
    select(data) {
      this.selected = data;
      this.showOptions = "";
      this.$emit("selected", data);
      this.toggleDropdown();
    },
    clear() {
      this.selected = [];
      this.$emit("selected", []);
      this.overflow();
    },
    overflow() {
      setTimeout(() => {
        const el = document.getElementById("dropdown-menu-sender");
        if (el) {
          if (this.search.length > 0) {
            if (this.filtered.length > 8) {
              const newHeight = (this.filtered.length + 1) * 58 + 12;
              el.style.height = `${newHeight}px`;
              el.classList.add("overflowXAuto");
              el.classList.remove("overflowXHidden");
            } else {
              const newHeight = (this.filtered.length + 1) * 58 + 12;
              el.style.height = `${newHeight}px`;
              el.classList.add("overflowXHidden");
              el.classList.remove("overflowXAuto");
            }
          } else {
            const newHeight = (this.filtered.length + 1) * 58 + 12;
            el.style.height = `${newHeight}px`;
            if (el.classList.contains("overflowXHidden")) {
              el.classList.remove("overflowXHidden");
              el.classList.add("overflowXAuto");
            }
          }
        }
      }, 50);
    },
    async checkPhone() {
      const { prefix } = this.selectedNew;
      const fullnumber = prefix + this.selectedNew.mobile;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      };
      if (this.selectedNew.mobile.length > 2) {
        this.axios
          .get(
            `https://authentication.${API_ENV}.${API_URL}/authentication/validate/phone/${fullnumber}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status_message === "Success") {
              this.validPhone = true;
              this.verifiedPhone = true;
            } else {
              this.validPhone = false;
              this.verifiedPhone = false;
              // this.v$.selectedNew.mobile.$touch();
              if (this.loading) {
                notification.toast("error", this.$t("register.wrong_phone"));
                this.loading = false;
              }
            }
          });
      }
    },
    pinnedSender() {
      if (this.$store.state.step1.sender != undefined && this.$store.state.step1.sender.id != "") {
        this.selected = this.$store.state.step1.sender;
        if (Object.keys(this.selected).length > 0) {
          this.$emit("selected", this.selected);
        }
      } else if (Object.keys(this.pinned).length !== 0) {
        this.selected = this.pinned[0];
        this.$emit("selected", this.selected);
      } else {
        this.selected = [];
      }
    },
    addNewAddress() {
      this.addNew = true;
      this.toggleDropdown();
    },
  },
  mounted() {
    this.addNew = this.newuser;
    if (this.newuser) {
      this.addNew = true;
    }
    this.width = window.innerWidth;
    setTimeout(() => {
      this.pinned = this.addressbook.filter((o) => o.pinned === true);
      this.pinnedSender();
    }, 100);
  },
  computed: {
    filtered() {
      const filtered = this.addressbook.filter((o) =>
        Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(this.search.toLowerCase()))
      );

      this.overflow();

      return filtered;
    },
  },
  watch: {
    v$() {
      if (!this.v$.selectedNew.$invalid) {
        if (this.verifiedPhone) {
          this.$emit("selected", this.selectedNew);
          if (this.addNew) {
            this.$emit("saveSenderAddressbook", this.saveSenderAddressbook);
          }
        }
      }
    },
    newuser() {
      this.addNew = this.newuser;
    },
    "selectedNew.zip": function () {
      this.v$.$validate();
      if (this.selectedNew.zip.length == 4) {
        const match = postalcodes[this.selectedNew.zip];
        if (match != null || match != undefined) {
          this.selectedNew.city = match[0].toUpperCase() + match.slice(1).toLowerCase();
        }
      }
    },
    saveSenderAddressbook() {
      if (this.addNew) {
        this.$emit("saveSenderAddressbook", this.saveSenderAddressbook);
      }
    },
    addressbook() {
      if (Object.keys(this.addressbook).length > 0) {
        this.pinned = this.addressbook.filter((o) => o.pinned === true);
        this.pinnedSender();
      }
    },
  },
};
</script>
<style scoped>
@import "@/assets/fraktbestilling/step1drop.scss"; /* injected */
.boxOptionsS1 {
  position: absolute;
  right: 14px;
  margin-top: 218px;
  background-color: #f2f2f2;
  width: 223px;
  height: 118px;
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}
.boxOptionsBtn {
  height: 59px;
  width: 223px;
  border: none;
  font-size: 18px;
  line-height: 26px;
  color: #6e6e6e;
  text-align: left;
  padding-left: 20px;
}
</style>
