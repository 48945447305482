<template>
  <div class="container">
    <span>Overview</span>
  </div>
</template>
<script>
export default {
  components: {},
  name: "OverView",
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
