<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container welcome-modal">
          <div class="welcome-modal-header">
            <h1 class="welcome-modal-title">
              {{ $t("welcome.title") }}
            </h1>
            <button
              class="delete welcome-close"
              aria-label="close"
              @click="$emit('close')"
            ></button>
          </div>

          <div class="welcome-modal-body">
            <p class="welcome-modal-date">{{ date }}</p>
            <p class="welcome-modal-body-title has-text-black">
              {{ $t("welcome.title") }}
            </p>
            <br />
            <p class="welcome-modal-body-text has-text-black">
              {{ $t("welcome.subtitle") }}
            </p>
            <p
              class="welcome-modal-body-text has-text-black"
              v-html="$t('welcome.text')"
            ></p>
          </div>

          <div class="welcome-modal-footer">
            <slot name="footer">
              <button
                class="button button-bottom-welcome-modal"
                @click="$emit('close')"
              >
                {{ $t("welcome.start") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import dayjs from "dayjs";

export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      date: "",
    };
  },
  methods: {},
  mounted() {
    this.date = dayjs();
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.welcome-close {
  position: absolute !important;
  right: 28px;
  top: 40px;
  background-color: rgba(29, 29, 31, 0.8);
}
.modal-container {
  max-width: 960px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.welcome-modal {
  background-color: white;
  border-radius: 20px;
}
.welcome-modal-header {
  height: 100px;
  border-bottom: 1px #d6d6d6 solid;
  padding: 36px 64px;
  position: relative !important;
}
.welcome-close {
  position: absolute;
  right: 28px;
  top: 40px;
  background-color: rgba(29, 29, 31, 0.8);
}
.welcome-modal-body {
  padding: 20px 64px 0;
}
.button-bottom-welcome-modal {
  width: 113px !important;
  height: 43px !important;
  background-color: #003b46 !important;
  color: white !important;
  border-radius: 3px !important;
  padding: 16px 24px !important;
  font-family: "Proxima Nova A Semibold" !important;
  font-size: 16px !important;
  line-height: auto !important;
  letter-spacing: 0% !important;
  border: 0 !important;
}
.welcome-modal-footer {
  height: 83px;
  border-top: 1px #d6d6d6 solid;
  padding: 20px 64px;
}
.welcome-modal-image {
  max-width: 948px;
  min-width: calc(100% - 128px);
  height: 229px;
  background-color: #003b46 !important;
  margin-top: 47px;
}
</style>
