<template>
  <img src="@/assets/images/logo-nb.png" alt="" />
</template>
<script>
export default {
  data() {
    return {
      logo: "@/assets/images/logo-nb.png",
    };
  },
  methods: {},
};
</script>
<style></style>
