<template>
  <div class="container">
    <h1 class="title is-1">search users</h1>
    <div class="columns">
      <div class="column is-8">
        <input
          placeholder="Search..."
          type="search"
          v-model="filterEmail"
          icon="magnify"
          icon-clickable
          @icon-click="filter"
        />
      </div>

      <div class="column is-4">
        <button class="button button-small" @click="filter">Search</button>
      </div>
    </div>
    <div style="margin-bottom: 25px"><DataTable :tabledata="users" /></div>

    <div v-if="total > this.request.perPage" style="margin-top: 50px; margin-bottom: 50px">
      <Pagination
        :page_size="request.perPage"
        :page="currentPage"
        :pages="pages"
        @page="updatePage"
        :key="pages"
      />
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/TableComponent.vue";
import Pagination from "@/components/PaginationComponent.vue";
import UserService from "@/services/user.service";

export default {
  components: {
    DataTable,
    Pagination,
  },
  name: "UsersView",
  props: {},
  data() {
    return {
      users: [],
      search_user: "",
      filterId: "",
      filterEmail: "",
      filterRole: "",
      total: 0,
      currentPage: 1,
      pages: 1,
      request: {
        page: 1,
        perPage: 10,
      },
    };
  },
  methods: {
    updatePage(value) {
      this.request.page = value;
      this.getAllUsers();
    },
    filter() {
      let filter = {};

      if (this.filterEmail != "") {
        filter.email = this.filterEmail;
      }
      if (this.filterEmail) {
        this.request.filter = filter;
        this.getAllUsers();
      } else {
        this.request = {
          perPage: 10,
          page: 1,
        };
        this.getAllUsers();
      }
    },
    getAllUsers() {
      UserService.getAllUsers(this.request).then(
        (response) => {
          this.users = response.data.users;
          this.currentPage = response.data.page;
          this.pages = response.data.pageCount;
          this.total = response.data.total;
        },
        (error) => {
          let content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          console.error(content);
        }
      );
    },
  },
  mounted() {
    this.getAllUsers();
  },
  watch: {
    search_user() {
      this.request.page = 1;
    },
  },
  computed: {},
};
</script>
