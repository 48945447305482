<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="edit-modal-container">
          <div class="edit-modal-header">
            <h1 class="headline3">{{ $t("addressbook.modal.headeredit") }}</h1>
            <button class="edit-close" aria-label="close" @click="close()">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </div>
          <div class="edit-modal-body">
            <div class="editform">
              <div
                class="field"
                v-if="addressbook.accountType && addressbook.accountType === 'business'"
              >
                <label class="label">{{ $t("addressbook.modal.companyname") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.companyName"
                    :placeholder="$t('register.placeholder_company_name')"
                    :class="{ redBorder: v$.addressbook.companyName.$error }"
                    @blur="v$.addressbook.companyName.$touch"
                  />
                </div>
                <span v-if="v$.addressbook.companyName.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help"></p>
              </div>
              <div class="field">
                <label class="label">{{ $t("addressbook.modal.contactperson") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.fullName"
                    :placeholder="$t('addressbook.modal.contactperson_placeholder')"
                    :class="{ redBorder: v$.addressbook.fullName.$error }"
                    @blur="v$.addressbook.fullName.$touch"
                  />
                </div>
                <span v-if="v$.addressbook.fullName.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help"></p>
              </div>

              <div class="field prefix">
                <label class="label">{{ $t("register.country_code") }}</label>
                <div class="control dropdown-trigger">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.prefix"
                    :placeholder="$t('addressbook.searchinput')"
                    @click="toggleDropdown()"
                    readonly
                  />
                </div>
                <div name="arrowBox" class="arrowBox z-1">
                  <img src="@/assets/icons/PNG/chevron-right.png" alt="right" />
                </div>
                <div role="menu" class="dropdown-show-options" v-if="showDropdown">
                  <div
                    class="dropdown-content prefix-content box-shadow-unset"
                    v-for="(country, i) in countrycodes"
                    :key="i"
                  >
                    <div
                      class="dropdown-item columns mtb-0"
                      @click="selectPrefix(country.dial_code)"
                    >
                      <div class="column">
                        <span class="body-text-small text-black">{{ country.name }}</span>
                        <span class="body-text-small text-gray ml-10px">{{
                          country.dial_code
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field phonenumber">
                <label class="label mobilelabel">{{ $t("addressbook.modal.phonenumber") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.mobile"
                    :placeholder="$t('register.placeholder_personal_phone')"
                    :class="{ redBorder: v$.addressbook.mobile.$error }"
                    @blur="checkPhone"
                  />
                </div>
                <span v-if="!this.validPhone || v$.addressbook.mobile.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help">
                  <span v-if="!this.validPhone || v$.addressbook.mobile.$error">{{
                    $t("required_field")
                  }}</span>
                </p>
              </div>
              <div class="field email">
                <label class="label emaillabel">{{ $t("addressbook.modal.email") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.email"
                    :placeholder="$t('register.placeholder_personal_email')"
                  />
                </div>
                <p class="help"></p>
              </div>
              <div class="field post_sted">
                <label class="label">{{ $t("addressbook.modal.street") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.streetName"
                    :placeholder="$t('addressbook.modal.placeholder_streetname')"
                    :class="{ redBorder: v$.addressbook.streetName.$error }"
                    @blur="v$.addressbook.streetName.$touch"
                  />
                </div>
                <span v-if="v$.addressbook.streetName.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help"></p>
              </div>
              <div class="field post_number">
                <label class="label">{{ $t("addressbook.modal.number") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.streetNumber"
                    :placeholder="$t('addressbook.modal.placeholder_number')"
                    :class="{ redBorder: v$.addressbook.streetNumber.$error }"
                    @blur="v$.addressbook.streetNumber.$touch"
                  />
                </div>
                <span v-if="v$.addressbook.streetNumber.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help"></p>
              </div>
              <div class="field post_area">
                <label class="label">{{ $t("addressbook.modal.area") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.city"
                    :placeholder="$t('addressbook.modal.placeholder_area')"
                    :class="{ redBorder: v$.addressbook.city.$error }"
                    @blur="v$.addressbook.city.$touch"
                  />
                </div>
                <span v-if="v$.addressbook.city.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help"></p>
              </div>
              <div class="field post_zip">
                <label class="label">{{ $t("addressbook.modal.zip") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="addressbook.zip"
                    :placeholder="$t('addressbook.modal.placeholder_number')"
                    :class="{ redBorder: v$.addressbook.zip.$error }"
                    @blur="v$.addressbook.zip.$touch"
                  />
                </div>
                <span v-if="v$.addressbook.zip.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help"></p>
              </div>

              <div class="field">
                <label class="label">{{ $t("addressbook.modal.comment") }}</label>
                <div class="control">
                  <textarea
                    class="textarea is-small"
                    v-model="addressbook.note"
                    :placeholder="$t('addressbook.modal.placeholder_comment')"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="edit-modal-footer">
            <slot name="footer">
              <button class="button-medium-empty-b" @click="close()">
                <span class="sidebaricon">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                      fill="#66A5AD"
                    />
                  </svg>
                </span>

                {{ $t("addressbook.modal.cancel") }}
              </button>
              <button
                class="button button-bottom-welcome-modal float-right"
                :disabled="this.v$.addressbook.$invalid || !this.validPhone"
                @click="editAddress()"
              >
                <span class="sidebaricon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.10313 18.1766C9.44063 18.5141 10.0594 18.5141 10.3969 18.1766L20.7469 7.82656C21.0844 7.48906 21.0844 6.87031 20.7469 6.53281L19.4531 5.29531C19.1156 4.90156 18.5531 4.90156 18.2156 5.29531L9.77813 13.7328L5.78438 9.79531C5.44688 9.40156 4.88438 9.40156 4.54688 9.79531L3.25312 11.0328C2.91563 11.3703 2.91563 11.9891 3.25312 12.3266L9.10313 18.1766Z"
                      fill="white"
                    />
                  </svg>
                </span>
                {{ $t("addressbook.modal.save") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import countrycodes from "@/countrycodes.json";
import notification from "@/notification.js";
import UserService from "@/services/user.service";
import postalcodes from "@/postalcodes.json";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, requiredIf, numeric } from "@vuelidate/validators";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
export default {
  props: {
    show: Boolean,
    selected: String,
  },
  emits: ["closeEdit"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      countrycodes,
      isFocusDelEdit: false,
      showDropdown: false,
      validPhone: true,
      verifiedPhone: false,
      sent: false,
      addressbook: {
        accountType: "individual",
        companyName: "",
        fullName: "",
        prefix: "+47",
        mobile: "",
        email: "",
        streetName: "",
        streetNumber: "",
        zip: "",
        city: "",
        note: "",
      },
    };
  },
  validations() {
    return {
      addressbook: {
        companyName: {
          required: requiredIf(this.addressbook?.accountType === "business"),
          min: minLength(3),
        },
        fullName: {
          required,
          min: minLength(1),
        },
        mobile: {
          required,
          min: minLength(3),
        },
        streetName: {
          required,
          min: minLength(1),
        },
        streetNumber: {
          required,
          min: minLength(1),
        },
        zip: {
          required,
          numeric,
          min: minLength(4),
          max: maxLength(4),
        },
        city: {
          required,
          min: minLength(1),
        },
      },
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectPrefix(prefix) {
      this.addressbook.prefix = prefix;
      this.showDropdown = false;
    },
    async checkPhone() {
      const { prefix } = this.addressbook;
      const fullnumber = prefix + this.addressbook.mobile;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      };
      if (this.addressbook.mobile.length > 2) {
        this.axios
          .get(
            `https://authentication.${API_ENV}.${API_URL}/authentication/validate/phone/${fullnumber}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status_message === "Success") {
              this.validPhone = true;
              this.verifiedPhone = true;
            } else {
              this.validPhone = false;
              if (this.loading) {
                notification.toast("error", this.$t("register.wrong_phone"));
                this.loading = false;
              }
            }
          });
      }
    },
    close() {
      this.sent = true;
      this.addressbook = {
        accountType: "individual",
        companyName: "",
        fullName: "",
        prefix: "+47",
        mobile: "",
        email: "",
        streetName: "",
        streetNumber: "",
        zip: "",
        city: "",
        note: "",
      };
      this.$emit("closeEdit");
    },
    editAddress() {
      this.sent = true;
      const entry = {
        accountType: this.addressbook.accountType,
        id: this.addressbook.id,
        companyName: this.addressbook.companyName,
        organizationNumber: "",
        fullName: this.addressbook.fullName,
        email: this.addressbook.email,
        prefix: this.addressbook.prefix,
        mobile: this.addressbook.mobile,
        streetName: this.addressbook.streetName,
        streetNumber: this.addressbook.streetNumber,
        zip: this.addressbook.zip,
        city: this.addressbook.city,
        country: this.addressbook.country,
        note: this.addressbook.note,
        bookmark: this.addressbook.bookmark,
        defaultValue: this.addressbook.defaultValue,
        sender: this.addressbook.sender,
        belongTo: this.addressbook.belongTo,
      };
      UserService.updateAddressbook(entry).then(
        () => {
          this.$emit("closeEdit");
          this.addressbook = {
            accountType: "individual",
            companyName: "",
            fullName: "",
            prefix: "+47",
            mobile: "",
            email: "",
            streetName: "",
            streetNumber: "",
            zip: "",
            city: "",
            note: "",
          };
          notification.toast("success", this.$t("addressbook.edited_notification"));
        },
        (error) => {
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.$t("forgot_password.problem"));
        }
      );
    },
  },
  mounted() {
    this.addressbook.belongTo = this.$store.state.userId;
  },
  watch: {
    selected(newValue) {
      const all = this.$store.state.addressbook;
      this.addressbook = all.find((item) => item.id == newValue);
    },
    "addressbook?.zip": function () {
      if (this.addressbook.zip.length == 4) {
        const match = postalcodes[this.addressbook.zip];
        if (match != null || match != undefined) {
          this.addressbook.city = match[0].toUpperCase() + match.slice(1).toLowerCase();
        }
      }
    },
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.edit-close {
  position: absolute !important;
  right: 28px;
  top: 40px;
  background-color: rgb(255, 255, 255) !important;
  border: 0 !important;
}
.edit-modal-container {
  width: unset !important;
  max-width: 525px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  margin-top: 25px;
  margin-bottom: 25px;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .edit-modal-container,
.modal-leave-to .edit-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.edit-modal {
  background-color: white;
  border-radius: 20px;
}
.edit-modal-header {
  height: 100px;
  border-bottom: 1px #d6d6d6 solid;
  padding: 36px 64px;
  position: relative !important;
}
.edit-close {
  position: absolute;
  right: 28px;
  top: 40px;
  background-color: rgb(255, 255, 255);
}
.edit-modal-body {
  padding: 20px 0px 0;
}
.button-bottom-welcome-modal {
  width: 113px !important;
  height: 43px !important;
  background-color: #003b46 !important;
  color: white !important;
  border-radius: 3px !important;
  padding: 16px 24px !important;
  font-family: "Proxima Nova A Semibold" !important;
  font-size: 16px !important;
  line-height: auto !important;
  letter-spacing: 0% !important;
  border: 0 !important;
}
.edit-modal-footer {
  height: 83px;
  border-top: 1px #d6d6d6 solid;
  padding: 20px 64px;
}
.edit-modal-image {
  max-width: 948px;
  min-width: calc(100% - 128px);
  height: 229px;
  background-color: #003b46 !important;
  margin-top: 47px;
}
.edit-modal-container {
  padding: 0 !important;
}
.radioselect {
  height: 18px !important;
  border: 2px solid #07575b !important;
  width: 50px !important;
}
.radioselect:checked {
  accent-color: #5cdb95 !important;
  background-color: white !important;
  filter: invert(100%) hue-rotate(180deg) brightness(1.5);
}
.editform {
  display: inline-block !important;
  padding: 40px !important;
}
.edit-modal-footer {
  height: 83px;
  border-top: 1px #d6d6d6 solid;
  padding: 20px;
}
</style>
