<template>
  <div class="dis-inline">
    <img
      src="@/assets/icons/PNG/info-circle.png"
      class="s2-control-img"
      @mouseover="show = true"
      @touchstart="show = true"
      @mouseleave="show = false"
      @touchend="show = false"
      alt="info"
    />
  </div>
  <div class="tooltip dis-inline" v-if="show">
    <div
      :class="`tooltip-body tooltip-${vertical}-${horizontal} tooltip-${theme}`"
      style="position: fixed; width: 262px"
    >
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  name: "ToolTip",
  props: ["vertical", "horizontal", "theme", "text"],

  components: {},
  data() {
    return {
      show: false,
    };
  },
  methods: {},
};
</script>
<style></style>
