<template>
  <LoaderComponent v-if="loading" />
  <div class="columns ml-0 mr-0">
    <div class="column">
      <h1 class="title is-size-3">{{ $t("settings.preference.language") }}</h1>
      <hr />
      <div class="field">
        <label class="label">{{
          $t("settings.preference.select_language")
        }}</label>
        <div class="control">
          <select
            expanded
            class="input select"
            :placeholder="$t('settings.language.title')"
            v-model="selectedLocal"
          >
            <option
              v-for="(language, i) in languages"
              :value="language.code"
              :key="i"
            >
              {{ language.name }}
            </option>
          </select>
          <div name="arrowBoxLan" class="arrowBoxLan">
            <i class="fas fa-solid fa-angle-down fa-lg"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mt-4 mr-3"
    style="margin-bottom: 100px !important"
    :class="{ mobilePaddingBottomPref: isMobile }"
  >
    <button
      type="submit"
      @click="saveLanguage()"
      class="button-medium has-sherpa float-right serviceButton"
    >
      {{ $t("settings.save") }}
      <span>
        <img
          src="@/assets/icons/PNG/check-circle-white.png"
          style="margin-top: -5px; vertical-align: middle; position: relative"
          @mouseover="show = true"
          @mouseleave="show = false"
          alt="info"
        />
      </span>
    </button>
  </div>
  <div class="columns ml-0 mr-0 settingsPreferenceMT">
    <div class="column">
      <h1 class="title is-size-3">
        {{ $t("settings.preference.notifications") }}
      </h1>
      <hr />
      <div class="columns">
        <div class="column is-half-mobile notificationText float-left">
          <p class="mt-14px">
            {{ $t("settings.preference.info_delivery") }}
            <span class="ml-6px">
              <ToolTip
                vertical="top"
                horizontal="left"
                theme="light"
                :text="$t('settings.preference.info_delivery_tooltip')"
              />
            </span>
          </p>
        </div>
        <div class="column is-half-mobile notificationChoice float-right">
          <div class="columns">
            <div class="column is-4 is-hidden-mobile has-text-right"></div>
            <div class="column float-left is-4 has-text-right">
              <div class="field">
                <label class="label">{{
                  $t("settings.preference.email")
                }}</label>
                <input type="checkbox" class="sq-25" v-model="email" />
              </div>
            </div>
            <div class="column float-right is-4 has-text-right">
              <div class="field">
                <label class="label">{{ $t("settings.preference.sms") }}</label>
                <input type="checkbox" class="sq-25" v-model="sms" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mt-4 mr-3"
    style="margin-bottom: 100px !important"
    :class="{ mobilePaddingBottomPref: isMobile }"
  >
    <button
      type="submit"
      @click="saveNotification()"
      class="button-medium has-sherpa float-right serviceButton"
    >
      {{ $t("settings.save") }}
      <span>
        <img
          src="@/assets/icons/PNG/check-circle-white.png"
          style="margin-top: -5px; vertical-align: middle; position: relative"
          @mouseover="show = true"
          @mouseleave="show = false"
          alt="info"
        />
      </span>
    </button>
  </div>
  <div class="columns ml-0 mr-0 settingsPreferenceMT">
    <div class="column">
      <h1 class="title is-size-3">
        {{ $t("settings.preference.service_tos") }}
      </h1>
      <hr />
      <div
        class="columns is-mobile mt-14px"
        v-for="(single, m) in tos"
        :key="m"
      >
        <div class="column float-left" style="min-width: 90px">
          <p>{{ capitalize(single.service) }}</p>
          <div
            style="background-color: #fcea86; width: fit-content"
            v-if="single.updated !== 0"
          >
            <span
              class="ml-1 mr-1"
              style="font-family: 'Proxima Nova A'; font-size: 12px"
              >{{ $t("settings.preference.updated") }}</span
            >
          </div>
        </div>
        <div class="column">
          <a :href="single.link" target="_blank" style="display: inline-flex">
            <img src="@/assets/icons/PNG/arrow-alt-to-bottom.png" alt="right" />
            <span
              v-if="!isMobile"
              class="pl-3"
              style="
                color: #07575b;
                font-family: 'Proxima Nova A';
                color: #6e6e6e;
              "
              >{{ $t("settings.preference.download") }}</span
            >
          </a>
        </div>
        <div class="column service-r-option float-right">
          <div class="columns is-mobile">
            <div class="column is-2 is-hidden-mobile has-text-right"></div>
            <div
              :class="{ 'pt-0': isMobile }"
              class="column is-6 float-left has-text-right"
            >
              <span
                v-if="single.accepted"
                style="color: #66a5ad; font-family: 'Proxima Nova A'"
                >{{ $t("settings.preference.accepted") }}</span
              >
              <span v-else style="font-family: 'Proxima Nova A'">{{
                $t("settings.preference.not_accepted")
              }}</span>
            </div>
            <div
              :class="{ 'pt-0': isMobile }"
              class="column is-4-desktop is-6-mobile float-right has-text-right"
            >
              <div class="field">
                <input
                  type="checkbox"
                  class="sq-25"
                  v-model="single.accepted"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mb-6 mr-3 settingsPreferenceMT pb-5"
    :class="{ mobilePaddingBottomPref: isMobile }"
  >
    <button
      type="submit"
      @click="saveTerms()"
      class="button-medium has-sherpa float-right serviceButton"
    >
      {{ $t("settings.save") }}
      <span>
        <img
          src="@/assets/icons/PNG/check-circle-white.png"
          style="margin-top: -5px; vertical-align: middle; position: relative"
          @mouseover="show = true"
          @mouseleave="show = false"
          alt="info"
        />
      </span>
    </button>
  </div>
</template>
<script>
import ToolTip from "@/components/ToolTip.vue";
import UserService from "@/services/user.service";
import notification from "@/notification.js";
import LoaderComponent from "@/components/LoaderComponent.vue";
import useVuelidate from "@vuelidate/core";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export default {
  components: { ToolTip, LoaderComponent },
  name: "SettingsPreferences",
  props: ["isMobile", "active"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      data: [],
      loading: true,
      languages: [
        { name: this.$t("settings.preference.norwegian"), code: "nb" },
        { name: this.$t("settings.preference.english"), code: "en" },
      ],
      selectedLocal: "",
      email: false,
      sms: false,
      service: false,
      tos: [],
      currencies: [
        { name: "NOK", symbol: "kr" },
        { name: "EUR", symbol: "€" },
        { name: "DKK", symbol: "kr" },
        { name: "SEK", symbol: "kr" },
        { name: "GBP", symbol: "£" },
        { name: "USD", symbol: "$" },
        { name: "CHF", symbol: "CHF" },
      ],
      selectedCurrency: [],
    };
  },
  validations() {
    return {};
  },
  methods: {
    capitalize(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    save() {
      this.$root.$i18n.locale = this.selectedLocal;

      ls.set("locale", this.selectedLocal);
    },
    saveLanguage() {
      this.$root.$i18n.locale = this.selectedLocal;
      ls.set("locale", this.selectedLocal);
      UserService.savePreferences(this.belongTo, {
        selectedLocal: this.selectedLocal,
      }).then(
        () => {
          notification.toast("success", this.$t("settings.notification_saved"));
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message;
          error.toString();
          notification.toast("error", this.$t("settings.notification_error"));
        }
      );
    },
    saveNotification() {
      UserService.saveNotifications(this.belongTo, {
        notificationByEmail: this.email,
        notficationBySms: this.sms,
      }).then(
        () => {
          notification.toast("success", this.$t("settings.notification_saved"));
        },
        (error) => {
          this.content =
            error.response &&
            error.response.data &&
            error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.$t("settings.notification_error"));
        }
      );
    },
    saveTerms() {
      let services = [];
      let userId = this.$store.state.userId;
      for (let s of this.tos) {
        if (s.accepted) {
          services.push({ serviceName: s.service, userId: userId });
        }
      }

      UserService.saveAllTos(services, userId).then((res) => {
        console.log(res);
        notification.toast("success", this.$t("settings.notification_saved"));
      });
    },
    getLanguage() {
      const locale = ls.get("locale");
      this.selectedLocal = locale || "nb";
      this.$root.$i18n.locale = this.selectedLocal;
    },
    getId() {
      this.belongTo = this.$store.state.userId;
    },
    getData() {
      UserService.getProfile(this.belongTo).then(
        (response) => {
          this.selectedLocal =
            response.data.Setting?.language != undefined ||
            response.data.Setting?.language != null
              ? response.data.Setting.language
              : "nb";
          this.email = response.data.Setting?.notifyByEmail;
          this.sms = response.data.Setting?.notifyBySms;
          this.currency = response.data.Setting?.currency
            ? response.data.Setting.currency
            : "NOK";
          this.loading = false;
        },
        (error) => {
          this.content =
            error.response &&
            error.response.data &&
            error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.$t("settings.notification_error"));
          this.loading = false;
        }
      );
    },
    getTos() {
      UserService.getAllTos(this.$store.state.userId).then((res) => {
        this.tos = res.data;
      });
    },
  },
  mounted() {
    this.data = this.$store.state.userData;
    this.getId();
    if (this.active) {
      this.getLanguage();
      this.getData();
      this.getTos();
    }
  },
  watch: {
    active() {
      if (this.active) {
        this.getData();
        this.getTos();
      } else {
        this.loading = true;
      }
    },
  },
};
</script>
<style scoped>
hr {
  background-color: #e7e1dc;
  border: none;
  height: 1px;
  width: 100%;
}
</style>
