<template>
  <Transition name="modal">
    <div class="loader-container" ref="loader" tabindex="0" @keydown.esc="escaped()">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  props: {},
  emits: ["escape"],
  data() {
    return {};
  },
  methods: {
    escaped() {
      this.$emit("escape");
    },
  },
  mounted() {
    this.$refs.loader.focus();
  },
};
</script>
<style></style>
