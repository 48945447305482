<template>
  <section class="section is-paddingless">
    <div class="columns is-gapless"></div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "DefaultView",
  components: {},
};
</script>
