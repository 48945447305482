<template>
  <LoaderComponent v-if="validating" />
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered">
        <div
          class="column is-half-desktop is-half-tablet is-full-mobile p-0 max-590"
        >
          <FraktBestillingLogo />
          <h1
            class="title is-size-2 has-text-mosque"
            style="font-size: 44px !important; margin-bottom: 67px !important"
          >
            {{ $t("login.title") }}<span class="font-light">.no</span>
          </h1>
          <div class="auth-container" v-if="!this.validating && this.validated">
            <div class="has-text-black">
              <p class="body-text-title">
                {{ $t("forgot_password.set_new_password") }}
              </p>
            </div>
            <div class="field mt-large">
              <label class="label">{{
                $t("forgot_password.new_password")
              }}</label>
              <div class="control">
                <input
                  id="password"
                  class="input"
                  type="password"
                  v-model="form.password"
                  :placeholder="$t('login.placeholder_password')"
                  :class="{ redBorder: v$.form.password.$error }"
                  @blur="v$.form.password.$touch"
                />
              </div>
              <span class="field-icon">
                <i
                  id="eye-password"
                  class="fas fa-eye"
                  @click="showHidePassword('password')"
                ></i>
              </span>
              <p class="help">
                <span v-if="v$.form.password.$error"
                  >{{ $t("required_field") }}
                </span>
              </p>
            </div>
            <div class="field" style="margin-bottom: 48px !important">
              <label class="label">{{
                $t("forgot_password.new_password_repeat")
              }}</label>
              <div class="control">
                <input
                  id="repeatPassword"
                  class="input"
                  type="password"
                  v-model="form.passwordRepeat"
                  :placeholder="$t('login.placeholder_password')"
                  :class="{ redBorder: v$.form.passwordRepeat.$error }"
                  @blur="v$.form.passwordRepeat.$touch"
                  v-on:keyup.enter="login"
                />
              </div>
              <span class="field-icon">
                <i
                  id="eye-repeatPassword"
                  class="fas fa-eye"
                  @click="showHidePassword('repeatPassword')"
                ></i>
              </span>
              <p class="help">
                <span v-if="v$.form.password.$error"
                  >{{ $t("required_field") }}
                </span>
              </p>
            </div>
            <div class="field mt-medium" style="margin-bottom: 52px !important">
              {{ $t("forgot_password.go_back") }}

              <router-link to="/login">
                {{ $t("login.login").toLowerCase() }}
              </router-link>
            </div>
            <div
              class="field"
              style="border-top: 1px solid #d6d6d6; text-align: right"
            >
              <button
                class="button-medium mt-27px"
                :class="{ 'is-loading': loading }"
                @click="reset()"
                :disabled="this.v$.form.$invalid"
              >
                <span class="sidebaricon">
                  <img
                    src="@/assets/icons/PNG/check-circle-white.png"
                    style="
                      margin-top: -5px;
                      vertical-align: middle;
                      position: relative;
                    "
                    @mouseover="show = true"
                    @mouseleave="show = false"
                    alt="info"
                  />
                </span>
                {{ $t("forgot_password.reset_password") }}
              </button>
            </div>
          </div>

          <div
            class="auth-container"
            v-if="!this.validating && !this.validated"
          >
            <div class="has-text-black">
              <p class="body-text-title">
                {{ $t("forgot_password.token_problem") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FraktBestillingLogo from "@/components/FraktBestillingLogo.vue";
import useVuelidate from "@vuelidate/core";
import { required, sameAs, minLength } from "@vuelidate/validators";
import LoaderComponent from "@/components/LoaderComponent.vue";
import notification from "@/notification.js";
import ls from "localstorage-slim";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
ls.config.encrypt = true;
export default {
  name: "ResetToken",
  components: { FraktBestillingLogo, LoaderComponent },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      token: "",
      loading: false,
      validating: true,
      validated: false,
      form: {
        password: "",
        passwordRepeat: "",
      },
    };
  },
  validations() {
    return {
      form: {
        password: {
          required,
          min: minLength(8),
          valid(value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /\d/.test(value);
            // const containsSpecial = /[#?!@$%^&*-]/.test(value);
            return containsUppercase && containsLowercase && containsNumber;
          },
        },
        passwordRepeat: {
          required,
          sameAsPassword: sameAs(this.form.password),
        },
      },
    };
  },
  methods: {
    showHidePassword(val) {
      const x = document.getElementById(val);
      const eye = document.getElementById("eye-" + val);
      eye.classList.toggle("fa-eye");
      eye.classList.toggle("fa-eye-slash");
      x.type = x.type === "text" ? "password" : "text";
    },
    match() {
      if (this.form.password !== this.form.passwordRepeat) {
        this.v$.$touch("passwordRepeat");
        return false;
      }
      return true;
    },
    reset() {
      const that = this;
      this.loading = true;
      this.axios
        .post(
          `https://authentication.${API_ENV}.${API_URL}/authentication/forgot/password/reset/${this.token}`,
          {
            password: this.form.password,
          }
        )
        .then(() => {
          notification.toast(
            "success",
            this.$t("forgot_password.successful_reset")
          );

          that.loading = false;
          ls.set("token", "");
          that.$router.push({ path: "/" });
        })
        .catch(() => {
          notification.toast("error", this.$t("login.toast_network_error"));
          that.loading = false;
        });
    },
    validateToken() {
      if (this.token.length > 1) {
        setTimeout(() => {
          this.validating = false;
          this.validated = true;
        }, 2000);
      }
    },
  },
  mounted() {
    this.token = this.$route.params.token;
    this.validateToken();
  },
};
</script>
