<template>
  <div>
    <h1 class="headline3 bottom64 is-hidden-mobile">
      {{ $t("new_delivery.header") }}
      <span class="headline-thin">{{ $t("new_delivery.step2.header") }}</span>
    </h1>
    <br />
    <div class="columns mt-0 mb-0">
      <div class="ml-3 column p-0 pos-relative">
        <h2 class="body-text-title step2label">
          {{ $t("new_delivery.step2.description_upper_label") }}
        </h2>
        <div v-show="form.title.length > 1" class="selectedGreenS2">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
              fill="#5CDB95"
            />
          </svg>
        </div>
        <hr class="mt-2 mb-2 is-hidden-mobile" />
        <div class="field z-1">
          <label class="label pos-relative"
            >{{ $t("new_delivery.step2.description_label") }}
            <p class="dis-inline ml-12px">
              <ToolTip
                vertical="top"
                horizontal="left"
                theme="light"
                :text="$t('new_delivery.step2.tooltip_description')"
              />
            </p>
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="form.title"
              :placeholder="$t('new_delivery.step2.description')"
              :class="{ redBorder: v$.form.title.$error }"
              @blur="v$.form.title.$touch"
            />
          </div>
          <span v-if="v$.form.title.$error" class="field-icon">
            <i class="fas fa-exclamation-circle is-red"></i>
          </span>
          <p class="help">
            <span v-if="v$.form.title.$error">{{ $t("required_field") }} </span>
          </p>
        </div>
      </div>
    </div>
    <hr class="mt-0 mb-2" />
    <div class="columns has-text-left mt-2 is-hidden-desktop">
      <div class="column pb-0 pos-relative">
        <span class="body-text-title">{{ $t("new_delivery.step2.packages") }}</span>
        <div v-if="totalVolume() > 0 && totalWeight() > 0" class="selectedGreenS2Pack">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
              fill="#5CDB95"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="columns is-mobile has-text-left mt-2 pos-relative">
      <div class="column is-hidden-touch">
        <span class="body-text-title">{{ $t("new_delivery.step2.packages") }}</span>
        <div v-if="totalVolume() > 0" class="selectedGreenS2Pack">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
              fill="#5CDB95"
            />
          </svg>
        </div>
      </div>
      <div class="column pl-0 mob-flex-grow-un">
        <div class="packages">
          <img src="/icons/SVG/items.svg" alt="items" style="transform: translate(0px, 7px)" />
          <span class="has-text-gray technical18 is-hidden-touch"
            >{{ $t("new_delivery.step2.total_count") }}&nbsp;{{ totalItems() }}&nbsp;{{
              $t("new_delivery.step2.items_count")
            }}</span
          >
          <span class="has-text-gray technical18 is-hidden-desktop">{{ totalItems() }}</span>
        </div>
      </div>
      <div class="column pl-0 mob-flex-grow-un">
        <div class="packages">
          <img src="/icons/SVG/volume.svg" alt="volume" style="transform: translate(0px, 7px)" />
          <span class="has-text-gray technical18 nowrap is-hidden-touch"
            >{{ $t("new_delivery.step2.total_volume") }} {{ totalVolume() }} m³</span
          >
          <span class="has-text-gray technical18 is-hidden-desktop">
            {{ totalVolume() }}
            m³
          </span>
        </div>
      </div>
      <div class="column pl-0 mob-flex-grow-un">
        <div class="packages">
          <img src="/icons/SVG/weight.svg" alt="weight" style="transform: translate(0px, 7px)" />
          <span class="has-text-gray technical18 is-hidden-touch"
            >{{ $t("new_delivery.step2.total_weight") }} {{ totalWeight() }} kg</span
          >
          <span class="has-text-gray technical18 is-hidden-desktop"> {{ totalWeight() }} kg </span>
        </div>
      </div>
      <div class="column is-hidden-mobile">
        <span class="has-text-gray technical18"></span>
      </div>
    </div>
    <hr class="mt-0" />
    <div
      class="single-item-nt columns is-mobile is-multiline mb-0 is-relative mb-45px is-hidden-mobile"
      v-for="(obj, i) in this.form.items.length"
      :key="i"
    >
      <div class="column is-one-sixth-desktop is-half-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.items") }}</label>
          <!--<div class="control">
            <select
              class="input"
              type="number"
              v-model.lazy="form.items[i].quantity"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].quantity.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            >
            <option>
              1
            </option>
            <option>
              2
            </option>
            <option>
              3
            </option>
            <option>
              4
            </option>
            <option>
              5
            </option>
            </select>
          </div>
          <button class="items-label" tabindex="-1">
            <img src="@/assets/icons/PNG/chevron-right.png" alt="right" />
          </button>-->
          <div class="control dropdown-trigger">
            <input
              class="input"
              type="text"
              v-model.lazy="form.items[i].quantity"
              placeholder="0"
              @click="toggleDropdown(i)"
              name="showMenu"
            />
          </div>
          <div name="arrowBox" class="arrowBoxItems z-1">
            <img src="@/assets/icons/PNG/chevron-right.png" alt="right" />
          </div>
          <div role="menu" class="dropdown-show-options-items" v-if="visibleDrop === i">
            <div
              class="dropdown-content items-prefix-content box-shadow-unset"
              v-for="(single, j) in 60"
              :key="j"
            >
              <div
                class="dropdown-item columns mtb-0"
                @click="
                  form.items[i].quantity = single;
                  toggleDropdown(i);
                "
              >
                <div class="column">
                  <span class="body-text-small text-black">{{ single }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-sixth-desktop is-half-mobile">
        <div class="field">
          <label class="label" v-if="i == 0"
            >{{ $t("new_delivery.step2.weight") }}
            <p class="dis-inline ml-12px">
              <ToolTip
                vertical="top"
                horizontal="left"
                theme="light"
                :text="$t('new_delivery.step2.tooltip_weight')"
              />
            </p>
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
              v-model="form.items[i].weight"
              step="0.1"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].weight.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="00.000"
            />
          </div>

          <button class="items-label" tabindex="-1">kg</button>
        </div>
      </div>
      <div class="column is-one-sixth-desktop is-one-third-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.length") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              v-model="form.items[i].length"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].length.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            />
          </div>
          <button class="items-label" tabindex="-1">cm</button>
        </div>
      </div>
      <div class="column is-one-sixth-desktop is-one-third-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.width") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              v-model="form.items[i].width"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].width.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            />
          </div>
          <button class="items-label" tabindex="-1">cm</button>
        </div>
      </div>
      <div class="column is-one-sixth-desktop is-one-third-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.height") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              v-model="form.items[i].height"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].height.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            />
          </div>
          <button class="items-label" tabindex="-1">cm</button>
        </div>
      </div>

      <div
        class="column pl-0 pr-0 is-one-sixth-desktop is-hidden-mobile"
        @mouseover="showControl(i, true)"
        @mouseleave="showControl(i, false)"
      >
        <div :class="{ 'mt-24': i === 0 }">
          <Step2Control
            vertical="top"
            horizontal="left"
            theme="light"
            :id="i"
            @controlclone="controlClone"
            @controlsavetemplate="controlSaveTemplate"
            @controlcancel="controlCancel"
            v-if="showControlVal === i"
          />
        </div>
      </div>
      <div class="s2-more-button">
        <button
          class="button-small-empty"
          style="padding: 0 16px; white-space: nowrap"
          @click="showMobileControl(i)"
        >
          <svg
            width="3"
            height="13"
            viewBox="0 0 3 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="3" height="13" fill="#E5E5E5" />
            <path
              d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z"
              fill="#66A5AD"
            />
            <path
              d="M3 6.5C3 7.32843 2.32843 8 1.5 8C0.671573 8 0 7.32843 0 6.5C0 5.67157 0.671573 5 1.5 5C2.32843 5 3 5.67157 3 6.5Z"
              fill="#66A5AD"
            />
            <path
              d="M3 11.5C3 12.3284 2.32843 13 1.5 13C0.671573 13 0 12.3284 0 11.5C0 10.6716 0.671573 10 1.5 10C2.32843 10 3 10.6716 3 11.5Z"
              fill="#66A5AD"
            />
          </svg>
          <span
            style="
              font-size: 16px;
              line-height: 26px;
              text-align: center;
              color: #07575b;
              margin-left: 20px;
            "
            >{{ $t("new_delivery.step2.more_functions_mobile") }}</span
          >
        </button>
      </div>
    </div>
    <div
      class="single-item-nt columns is-mobile is-multiline mb-0 is-relative mb-45px is-hidden-desktop is-hidden-tablet"
      v-for="(obj, i) in this.form.items.length"
      :key="i"
    >
      <div class="column is-one-sixth-desktop is-one-third-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.length") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              v-model="form.items[i].length"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].length.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            />
          </div>
          <button class="items-label" tabindex="-1">cm</button>
        </div>
      </div>
      <div class="column is-one-sixth-desktop is-one-third-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.width") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              v-model="form.items[i].width"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].width.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            />
          </div>
          <button class="items-label" tabindex="-1">cm</button>
        </div>
      </div>
      <div class="column is-one-sixth-desktop is-one-third-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.height") }}</label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              v-model="form.items[i].height"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].height.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            />
          </div>
          <button class="items-label" tabindex="-1">cm</button>
        </div>
      </div>
      <div class="column is-one-sixth-desktop is-half-mobile">
        <div class="field">
          <label class="label" v-if="i == 0"
            >{{ $t("new_delivery.step2.weight") }}
            <p class="dis-inline ml-12px">
              <ToolTip
                vertical="top"
                horizontal="left"
                theme="light"
                :text="$t('new_delivery.step2.tooltip_weight')"
              />
            </p>
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              v-model="form.items[i].weight"
              step="0.1"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].weight.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="00.000"
            />
          </div>

          <button class="items-label" tabindex="-1">kg</button>
        </div>
      </div>

      <div class="column is-one-sixth-desktop is-half-mobile">
        <div class="field">
          <label class="label" v-if="i == 0">{{ $t("new_delivery.step2.items") }}</label>
          <div class="control">
            <input
              class="input"
              type="number"
              v-model.lazy="form.items[i].quantity"
              :class="{
                redBorder:
                  v$.$errors.length > 0 &&
                  v$.form.items.$each.$response.$errors[i].quantity.length > 0,
              }"
              @blur="v$.form.$touch"
              placeholder="0"
            />
          </div>
          <button class="items-label" tabindex="-1">
            <img src="@/assets/icons/PNG/chevron-right.png" alt="right" />
          </button>
        </div>
      </div>
      <div
        class="column pl-0 pr-0 is-one-sixth-desktop is-hidden-mobile"
        @mouseover="showControl(i, true)"
        @mouseleave="showControl(i, false)"
      >
        <div :class="{ 'mt-24': i === 0 }">
          <Step2Control
            vertical="top"
            horizontal="left"
            theme="light"
            :id="i"
            @controlclone="controlClone"
            @controlsavetemplate="controlSaveTemplate"
            @controlcancel="controlCancel"
            v-if="showControlVal === i"
          />
        </div>
      </div>
      <div class="s2-more-button">
        <button
          class="button-small-empty"
          style="padding: 0 16px; white-space: nowrap"
          @click="showMobileControl(i)"
        >
          <svg
            width="3"
            height="13"
            viewBox="0 0 3 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="3" height="13" fill="#E5E5E5" />
            <path
              d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z"
              fill="#66A5AD"
            />
            <path
              d="M3 6.5C3 7.32843 2.32843 8 1.5 8C0.671573 8 0 7.32843 0 6.5C0 5.67157 0.671573 5 1.5 5C2.32843 5 3 5.67157 3 6.5Z"
              fill="#66A5AD"
            />
            <path
              d="M3 11.5C3 12.3284 2.32843 13 1.5 13C0.671573 13 0 12.3284 0 11.5C0 10.6716 0.671573 10 1.5 10C2.32843 10 3 10.6716 3 11.5Z"
              fill="#66A5AD"
            />
          </svg>
          <span
            style="
              font-size: 16px;
              line-height: 26px;
              text-align: center;
              color: #07575b;
              margin-left: 20px;
            "
            >{{ $t("new_delivery.step2.more_functions_mobile") }}</span
          >
        </button>
      </div>
    </div>
    <div class="is-hidden-desktop mt-4"></div>
    <button class="button-medium-empty-b" @click="addItems()">
      {{ $t("new_delivery.step2.add_box") }}
      <span class="ml-12px"
        ><img
          src="@/assets/icons/PNG/plus.png"
          style="height: 15px; margin-top: -2px; vertical-align: middle"
          alt="add"
      /></span>
    </button>

    <!-- <button
      class="button-medium-empty-mint"
      style="margin-left: 25px"
      @click="showTemplateModal = true"
    >
      {{ $t("new_delivery.step2.add_from_template") }}
      <span class="ml-12px"
        ><img
          src="@/assets/icons/PNG/file.png"
          style="margin-top: -2px; vertical-align: middle"
          alt="add"
      /></span>
    </button> -->
    <div class="is-hidden-desktop mb-4"></div>
  </div>
  <MoreModal
    :show="showMoreModal"
    @controlclone="controlClone(showControlVal)"
    @controlsavetemplate="controlSaveTemplate(showControlVal)"
    @controlcancel="controlCancel(showControlVal)"
    @close="closeShowMoreModalFun"
  />
  <StepTwoTemplateModal
    :show="showTemplateModal"
    @close="showTemplateModal = false"
    @loadtemplate="loadTemplate"
  />
</template>
<script>
import ToolTip from "@/components/ToolTip.vue";
import Step2Control from "@/components/Step2Control.vue";
import StepTwoTemplateModal from "@/components/newtask/StepTwoTemplateModal.vue";
import MoreModal from "@/components/newtask/MoreModal.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, required, minLength } from "@vuelidate/validators";

export default {
  name: "StepTwo",
  components: {
    ToolTip,
    Step2Control,
    StepTwoTemplateModal,
    MoreModal,
  },
  emits: ["evaluated"],
  props: ["active"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showControlVal: "",
      showMoreModal: false,
      template: [],
      showTemplateModal: false,
      showDropdown: false,
      visibleDrop: -1,
      selectableNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      form: {
        title: "",
        items: [
          {
            quantity: "1",
            weight: "",
            length: "",
            width: "",
            height: "",
          },
        ],
      },
      total: {
        items: 1,
        volume: 0,
        weight: 0,
      },
    };
  },
  validations() {
    return {
      form: {
        title: {
          required,
          min: minLength(2),
        },
        items: {
          $each: helpers.forEach({
            quantity: { required },
            weight: { required },
            length: { required },
            width: { required },
            height: { required },
          }),
        },
      },
    };
  },
  methods: {
    checkClick(e) {
      if (e.target.getAttribute("name") != "showMenu") {
        this.toggleDropdown(this.visibleDrop);
      }
    },
    toggleDropdown(val) {
      if (this.visibleDrop === val) {
        this.visibleDrop = -1;
      } else {
        this.visibleDrop = val;
      }

      //this.showDropdown = !this.showDropdown;
    },
    closeShowMoreModalFun() {
      this.showControlVal = "";
      this.showMoreModal = false;
    },
    controlClone(val) {
      this.form.items.push({
        length: this.form.items[val].length,
        width: this.form.items[val].width,
        height: this.form.items[val].height,
        weight: this.form.items[val].weight,
        quantity: this.form.items[val].quantity,
      });
      this.showMoreModal = false;
    },
    controlSaveTemplate(val) {
      this.template.push({
        length: this.form.items[val].length,
        width: this.form.items[val].width,
        height: this.form.items[val].height,
        weight: this.form.items[val].weight,
        quantity: this.form.items[val].quantity,
      });
      localStorage.setItem("template", JSON.stringify(this.template));
      this.showMoreModal = false;
    },
    controlCancel(val) {
      this.form.items.splice(val, 1);
      this.showMoreModal = false;
    },
    loadTemplate(value) {
      this.form.items.push({
        length: value.length,
        width: value.width,
        height: value.height,
        weight: value.weight,
        quantity: value.quantity,
      });
      this.showTemplateModal = false;
    },
    showControl(i, val) {
      if (val) {
        this.showControlVal = i;
      } else {
        this.showControlVal = "";
      }
    },
    showMobileControl(i) {
      this.showControlVal = i;
      this.showMoreModal = true;
    },
    addItems() {
      this.form.items.push({
        quantity: "1",
        size: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      });
    },
    totalItems() {
      return this.form.items.reduce((a, b) => parseFloat(a) + (parseFloat(b.quantity) || 0), 0);
    },
    totalWeight() {
      return this.form.items.reduce(
        (a, b) => parseFloat(a) + (parseFloat(b.weight) * parseFloat(b.quantity) || 0),
        0
      );
    },
    totalVolume() {
      let totalVolume = 0;
      this.form.items.forEach((item) => {
        totalVolume +=
          (parseInt(item.width) / 100) *
          (parseInt(item.height) / 100) *
          (parseInt(item.length) / 100) *
          parseInt(item.quantity);
      });
      // set to use max 2 decimals
      const countDecimals = function (value) {
        if (value % 1 != 0)
          if (value.toString().split(".")[1].length < 3) {
            return value.toString().split(".")[1].length;
          } else {
            return 3;
          }
        return 0;
      };
      return isNaN(totalVolume) ? 0 : totalVolume.toFixed(countDecimals(totalVolume));
    },
    getTemplate() {
      const template = localStorage.getItem("template");
      if (template) {
        this.template = JSON.parse(template);
      }
    },
    checkExistingData() {
      const { step2 } = this.$store.state;
      if (step2.title && step2.title.length > 0) {
        this.form = step2;
      }
    },
  },
  mounted() {
    this.getTemplate();
    this.checkExistingData();
    window.addEventListener("click", this.checkClick);
  },
  watch: {
    v$() {
      if (this.v$.form.$invalid) {
        this.$emit("evaluated", { step: 2, valid: false });
      } else {
        this.$store.commit("addStep2", this.form);
        this.$emit("evaluated", { step: 2, valid: true });
      }
    },
  },
};
</script>
<style scoped>
.column {
  min-height: 100%;
}

.help {
  min-height: 18px;
  color: red !important;
}
.is-red {
  color: red !important;
}
.field-icon {
  position: absolute;
  font-size: 18px;
  right: 15px;
  top: 38px;
}
.mobilelabel {
  margin-left: -76px;
}
.field {
  float: left;
  width: 100%;
  position: relative;
}
.mt-24 {
  margin-top: 24px;
}
</style>
