<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="moremodal-wrapper">
        <div class="moremodal-container">
          <div class="moremodal-body">
            <button @click="close()" class="more-mod-close-btn">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADWSURBVHgBhZExDoMwDEWTWNyDU4DYytaxBwGUbt3aG5SRsZ26pidou8EtOAJdyZDaqJGiyAhLlhL7xY6/hUCrqmonNswzUNe1Vko98jwXwzB8OLhpmrOU8pZl2VcBgMHLiPELJTiYcuijtdZA3/dTURRPDBzIw04hPM9z2XXdKH0lrXXqnHuhp39IxDAFZNg+eiRimEyFD9q2pYTxd5zNhPCiEjPgiSojPGGnfawecGrQN5IkuXNCwJoaa+pJWhy2v3IDxkKgH5fF4eHNwV4ILFgSQ4v7AdGQoVIqBbpPAAAAAElFTkSuQmCC"
                data-v-5824b2d2=""
                class="v-align-middle"
                alt="Close"
              />
            </button>
            <div class="body-p boxOptionsMod">
              <button class="boxOptionsBtnMod" @click="controlclone">
                <span class="sidebaricon">
                  <img src="@/assets/icons/PNG/clone.png" class="s2-control-img" alt="Clone" />
                </span>
                {{ $t("new_delivery.step2.tooltip_clone") }}
              </button>
              <button class="boxOptionsBtnMod" @click="controlsavetemplate">
                <span class="sidebaricon">
                  <img
                    src="@/assets/icons/PNG/template-gray.png"
                    class="s2-control-img"
                    alt="Save as template"
                  />
                </span>
                {{ $t("new_delivery.step2.tooltip_save_template") }}
              </button>
              <button class="boxOptionsBtnMod" @click="controlcancel">
                <span>
                  <span class="sidebaricon">
                    <img
                      src="@/assets/icons/PNG/times-gray.png"
                      class="s2-control-img"
                      alt="Delete"
                    />
                  </span>
                  {{ $t("new_delivery.step2.tooltip_remove") }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  props: {
    show: Boolean,
  },
  emits: ["close", "controlclone", "controlsavetemplate", "controlcancel"],
  data() {
    return {};
  },

  methods: {
    close() {
      this.$emit("close");
    },
    controlclone() {
      this.$emit("controlclone");
    },
    controlsavetemplate() {
      this.$emit("controlsavetemplate");
    },
    controlcancel() {
      this.$emit("controlcancel");
    },
  },
  watch: {},
};
</script>
<style>
.moremodal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.moremodal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.moremodal-container {
  width: 100%;
  min-height: 177px;
  margin: 0px auto;
  background-color: unset;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 4px;
}
.moremodal-header {
  border-bottom: 1px #d6d6d6 solid;
  height: 90px;
  padding: 30px 40px;
}
.moremodal-footer {
  border-top: 1px #d6d6d6 solid;
  padding: 18px;
}
.moremodal-body {
  padding: 0;
  overflow-x: auto;
}
.modal-default-button {
  float: right;
}
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.import-close {
  position: absolute !important;
  right: 30px;
  background: none;
  border: none;
  top: calc(50% - 6px);
}
.boxOptionsMod {
  position: absolute;
  background-color: #f2f2f2;
  left: 12.5%;
  width: 75%;
  height: 177px;
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}
.boxOptionsBtnMod {
  height: 59px;
  width: 100%;
  border: none;
  font-size: 18px;
  line-height: 26px;
  color: #6e6e6e;
  text-align: left;
  padding-left: 20px;
}
</style>
