<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="importmodal-wrapper">
        <div class="importmodal-container">
          <div class="importmodal-header pos-relative">
            <span class="headline3">{{ $t("addressbook.importtitle") }}</span>
            <button class="import-close" aria-label="close" @click="close()">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </div>
          <div class="importmodal-body">
            <p class="body-p">
              {{ $t("addressbook.importbody") }}
            </p>
          </div>
          <div class="importmodal-footer">
            <button
              class="button-medium-empty-b elipsis-text"
              style="max-width: 45vw"
              @click="downloadFile()"
            >
              <span class="sidebaricon">
                <svg
                  width="23"
                  height="17"
                  viewBox="0 0 23 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.1289 7.23047C19.2695 6.84375 19.375 6.45703 19.375 6C19.375 4.13672 17.8633 2.625 16 2.625C15.2969 2.625 14.6289 2.83594 14.1016 3.22266C13.1523 1.53516 11.3242 0.375 9.25 0.375C6.12109 0.375 3.625 2.90625 3.625 6C3.625 6.10547 3.625 6.21094 3.625 6.31641C1.65625 6.98438 0.25 8.88281 0.25 11.0625C0.25 13.875 2.5 16.125 5.3125 16.125H18.25C20.7109 16.125 22.75 14.1211 22.75 11.625C22.75 9.48047 21.2031 7.65234 19.1289 7.23047ZM14.207 10.5L10.9727 13.7695C10.6211 14.0859 10.0938 14.0859 9.77734 13.7695L6.50781 10.5C6.19141 10.1836 6.19141 9.62109 6.50781 9.30469L6.89453 8.91797C7.21094 8.60156 7.77344 8.60156 8.08984 8.95312L9.25 10.1484V5.71875C9.25 5.26172 9.60156 4.875 10.0938 4.875H10.6562C11.1133 4.875 11.5 5.26172 11.5 5.71875V10.1484L12.625 8.95312C12.9414 8.60156 13.5039 8.60156 13.8555 8.91797L14.207 9.30469C14.5586 9.62109 14.5586 10.1836 14.207 10.5Z"
                    fill="#66A5AD"
                  />
                </svg>
              </span>
              {{ $t("addressbook.downloadtemplate") }}
            </button>
            <button
              class="button-medium float-right elipsis-text"
              @click="chooseFile()"
              style="max-width: 45vw"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5018 3.44461L14.5526 0.495703C14.2362 0.179297 13.8073 0 13.3611 0H6.18715C5.25586 0.00351563 4.5 0.759375 4.5 1.69066V9.28371H0.421875C0.188789 9.28371 0 9.4725 0 9.70559V10.5493C0 10.7824 0.188789 10.9712 0.421875 10.9712H10.125V12.6896C10.125 13.0662 10.581 13.2539 10.8464 12.9867L13.3696 10.4446C13.5436 10.2691 13.5436 9.98613 13.3696 9.8107L10.8464 7.26855C10.581 7.00137 10.125 7.1891 10.125 7.56562V9.28406H6.18715V1.69066H11.8111V5.34621C11.8111 5.81379 12.1873 6.18961 12.6548 6.18961H16.3125V16.3129H6.18715V12.6587H4.5V16.3129C4.5 17.2441 5.25586 18 6.18715 18H16.31C17.2417 18 18 17.2441 18 16.3129V4.63957C18 4.19344 17.8182 3.76102 17.5018 3.44461ZM13.4982 4.50246V1.82777L16.1733 4.50246H13.4982Z"
                  fill="#66A5AD"
                />
              </svg>
              {{ $t("addressbook.importfile") }}
            </button>
            <input
              ref="file"
              @change="fileUpload()"
              type="file"
              accept=".xlsx, .xls, .csv"
              hidden
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import notification from "@/notification.js";
import UserService from "@/services/user.service";
let XLSX = require("xlsx");

export default {
  props: {
    show: Boolean,
  },
  emits: ["closeImport"],
  data() {
    return {
      passage: 0,
      addressbook: [],
      error_seen: false,
    };
  },

  methods: {
    close() {
      this.$emit("closeImport");
    },
    downloadFile() {
      // const sample =
      //   'data:text/csv;charset=utf-8,"companyName","fullName","email","prefix","mobile","streetName","streetNumber","zip","city","note"\n,,,"+47",,,,,,';
      const sample = `${window.location.origin}/files/sample.zip`;
      window.open(sample, "_blank");
    },
    chooseFile() {
      const fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    async fileUpload() {
      let that = this;
      let file = this.$refs.file.files[0];
      let extension = file.name.split(".").pop().toLowerCase();
      let result = {};
      if (extension == "csv") {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          let csv = e.target.result;
          that.importAddress(csv);
        };
      } else {
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          let excel = e.target.result;
          excel = new Uint8Array(excel);
          let workbook = XLSX.read(excel, { type: "array" });
          workbook.SheetNames.forEach(function (sheetName) {
            let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
              header: 1,
            });
            if (roa.length) result[sheetName] = roa;
          });
          that.importExcelAddress(result);
        };
      }
    },
    isNotUndefined(val) {
      let res = false;
      if (
        val.fullName != undefined &&
        val.email != undefined &&
        val.prefix != undefined &&
        val.mobile != undefined &&
        val.streetName != undefined &&
        val.prefix != undefined &&
        val.streetNumber != undefined &&
        val.zip != undefined &&
        val.city != undefined
      ) {
        res = true;
      }
      return res;
    },
    checkPrefix(val) {
      if (val.startsWith("+")) {
        return val;
      }
      if (val.startsWith("00")) {
        return "+" + val.str.slice(2);
      }
      return "+" + val.str;
    },
    importAddress(value) {
      const headers = value.slice(0, value.indexOf("\n")).split(",");
      const rows = value.slice(value.indexOf("\n") + 1).split("\n");
      const arr = rows.map((row) => {
        const values = row.split(",");
        return headers.reduce((object, header, index) => {
          object[header.replace(/^"(.*)"$/, "$1")] = values[index];
          return object;
        }, {});
      });
      for (const element of arr) {
        const type = element.companyName == "" ? "individual" : "business";
        if (this.isNotUndefined(element) && element.prefix != "") {
          const prefix = this.checkPrefix(element.prefix);
          this.addressbook.push({
            accountType: type,
            companyName: element.companyName,
            fullName: element.fullName,
            email: element.email,
            prefix,
            mobile: element.mobile,
            streetName: element.streetName,
            streetNumber: element.streetNumber,
            zip: element.zip,
            city: element.city,
            note: element.note,
            belongTo: this.$store.state.userId,
          });
        } else {
          if (!this.error_seen) {
            notification.toast("error", this.$t("addressbook.import_file_error"));
            this.error_seen = true;
          }
          this.$refs.file.value = null;
          this.close();
        }
        this.passage++;
        if (this.passage == arr.length && !this.error_seen) {
          this.sendAddressBook();
        }
      }
    },
    importExcelAddress(data) {
      let sheet1 = Object.keys(data);
      let file = data[sheet1];
      let header = file[0];
      if (this.checkExcelHeader(header)) {
        for (let i = 0; i < file.length; i++) {
          if (i > 0 && file[i][3] != undefined) {
            let prefix = this.checkPrefix(file[i][3]);
            this.addressbook.push({
              accountType: file[i][0] == "" ? "individual" : "business",
              companyName: file[i][0],
              fullName: file[i][1],
              email: file[i][2],
              prefix: prefix,
              mobile: file[i][4].toString(),
              streetName: file[i][5],
              streetNumber: file[i][6],
              zip: file[i][7],
              city: file[i][8],
              note: file[i][9],
              belongTo: this.$store.state.userId,
            });
          }
        }
        this.sendAddressBook();
      }
    },
    checkExcelHeader(val) {
      return (
        val[0] == "companyName" &&
        val[1] == "fullName" &&
        val[2] == "email" &&
        val[3] == "prefix" &&
        val[4] == "mobile" &&
        val[5] == "streetName" &&
        val[6] == "streetNumber" &&
        val[7] == "zip" &&
        val[8] == "city" &&
        val[9] == "note"
      );
    },
    sendAddressBook() {
      UserService.postAddressbook(this.addressbook).then(
        () => {
          this.close();
          this.addressbook = [];
          notification.toast("success", this.$t("addressbook.import_file_success"));
        },
        (error) => {
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.$t("forgot_password.problem"));
        }
      );
    },
  },
};
</script>
<style>
.importmodal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.importmodal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.importmodal-container {
  width: 624px;
  min-height: 290px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 4px;
}
.importmodal-header {
  border-bottom: 1px #d6d6d6 solid;
  height: 90px;
  padding: 30px 40px;
}
.importmodal-footer {
  border-top: 1px #d6d6d6 solid;
  padding: 18px;
}
.importmodal-body {
  padding: 30px 40px;
}
.modal-default-button {
  float: right;
}
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.import-close {
  position: absolute !important;
  right: 30px;
  background: none;
  border: none;
  top: calc(50% - 6px);
}
</style>
