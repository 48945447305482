<template>
  <LoaderComponent v-if="loading" />
  <h1 v-if="!isMobile" class="title is-size-3">
    {{ $t("settings.tabs.business_info") }}
  </h1>
  <hr v-if="!isMobile" />

  <div class="field is-horizontal columns">
    <div :class="{ mobileCol2Settings: isMobile }" class="column">
      <div class="field">
        <label class="label">{{ $t("register.company_name") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.companyName"
            :placeholder="$t('register.placeholder_company_name')"
            :class="{ redBorder: v$.form.companyName.$error }"
            @blur="v$.form.companyName.$touch"
          />
        </div>
        <span v-if="v$.form.companyName.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="v$.form.companyName.$error"
            >{{ $t("required_field") }}
          </span>
        </p>
      </div>
      <div class="field">
        <label class="label">{{ $t("register.vatnr") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            ref="companyNumber"
            v-model="form.companyNumber"
            :placeholder="$t('register.vatnr')"
            :class="{
              redBorder: !this.validOrgNumber || v$.form.companyNumber.$error,
            }"
            @blur="checkOrganizationNumber"
          />
        </div>
        <span
          v-if="!this.validOrgNumber || v$.form.companyNumber.$error"
          class="field-icon"
        >
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="!this.validOrgNumber || v$.form.companyNumber.$error"
            >{{ $t("required_field") }}
          </span>
        </p>
      </div>
      <div class="field email">
        <label class="label emaillabel">{{
          $t("settings.business.invoice_email")
        }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.invoiceEmail"
            :placeholder="$t('settings.business.invoice_email_placeholder')"
            :class="{ redBorder: v$.form.invoiceEmail.$error }"
            @blur="v$.form.invoiceEmail.$touch"
          />
        </div>
        <span v-if="v$.form.invoiceEmail.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="v$.form.invoiceEmail.$error">{{
            $t("required_field")
          }}</span>
        </p>
      </div>
      <div class="field prefix">
        <label class="label" style="width: 200px">{{
          $t("settings.business.phone_number")
        }}</label>
        <div class="control dropdown-trigger">
          <input
            class="input"
            type="text"
            v-model="form.businessPrefix"
            :placeholder="$t('addressbook.searchinput')"
            @click="toggleDropdown()"
            readonly
          />
        </div>
        <div name="arrowBox" class="arrowBox">
          <img src="@/assets/icons/PNG/chevron-right.png" alt="right" />
        </div>
        <div role="menu" class="dropdown-show-options" v-if="showDropdown">
          <div
            class="dropdown-content prefix-content box-shadow-unset"
            v-for="(country, i) in countrycodes"
            :key="i"
          >
            <div
              class="dropdown-item columns mt-0 mb-0"
              @click="selectPrefix(country.dial_code)"
            >
              <div class="column">
                <span class="body-text-small text-black">{{
                  country.name
                }}</span>
                <span class="body-text-small text-gray ml-10px">{{
                  country.dial_code
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field phonenumber">
        <label class="label mobilelabel">&nbsp; </label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.businessPhone"
            :class="{
              redBorder: !this.validPhone,
            }"
            :placeholder="$t('settings.business.phone_placeholder')"
            @blur="checkPhone()"
          />
        </div>
        <span v-if="!this.validPhone" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="!this.validPhone">{{ $t("required_field") }}</span>
        </p>
      </div>
    </div>
    <div :class="{ mobileCol2Settings: isMobile }" class="column">
      <div class="field post_sted">
        <label class="label">{{ $t("addressbook.modal.street") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.streetName"
            :placeholder="$t('addressbook.modal.placeholder_streetname')"
            :class="{ redBorder: v$.form.streetName.$error }"
            @blur="v$.form.streetName.$touch"
          />
        </div>
        <span v-if="v$.form.streetName.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help"></p>
      </div>
      <div class="field post_number">
        <label class="label">{{ $t("addressbook.modal.number") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.streetNumber"
            :placeholder="$t('addressbook.modal.placeholder_number')"
            :class="{ redBorder: v$.form.streetNumber.$error }"
            @blur="v$.form.streetNumber.$touch"
          />
        </div>
        <span v-if="v$.form.streetNumber.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help"></p>
      </div>

      <div class="field post_area">
        <label class="label">{{ $t("addressbook.modal.area") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.city"
            :placeholder="$t('addressbook.modal.placeholder_area')"
            :class="{ redBorder: v$.form.city.$error }"
            @blur="v$.form.city.$touch"
          />
        </div>
        <span v-if="v$.form.city.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help"></p>
      </div>
      <div class="field post_zip">
        <label class="label">{{ $t("addressbook.modal.zip") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.zip"
            :placeholder="$t('addressbook.modal.placeholder_number')"
            :class="{ redBorder: v$.form.zip.$error }"
            @blur="v$.form.zip.$touch"
          />
        </div>
        <span v-if="v$.form.zip.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help"></p>
      </div>
      <div class="field">
        <label class="label">{{ $t("settings.business.country") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.country"
            :placeholder="$t('settings.business.country')"
          />
        </div>
        <p class="help"></p>
      </div>
    </div>
  </div>
  <hr />

  <div class="mb-6" :class="{ mobilePaddingBottom: isMobile }">
    <button
      type="submit"
      :disabled="this.v$.form.$invalid || !validOrgNumber"
      @click="save()"
      class="button-medium has-sherpa float-right"
    >
      {{ $t("settings.save") }}
      <span>
        <img
          src="@/assets/icons/PNG/check-circle-white.png"
          style="margin-top: -5px; vertical-align: middle; position: relative"
          @mouseover="show = true"
          @mouseleave="show = false"
          alt="info"
        />
      </span>
    </button>
  </div>
</template>
<script>
import countrycodes from "@/countrycodes.json";
import postalcodes from "@/postalcodes.json";
import notification from "@/notification.js";
import UserService from "@/services/user.service";
import LoaderComponent from "@/components/LoaderComponent.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "@vuelidate/validators";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
export default {
  components: { LoaderComponent },
  name: "SettingsProfile",
  props: ["isMobile", "active"],
  emits: ["refresh"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      countrycodes,
      isFocusDel: false,
      canSave: true,
      showDropdown: false,
      selectedPrefix: "",
      validPhone: true,
      verifiedPhone: false,
      validOrgNumber: true,
      loading: true,
      form: {
        companyName: "",
        companyNumber: "",
        invoiceEmail: "",
        businessPrefix: "+47",
        businessPhone: "",
        streetName: "",
        streetNumber: "",
        city: "",
        zip: "",
        country: "",
      },
    };
  },
  validations() {
    return {
      form: {
        companyName: {
          required,
        },
        companyNumber: {
          required,
        },
        invoiceEmail: {
          required,
          email,
        },
        streetName: {
          required,
        },
        streetNumber: {
          required,
          numeric,
        },
        city: {
          required,
        },
        zip: {
          required,
          numeric,
          min: minLength(4),
          max: maxLength(4),
        },
      },
    };
  },
  methods: {
    save() {
      let form = {
        companyName: this.form.companyName,
        companyNumber: this.form.companyNumber,
        invoiceEmail: this.form.invoiceEmail,
        businessPrefix: this.form.businessPrefix,
        businessPhone: this.form.businessPhone,
        streetName: this.form.streetName,
        streetNumber: this.form.streetNumber,
        city: this.form.city,
        zip: this.form.zip.toString(),
        country: this.form.country,
      };
      UserService.saveProfile(this.belongTo, form).then(
        () => {
          notification.toast("success", this.$t("settings.notification_saved"));
          this.$emit("refresh");
        },
        (error) => {
          this.content =
            error.response &&
            error.response.data &&
            error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.$t("settings.notification_error"));
        }
      );
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectPrefix(prefix) {
      this.selectedPrefix = prefix;
      this.form.businessPrefix = prefix;
      this.showDropdown = false;
    },
    async checkPhone() {
      const prefix = this.selectedPrefix
        ? this.selectedPrefix
        : this.form.businessPrefix;
      const fullnumber = prefix + this.form.businessPhone;
      const that = this;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      };
      if (this.form.businessPhone.length > 2) {
        this.axios
          .get(
            `https://authentication.${API_ENV}.${API_URL}/authentication/validate/phone/${fullnumber}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status_message === "Success") {
              that.validPhone = true;
              that.verifiedPhone = true;
            } else {
              that.validPhone = false;
              // that.v$.form.businessPhone.$touch();
              if (this.loading) {
                notification.toast("error", that.$t("register.wrong_phone"));
                that.loading = false;
              }
            }
          });
      }
    },
    getId() {
      this.belongTo = this.$store.state.userId;
    },
    getData() {
      UserService.getProfile(this.belongTo).then(
        (response) => {
          if (response.data.Company != null) {
            this.form.companyName = response.data.Company.companyName;
            this.form.companyNumber = response.data.Company.companyNumber;
            this.form.invoiceEmail = response.data.Company.invoiceEmail;
            this.form.businessPrefix = response.data.Company.prefix
              ? response.data.Company.prefix
              : "+47";
            this.form.businessPhone = response.data.Company.businessPhone;
            this.form.streetName = response.data.Company.streetName;
            this.form.streetNumber = response.data.Company.streetNumber;
            this.form.city = response.data.Company.city;
            this.form.zip = response.data.Company.zip;
            this.form.country = response.data.Company.country;
            this.loading = false;
          }
        },
        (error) => {
          this.content =
            error.response &&
            error.response.data &&
            error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.content);
          this.loading = false;
        }
      );
    },
    async checkOrganizationNumber() {
      let apiRes = {};
      if (this.form.companyNumber.length === 9) {
        try {
          apiRes = await this.axios.get(
            `https://data.brreg.no/enhetsregisteret/api/enheter/${this.form.companyNumber}`
          );
        } catch (err) {
          apiRes = err.response;
          this.validOrgNumber = false;
        } finally {
          if (apiRes.status === 200) {
            this.validOrgNumber = true;
          } else {
            this.validOrgNumber = false;
            notification.toast("error", this.$t("register.wrong_org_number"));
          }
        }
      }
    },
  },
  mounted() {
    this.getId();
    if (this.active) {
      this.getData();
    }
  },
  watch: {
    "addressbook.zip": function () {
      if (this.addressbook.zip.length == 4) {
        const match = postalcodes[this.addressbook.zip];
        if (match != null || match != undefined) {
          this.addressbook.city =
            match[0].toUpperCase() + match.slice(1).toLowerCase();
        }
      }
    },
    active() {
      if (this.active) {
        this.getData();
      } else {
        this.loading = true;
      }
    },
  },
};
</script>
<style scoped>
hr {
  background-color: #e7e1dc;
  border: none;
  height: 1px;
  width: 100%;
}
</style>
