<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="templatemodalS2-wrapper">
        <div class="templatemodalS2-container">
          <div class="templatemodalS2-header pos-relative">
            <span class="headline3">{{
              $t("new_delivery.step2.template_modal_title")
            }}</span>
            <button class="import-close" aria-label="close" @click="close()">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </div>
          <div class="templatemodalS2-body">
            <div class="body-p">
              <div class="control" v-if="template">
                <label
                  class="radio"
                  v-for="(item, i) in template"
                  :key="i"
                  style="margin: 12px 0 !important"
                >
                  <input
                    type="radio"
                    name="selected"
                    v-model="selected"
                    :value="i"
                    style="width: 10px !important; height: 10px !important"
                  />
                  L:{{ item.length }}cm W:{{ item.width }}cm H:{{
                    item.height
                  }}cm kg:{{ item.weight }} Q:{{ item.quantity }}
                </label>
              </div>
              <div v-else>
                {{ $t("new_delivery.step2.no_template") }}
              </div>
            </div>
          </div>
          <div class="templatemodalS2-footer">
            <button class="button-medium float-right" @click="loadTemplate()">
              <img
                src="@/assets/icons/PNG/plus-white.png"
                style=" height: 15px;
              margin-top: -2px; margin-right: 10px vertical-align: middle;
              position: relative; "
                @mouseover="showCancel = true"
                alt="Load
              template"
              />
              {{ $t("new_delivery.step2.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  props: {
    show: Boolean,
  },
  emits: ["close", "loadtemplate"],
  data() {
    return {
      template: [],
      selected: null,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },
    importTemplate() {
      this.template = JSON.parse(localStorage.getItem("template"));
    },
    loadTemplate() {
      this.$emit("loadtemplate", this.template[this.selected]);
    },
  },
  mounted() {
    this.importTemplate();
  },
  watch: {
    selected(newValue) {
      this.selected = newValue;
    },
  },
};
</script>
<style>
.templatemodalS2-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.templatemodalS2-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.templatemodalS2-container {
  width: 624px;
  min-height: 256px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 4px;
}
.templatemodalS2-header {
  border-bottom: 1px #d6d6d6 solid;
  height: 90px;
  padding: 30px 40px;
}
.templatemodalS2-footer {
  border-top: 1px #d6d6d6 solid;
  padding: 18px;
}
.templatemodalS2-body {
  padding: 30px 40px;
  max-height: 162px;
  overflow-x: auto;
}
.modal-default-button {
  float: right;
}
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.import-close {
  position: absolute !important;
  right: 30px;
  background: none;
  border: none;
  top: calc(50% - 6px);
}
</style>
