import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import ls from "localstorage-slim";
import { auth } from "./auth.module";

ls.config.encrypt = true;
export default createStore({
  state: {
    step1: [{}],
    step2: [{}],
    step3: [{}],
    step4: [{}],
    step5: [{}],
    saveSender: false,
    saveRecipient: false,
    newTaskId: "",
    token: "",
    userId: 0,
    role: "",
    accountType: "",
    addressbook: [],
    status: {
      loggedIn: false,
    },
    userData: {},
    becomeData: {},
  },
  getters: {
    getNewTask: (state) => state.newTask,

    token: (state) => state.token,
  },
  mutations: {
    addStep1(state, data) {
      state.step1 = data;
    },
    addStep2(state, data) {
      state.step2 = data;
    },
    addStep3(state, data) {
      state.step3 = data;
    },
    addStep4(state, data) {
      state.step4 = data;
    },
    addStep5(state, data) {
      state.step5 = data;
    },
    saveSender(state, data) {
      state.saveSender = data;
    },
    saveRecipient(state, data) {
      state.saveRecipient = data;
    },
    newTaskId(state, data) {
      state.newTaskId = data;
    },
    cleanAllSteps(state) {
      state.step1 = [{}];
      state.step2 = [{}];
      state.step3 = [{}];
      state.step4 = [{}];
    },
    addToken(state, data) {
      state.token = data;
      ls.set("token", data);
    },
    delToken(state) {
      state.token = "";
    },
    addAddressbook(state, data) {
      state.addressbook = data;
    },
    addUserId(state, data) {
      state.userId = data;
    },
    addRole(state, data) {
      state.role = data;
    },
    addAccountType(state, data) {
      state.accountType = data;
    },
    addUserData(state, data) {
      state.userData = data;
    },
    addBecomeData(state, data) {
      state.becomeData = data;
    },
    loginSuccess(state) {
      state.status.loggedIn = true;
      state.step1 = [{}];
      state.step2 = [{}];
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.token = "";
    },
    logout(state) {
      state.status.loggedIn = false;
      state.token = "";
      state.userId = "";
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  actions: {},
  modules: {
    auth,
  },
  plugins: [
    createPersistedState({
      // Define the states to store in persisted state
      paths: [
        "step1",
        "step2",
        "step3",
        "step4",
        "step5",
        "userId",
        "addressbook",
        "status",
        "userData",
        "role",
        "accountType",
        "becomeData",
      ],
    }),
  ],
});
