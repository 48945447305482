<template>
  <div></div>
</template>

<script>
export default {
  name: "gotoNewSingleDelivery",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$router.push("/dashboard/tasks/new/");
  },
  watch: {},
};
</script>
<style scoped></style>
