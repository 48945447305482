<template>
  <router-view> </router-view>
  <div class="devBanner" v-if="isDev">TESTING ENVIRONMENT</div>
</template>
<script>
import { inject } from "vue";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export default {
  name: "CompComp",
  setup() {
    inject("axios");
  },
  data() {
    return {
      logged: false,
      isDev: false,
    };
  },
  methods: {
    isDevEnv() {
      let env = process.env.VUE_APP_ENV;
      if (env === "dev") {
        this.isDev = true;
      }
    },
    getLocale() {
      let locale = ls.get("locale");
      this.$root.$i18n.locale = locale || "nb";
    },
    intercom() {
      if (this.$store.state?.userData?.User) {
        const dateStr = this.$store.state.userData?.User?.createdAt;
        const date = new Date(dateStr);
        //console.log(date); // 👉️ Wed Jun 22 2022
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.VUE_APP_INTERCOM_APPID,
          name:
            this.$store.state.userData.User.firstName + this.$store.state.userData.User.lastName, // Full name
          email: this.$store.state.userData.User.email, // Email address
          user_id: this.$store.state.userData.User.id, // User ID,
          phone: this.$store.state.userData.User.prefix + this.$store.state.userData.User.mobile,
          created_at: unixTimestamp, // Signup date as a Unix timestamp
        };
      } else {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.VUE_APP_INTERCOM_APPID,
        };
      }

      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/dlmq5k9r'
      (function () {
        let w = window;
        let ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          let d = document;
          let i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          let l = function () {
            let s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + process.env.VUE_APP_INTERCOM_APPID;
            let x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },
    l() {
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + process.env.VUE_APP_INTERCOM_APPID;
      let x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    },
  },
  mounted() {
    this.isDevEnv();
    this.intercom();
    this.getLocale();
  },
};
</script>
<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease-in-out;
  height: 100vh;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 100vh;
}
.devBanner {
  position: absolute;
  top: 0;
  background-color: red;
  color: white;
  /* width: 100%; */
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  padding: 0 20px;
  border-radius: 3px;
  z-index: 100;
}
</style>
