<template>
  <div class="single-address-result columns is-mobile is-vcentered">
    <div class="column is-1">
      <p class="body-text-small-title">ID</p>
    </div>
    <div class="column is-2">
      <p class="body-text-small-title">First Name</p>
    </div>
    <div class="column is-2">
      <p class="body-text-small-title">Last Name</p>
    </div>
    <div class="column is-4">
      <p class="body-text-small-title">email</p>
    </div>
    <div class="column is-3">
      <p class="body-text-small-title"></p>
    </div>
  </div>

  <div
    class="single-address-result columns is-mobile is-vcentered"
    v-for="(data, i) in tabledata"
    :key="i"
  >
    <div class="column is-1">
      <p class="body-text-small-title">
        {{ data.id }}
      </p>
    </div>
    <div class="column is-2">
      <p class="body-text-small-title">
        {{ data.firstName }}
      </p>
    </div>
    <div class="column is-2">
      <p class="body-text-small-title">
        {{ data.lastName }}
      </p>
    </div>
    <div class="column is-4">
      <p class="body-text-small-title">
        {{ data.email }}
      </p>
    </div>
    <div class="column is-3">
      <p class="body-text-small-title float-right">
        <button class="button button-medium" @click="becomeUser(data.id, data)">Become</button>
      </p>
    </div>
  </div>
</template>
<script>
import notification from "@/notification.js";

export default {
  name: "TableComponent",
  props: ["tabledata"],

  components: {},
  data() {
    return {};
  },
  methods: {
    becomeUser(id, data) {
      this.$store.commit("addUserId", id);
      this.$store.commit("addBecomeData", data);
      notification.toast("success", `Now you are user ID: ${id}`);
    },
  },
};
</script>
<style></style>
