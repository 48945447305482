<template>
  <div
    id="sidebar"
    class="column is-one-fifth-desktop sidebar-column is-hidden-1119 fixed"
  >
    <aside class="sidebar menu is-hidden-1119 fixed">
      <div class="top-menu">
        <div class="hideMobile">
          <h3 class="is-size-3 has-text-white logo">
            <img src="/fraktbestilling-logo.svg" alt="" style="width: 190px" />
          </h3>
        </div>
        <div>
          <button
            id="newTask"
            @click="newTask()"
            class="button is-outlined-blue is-medium is-cta has-margin-top-l text-white"
          >
            <span class="sidebaricon">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 32 32"
              >
                <path
                  fill="rgb(255, 214, 10)"
                  d="M18.667 13.333h13.333v5.333h-13.333v13.333h-5.333v-13.333h-13.333v-5.333h13.333v-13.333h5.333v13.333z"
                ></path>
              </svg>
            </span>
            <span>{{ $t("menu.single") }}</span>
          </button>
        </div>
      </div>
      <MenuContent />
      <div class="bottom-menu menu-list">
        <div class="hr"><hr /></div>
        <div>
          <ul class="menu-sublist">
            <li>
              <a href="/" class="side-menu-item" @click="logout()"
                ><span class="nimber-icon"
                  ><span class="icon small"><i class="fa fa-sign-out"></i></span
                ></span>
                <span>{{ $t("login.logout") }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </aside>
  </div>
  <nav
    class="navbar is-hidden-desktop"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-burger" data-target="navMenu" @click="showMenu()">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div class="navbar-menu fixed is-hidden-desktop" id="navMenu">
      <div class="top-menu">
        <div class="hideMobile">
          <h3 class="is-size-3 has-text-white logo">
            <span class="font-bold">fraktbestilling.</span
            ><span class="font-light">no </span>
          </h3>
          <span class="has-text-white font-medium font-13">by Nimber</span>
        </div>
        <div>
          <button
            id="newTaskM"
            @click="newTask()"
            class="button is-outlined-blue is-medium is-cta has-margin-top-l text-white"
          >
            <span class="sidebaricon">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 32 32"
              >
                <path
                  fill="rgb(255, 214, 10)"
                  d="M18.667 13.333h13.333v5.333h-13.333v13.333h-5.333v-13.333h-13.333v-5.333h13.333v-13.333h5.333v13.333z"
                ></path>
              </svg>
            </span>
            <span>{{ $t("menu.single") }}</span>
          </button>
        </div>
      </div>
      <MenuContent @click="showMenu()" />
      <div class="bottom-menu menu-list">
        <div class="hr"><hr /></div>
        <div>
          <ul class="menu-sublist">
            <li>
              <a href="/" class="side-menu-item" @click="logout()"
                ><span class="nimber-icon"
                  ><span class="icon small"><i class="fa fa-sign-out"></i></span
                ></span>
                <span>{{ $t("login.logout") }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import MenuContent from "@/components/MenuContent";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export default {
  name: "SideBar",
  props: {},
  components: {
    MenuContent,
  },
  data() {
    return {};
  },
  methods: {
    showMenu() {
      const burger = document.getElementsByClassName("navbar-burger");
      const menu = document.getElementsByClassName("navbar-menu");
      const navbar = document.getElementsByClassName("navbar");
      burger[0].classList.toggle("is-active");
      burger[0].classList.toggle("fixed");
      menu[0].classList.toggle("is-active");
      menu[0].classList.toggle("is-hidden-desktop");
      navbar[0].classList.toggle("is-hidden-desktop");
    },
    logout() {
      localStorage.removeItem("fraktbestillingAuth");
      ls.set("token", "");
      let welcome = ls.get("welcome");
      let locale = ls.get("locale");
      ls.clear();
      ls.set("welcome", welcome);
      ls.set("locale", locale);
      // this.$store.commit("logout");
      localStorage.removeItem("token");
      localStorage.removeItem("vuex");
    },
    newTask() {
      this.showMenu();
      this.$router.push("/dashboard/tasks/goToNew/");
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#newTask:focus-visible {
  outline: 1px solid white;
}
#newTaskM:focus-visible {
  outline: 1px solid white;
}
.active {
  color: white !important;
}
.router-link-active {
  color: white !important;
}
</style>
