import axios from "axios";
import ls from "localstorage-slim";
import store from "../store";
import authHeader from "./auth-header";

ls.config.encrypt = true;
const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
};
ls.config.encrypt = true;
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
class AuthService {
  login(form) {
    return axios
      .post(
        `https://authentication.${API_ENV}.${API_URL}/authentication/login`,
        {
          email: form.email,
          password: form.password,
        },
        { headers }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          ls.set("token", response.data.accessToken);
          store.commit("addUserId", response.data.userId);
          store.commit("addRole", response.data.role);
          store.commit("addAccountType", response.data.accountType);
        }
        return response;
      });
  }

  logout() {
    store.commit("logout");
  }

  checktoken() {
    return axios
      .get(`https://authentication.${API_ENV}.${API_URL}/authentication/authenticate`, {
        headers: authHeader(),
      })
      .then((response) => response)
      .catch(() => "error");
  }

  register(form) {
    return axios.post(
      `https://authentication.${API_ENV}.${API_URL}/authentication/register`,
      {
        companyName: form.companyName,
        organizationNumber: form.organizationNumber,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        prefix: form.prefix,
        mobile: form.mobile,
        password: form.password,
      },
      { headers }
    );
  }
}
export default new AuthService();
