<template>
  <LoaderComponent v-if="showLoader" @escape="showLoader = false" />
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered">
        <div class="column is-half-desktop is-half-tablet is-full-mobile p-0 max-590">
          <FraktBestillingLogo />
          <h1 class="title is-size-2 has-text-mosque mb-title" style="font-size: 44px !important">
            {{ $t("login.title") }}<span class="font-light">.no</span>
          </h1>
          <div class="auth-container">
            <div class="has-text-black" style="height: 51px">
              <p class="float-left body-text-title auth-body-title-login mt-12px">
                {{ $t("login.create_user") }}
              </p>

              <router-link to="/register" class="float-right">
                <button class="button-medium-empty-mint body-text-small-title">
                  <span class="sidebaricon">
                    <img
                      src="@/assets/icons/PNG/user.png"
                      style="margin-top: -2px; vertical-align: middle; position: relative"
                      @mouseover="show = true"
                      @mouseleave="show = false"
                      alt="info"
                    />
                  </span>
                  {{ $t("login.register") }}
                </button>
              </router-link>
            </div>
            <div>
              <p class="mt-2" style="font-size: 16px; color: black">
                For å legge inn en bestillinger på Fraktbestilling må du registrere en ny profil. Vi
                beklager ulempene dette måtte medføre. Vi har besluttet å ta den gamle versjonen av
                Fraktbestilling ut av drift grunnet en tekniskfeil.
              </p>
            </div>
            <form @submit.prevent>
              <div class="field email mt-large">
                <label class="label emaillabel">{{ $t("login.username") }}</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="form.email"
                    :placeholder="$t('login.placeholder_email')"
                    :class="{ redBorder: v$.form.email.$error }"
                    @blur="v$.form.email.$touch"
                  />
                </div>
                <span v-if="v$.form.email.$error" class="field-icon">
                  <i class="fas fa-exclamation-circle is-red"></i>
                </span>
                <p class="help">
                  <span v-if="v$.form.email.$error">{{ $t("required_field") }}</span>
                </p>
              </div>
              <div class="field">
                <label class="label">{{ $t("login.password") }}</label>
                <div class="control">
                  <input
                    id="password"
                    class="input"
                    type="password"
                    v-model="form.password"
                    :placeholder="$t('login.placeholder_password')"
                    :class="{ redBorder: v$.form.password.$error }"
                    @blur="v$.form.password.$touch"
                    v-on:keyup.enter="login"
                    autocomplete="false"
                  />
                </div>
                <span class="field-icon">
                  <i id="eye" class="fas fa-eye" @click="showHidePassword()"></i>
                </span>
                <p class="help">
                  <span v-if="v$.form.password.$error">{{ $t("required_field") }} </span>
                </p>
              </div>
              <div class="field extrakey">
                <label class="label">Extra key</label>
                <div class="control">
                  <input
                    id="key"
                    class="input"
                    type="text"
                    v-model="extrakey"
                    placeholder="Type a safe key"
                  />
                </div>
              </div>
              <div
                class="field mt-medium forgot-field forgot-field"
                style="margin-bottom: 52px !important"
              >
                <router-link to="/reset" class="no-underline">
                  <span class="has-text-weight-normal has-text-mosque ml-12px">
                    {{ $t("login.forgot_password") }}
                  </span></router-link
                >
              </div>
              <div class="field" style="border-top: 1px solid #d6d6d6; text-align: right">
                <button
                  class="button-medium mt-27px"
                  :class="{ 'is-loading': loading }"
                  @click="login()"
                  :disabled="this.v$.form.$invalid"
                >
                  <span class="sidebaricon">
                    <img
                      src="@/assets/icons/PNG/key-white.png"
                      style="margin-top: -2px; vertical-align: middle; position: relative"
                      @mouseover="show = true"
                      @mouseleave="show = false"
                      alt="info"
                    />
                  </span>
                  {{ $t("login.login") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FraktBestillingLogo from "@/components/FraktBestillingLogo.vue";
import notification from "@/notification.js";
import useVuelidate from "@vuelidate/core";
import UserService from "@/services/user.service";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { required, email, minLength } from "@vuelidate/validators";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export default {
  name: "LoginUser",
  components: { FraktBestillingLogo, LoaderComponent },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      extrakey: "",
      loading: false,
      message: "",
      showLoader: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          min: minLength(8),
        },
      },
    };
  },
  methods: {
    showHidePassword() {
      const x = document.getElementById("password");
      const eye = document.getElementById("eye");
      eye.classList.toggle("fa-eye");
      eye.classList.toggle("fa-eye-slash");
      x.type = x.type === "text" ? "password" : "text";
    },
    getLocale() {
      UserService.getProfile(this.$store.state.userId).then(
        (response) => {
          this.$store.commit("addUserData", response.data);
          let locale =
            response.data.Setting?.language != undefined || response.data.Setting?.language != null
              ? response.data.Setting.language
              : "nb";
          ls.set("locale", locale);
          this.$root.$i18n.locale = locale || "nb";
        },
        (error) => {
          console.error(error);
        }
      );
    },
    login() {
      this.loading = true;
      this.showLoader = true;

      // AUTH SERVICE VUEX
      if (this.extrakey.length < 1) {
        this.$store.dispatch("auth/login", this.form).then(
          (res) => {
            if (res.data.accessToken) {
              notification.toast("success", this.$t("login.toast_success"));
              ls.set("token", res.data.accessToken);
              this.getLocale();

              this.$router.push({ path: "/dashboard/deliveries" });
            } else {
              this.loading = false;
              this.showLoader = false;
              notification.toast("error", this.$t("login.toast_network_error"));
            }
          },
          (error) => {
            this.loading = false;
            this.showLoader = false;
            this.message = error.response && error.response.data && error.response.data.message;
            error.message || error.toString();
            console.error(this.message);
            console.error(error);
            if (this.message == "Network Error") {
              notification.toast("error", this.$t("login.toast_network_error"));
            } else if (this.message == "user account not active") {
              notification.toast("error", this.$t("login.toast_not_active"));
            } else {
              notification.toast("error", this.$t("login.toast_error"));
            }
          }
        );
      }
    },
    isLogged() {
      let token = ls.get("token");
      if (token && token.length > 1) {
        this.$router.push("/dashboard");
      }
    },
  },
  mounted() {
    this.isLogged();
  },
};
</script>
