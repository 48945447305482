<template>
  <div class="" style="margin-bottom: 7px">
    <div id="dashboard" class="pagecontent dash-nt">
      <div id="breadcrumb" style="font-size: 12px; margin-bottom: 16px">
        <span class="sherpa">{{ $t("new_delivery.sent.header_sent") }}</span>
      </div>
      <div>
        <h1 class="headline2 bottom64 has-text-centered">
          {{ $t("new_delivery.sent.title", [capitalize(rename(selectedProvider))]) }}
        </h1>
        <br />
      </div>
      <div class="column p-0 pos-relative">
        <ul style="list-style: disc; padding: revert">
          <li v-html="$t('new_delivery.sent.step1', [formatDate(selectedDate?.date)])"></li>
          <li>{{ $t("new_delivery.sent.step2") }}</li>
          <li>
            {{ $t("new_delivery.sent.step3") }}
            <router-link to="/dashboard/deliveries">
              {{ $t("new_delivery.sent.step3_link") }}
            </router-link>
          </li>
        </ul>
        <div
          class="has-text-centered mt-5 mb-5"
          style="border: 1px solid #e4e4e4; width: fit-content; margin: auto"
          v-if="gotData"
        >
          <!--<img
            src="@/assets/icons/PNG/demo-label.png"
            alt="demo-label"
            class="p-5"
          />-->

          <vue-pdf-embed :source="pdfBlob" />
        </div>
      </div>
    </div>
  </div>
  <div class="footer-st-container booking-footer">
    <button class="button button-medium float-right mr-5 mt-4" @click="openLabel()">
      {{ $t("new_delivery.sent.print") }}
      <img src="@/assets/icons/PNG/print-white.png" class="ml-2" alt="print" />
    </button>
  </div>
</template>
<script>
import dayjs from "dayjs";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "TaskBooked",
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      selectedProvider: "",
      label: "",
      content: {},
      taskId: "",
      service: "",
      pdfBlob: null,
      selectedDate: dayjs(),
      gotData: false,
    };
  },

  methods: {
    rename(value) {
      return value === "nimber" ? "logista" : value;
    },
    getData() {
      this.content = this.$store.state.step5;
      this.selectedDate = this.$store.state.step4;
      this.label = this.content.label;
      // console.log("content = " + this.content);
      // console.log("label = " + this.label);
      this.getPdf();
      // if (this.label.startsWith("https://")) {
      //   this.label = this.content.label
      // } else {
      //   this.label = "https://" + this.label;
      // }
      this.selectedProvider = this.$store.state.step3?.selected?.company;
      this.taskId = this.content?.taskId;
    },
    getPdf() {
      this.axios
        .get(this.label, {
          responseType: "blob",
        })
        .then((response) => {
          // console.log("Success", response);
          const blob = new Blob([response.data]);
          const objectUrl = URL.createObjectURL(blob);
          this.pdfBlob = objectUrl;
          this.gotData = true;
        });
    },
    openLabel() {
      window.open(this.label, "_blank");
    },
    formatDate(val) {
      // console.log(val);
      return dayjs(val).format("DD-MM-YYYY");
    },
    capitalize(s) {
      return s.length > 0 ? s[0].toUpperCase() + s.slice(1) : "Provider";
    },
  },
  mounted() {
    this.getData();
  },
  watch: {},
};
</script>
<style scoped></style>
