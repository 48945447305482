import axios from "axios";
import authHeader from "./auth-header";
import store from "../store";

const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
class UserService {
  getAddressbook() {
    const belongTo = store.state.userId;
    return axios.get(`https://addressbook.${API_ENV}.${API_URL}/addressbook/all/${belongTo}`, {
      headers: authHeader(),
    });
  }

  postAddressbook(form) {
    return axios.post(`https://addressbook.${API_ENV}.${API_URL}/addressbook/create`, form, {
      headers: authHeader(),
    });
  }

  updateAddressbook(form) {
    return axios.put(`https://addressbook.${API_ENV}.${API_URL}/addressbook/update`, form, {
      headers: authHeader(),
    });
  }
  pinAddressbook(entry) {
    return axios.put(`https://addressbook.${API_ENV}.${API_URL}/addressbook/update`, entry, {
      headers: authHeader(),
    });
  }

  delAddressbook(entry) {
    return axios.delete(`https://addressbook.${API_ENV}.${API_URL}/addressbook/delete/${entry}`, {
      headers: authHeader(),
    });
  }
  createTask(request) {
    return axios.post(`https://task.${API_ENV}.${API_URL}/task/create-task`, request, {
      headers: authHeader(),
    });
  }

  getPricings(taskId) {
    return axios.get(`https://task.${API_ENV}.${API_URL}/task/get-prices/${taskId}`, {
      headers: authHeader(),
    });
  }

  bookTask(request) {
    return axios.post(`https://task.${API_ENV}.${API_URL}/task/book/`, request, {
      headers: authHeader(),
    });
  }

  getTaskServices(userId, entry) {
    return axios.get(
      `https://task.${API_ENV}.${API_URL}/task/pricings/`,
      { headers: authHeader() },
      {
        data: {
          userId,
          id: entry.id,
        },
      }
    );
  }

  postBookTask(userId, entry) {
    return axios.get(
      `https://task.${API_ENV}.${API_URL}/task/booking/`,
      { headers: authHeader() },
      {
        data: {
          userId,
          id: entry.id,
        },
      }
    );
  }

  getAllTasks(data) {
    return axios.post(`https://task.${API_ENV}.${API_URL}/task/all-tasks/`, data, {
      headers: authHeader(),
    });
  }

  getProfile(id) {
    return axios.get(`https://authentication.${API_ENV}.${API_URL}/authentication/settings/${id}`, {
      headers: authHeader(),
    });
  }

  getPreferences(id) {
    return axios.get(
      `https://authentication.${API_ENV}.${API_URL}/authentication/preferences/${id}`,
      {
        headers: authHeader(),
      }
    );
  }

  saveProfile(id, userdata) {
    return axios.post(
      `https://authentication.${API_ENV}.${API_URL}/authentication/profile/${id}`,
      userdata,
      {
        headers: authHeader(),
      }
    );
  }

  savePreferences(id, userdata) {
    return axios.post(
      `https://authentication.${API_ENV}.${API_URL}/authentication/preferences/${id}`,
      userdata,
      {
        headers: authHeader(),
      }
    );
  }

  saveNotifications(id, userdata) {
    return axios.post(
      `https://authentication.${API_ENV}.${API_URL}/authentication/notifications/${id}`,
      userdata,
      {
        headers: authHeader(),
      }
    );
  }

  resetOldNew(userdata) {
    return axios.post(
      `https://authentication.${API_ENV}.${API_URL}/authentication/password/reset/`,
      userdata,
      {
        headers: authHeader(),
      }
    );
  }

  requestCancellation(userdata) {
    return axios.post(
      `https://cancellation.${API_ENV}.${API_URL}/cancellation/request-cancellation/`,
      userdata,
      {
        headers: authHeader(),
      }
    );
  }

  bringPickupPoints(userdata) {
    return axios.post(
      `https://bring.${API_ENV}.${API_URL}/bring/retrieve-pick-up-points/`,
      userdata,
      {
        headers: authHeader(),
      }
    );
  }

  getAllTos(id) {
    return axios.get(`https://sac.${API_ENV}.${API_URL}/sac/get/all/services/${id}`, {
      headers: authHeader(),
    });
  }

  checkSingleTos(data) {
    return axios.get(
      `https://sac.${API_ENV}.${API_URL}/sac/get/accepted/service/${data.userId}/${data.serviceName}`,
      {
        headers: authHeader(),
      }
    );
  }

  acceptSingleTos(data) {
    return axios.post(`https://sac.${API_ENV}.${API_URL}/sac/add/accepted/service`, data, {
      headers: authHeader(),
    });
  }

  saveAllTos(services, userId) {
    return axios.post(
      `https://sac.${API_ENV}.${API_URL}/sac/save/all/${userId}`,
      { services },
      {
        headers: authHeader(),
      }
    );
  }

  getAllUsers(value) {
    return axios.post(
      `https://authentication.${API_ENV}.${API_URL}/authentication/all-users`,
      value,
      {
        headers: authHeader(),
      }
    );
  }

  // updateAllTos(data) {
  //   return axios.get(`${API_URL}/sac/get/all/services/${id}`, {
  //     headers: authHeader(),
  //   });
  // }

  tracking(userdata) {
    return axios.post(`https://task.${API_ENV}.${API_URL}/task/track/`, userdata, {
      headers: authHeader(),
    });
  }

  savegdpr(data) {
    return axios.post(
      `https://authentication.${API_ENV}.${API_URL}/authentication/acceptGdpr/`,
      data,
      {
        headers: authHeader(),
      }
    );
  }
  getgdpr(userId) {
    return axios.get(
      `https://authentication.${API_ENV}.${API_URL}/authentication/getgdpr/${userId}`,
      {
        headers: authHeader(),
      }
    );
  }
}
export default new UserService();
