import ls from "localstorage-slim";

ls.config.encrypt = true;
export default function authHeader() {
  const token = ls.get("token");
  if (token && token.length > 0) {
    return {
      "x-access-token": token,
    };
  }
  return {};
}
