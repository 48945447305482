<template>
  <LoaderComponent v-if="validating" />
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered">
        <div
          class="column is-half-desktop is-half-tablet is-full-mobile p-0 max-590"
        >
          <FraktBestillingLogo />
          <h1
            class="title is-size-2 has-text-mosque mb-title"
            style="font-size: 44px !important"
          >
            {{ $t("login.title") }}<span class="font-light">.no</span>
          </h1>
          <div class="auth-container">
            <div class="has-text-black" style="height: 51px">
              <p
                v-if="!validating && validated"
                class="body-text-title auth-body-title-login mt-12px has-text-centered"
              >
                {{ $t("forgot_password.email_validated") }} <br />{{
                  $t("forgot_password.proceed_to")
                }}
                <router-link to="/register">
                  {{ $t("login.login") }}
                </router-link>
                in {{ seconds }} seconds
              </p>
              <p
                v-if="!validating && !validated"
                class="body-text-title auth-body-title-login mt-12px has-text-centered"
              >
                {{ $t("forgot_password.problem") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FraktBestillingLogo from "@/components/FraktBestillingLogo.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;

export default {
  name: "EmailValidation",
  components: { FraktBestillingLogo, LoaderComponent },
  data() {
    return {
      token: "",
      validating: true,
      validated: false,
      seconds: 5,
    };
  },
  methods: {
    validateMailToken() {
      if (this.token.length > 2) {
        this.axios
          .post(
            `https://authentication.${API_ENV}.${API_URL}/authentication/user/activate/`,
            { token: this.token }
          )
          .then(() => {
            this.validating = false;
            this.validated = true;
            this.$router.push("/login");
            //this.startTimer();
          })
          .catch((err) => {
            console.error(err);
            this.$router.push("/login");
          });
      }
    },
    startTimer() {
      const that = this;
      setInterval(() => {
        that.seconds--;
        if (that.seconds <= 0) that.$router.push("/login");
      }, 1000);
    },
  },
  mounted() {
    this.seconds = 5;
    this.token = this.$route.params.token;
    this.validateMailToken();
  },
};
</script>
