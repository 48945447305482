<template>
  <Transition name="modal">
    <div class="modal-mask" v-if="show">
      <div class="calmodal-wrapper">
        <div class="calmodal-container">
          <div class="calmodal-body">
            <DatePicker
              color="green"
              is-expanded
              v-model="date"
              :min-date="today"
              :disabled-dates="disabled"
              :key="change"
              v-show="ready"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import { DatePicker } from "v-calendar";
import dayjs from "dayjs";
import "v-calendar/dist/style.css";

export default {
  name: "StepFourCalendar",
  props: ["show", "company"],
  emits: ["close", "selectedDate"],
  components: { DatePicker },
  data() {
    return {
      date: dayjs().format("YYYY, MM, DD"),
      currentYear: "",
      today: "",
      ready: false,
      change: 0,
      disabled: [
        {
          weekdays: [1, 7],
        },
      ],
    };
  },
  methods: {
    setDisabled() {
      if (this.company === "nimber") {
        const logista = {
          start: dayjs("2023/05/19").format("YYYY, MM, DD"),
          end: dayjs("2023/05/19").format("YYYY, MM, DD"),
        };
        this.disabled.push(logista);
      }
      const easter = {
        start: dayjs(this.getEaster()).subtract(3, "day").format("YYYY, MM, DD"),
        end: dayjs(this.getEaster()).add(1, "day").format("YYYY, MM, DD"),
      };
      const xmas = {
        start: dayjs(`${this.currentYear}/12/24`).format("YYYY, MM, DD"),
        end: dayjs(`${this.currentYear}/12/26`).format("YYYY, MM, DD"),
      };
      const newyear = {
        start: dayjs(`${this.currentYear}/12/31`).format("YYYY, MM, DD"),
        end: dayjs(`${parseInt(this.currentYear) + 1}/01/01`).format("YYYY, MM, DD"),
      };
      const labourDay = {
        start: dayjs(`${this.currentYear}/05/01`).format("YYYY, MM, DD"),
        end: dayjs(`${this.currentYear}/05/01`).format("YYYY, MM, DD"),
      };
      const nationalHoliday = {
        start: dayjs(`${this.currentYear}/05/17`).format("YYYY, MM, DD"),
        end: dayjs(`${this.currentYear}/05/17`).format("YYYY, MM, DD"),
      };
      const weekends = {
        weekdays: [1, 7],
      };
      const pentecost = {
        start: dayjs(this.getEaster()).add(49, "day").format("YYYY, MM, DD"),
        end: dayjs(this.getEaster()).add(50, "day").format("YYYY, MM, DD"),
      };
      const ascension = {
        start: dayjs(this.getEaster()).add(39, "day").format("YYYY, MM, DD"),
        end: dayjs(this.getEaster()).add(39, "day").format("YYYY, MM, DD"),
      };
      this.disabled.push(
        easter,
        xmas,
        newyear,
        labourDay,
        nationalHoliday,
        weekends,
        pentecost,
        ascension
      );

      this.change++;
      this.ready = true;
    },
    getEaster() {
      const f = Math.floor;
      const G = this.currentYear % 19;
      const C = f(this.currentYear / 100);
      const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
      const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
      const J = (this.currentYear + f(this.currentYear / 4) + I + 2 - C + f(C / 4)) % 7;
      const L = I - J;
      const month = 3 + f((L + 40) / 44);
      const day = L + 28 - 31 * f(month / 4);
      return [this.currentYear, month, day];
    },
  },
  mounted() {
    this.currentYear = dayjs().year();
    this.today = dayjs().subtract(1, "day").format("YYYY, MM, DD");
    this.setDisabled();
  },
  watch: {
    date() {
      this.$emit("selectedDate", this.date);
      this.$emit("close");
    },
    company() {
      if (this.company === "nimber") {
        const logista = {
          start: dayjs("2023/05/19").format("YYYY, MM, DD"),
          end: dayjs("2023/05/19").format("YYYY, MM, DD"),
        };
        this.disabled.push(logista);
      } else {
        this.disabled = [
          {
            weekdays: [1, 7],
          },
        ];
        this.setDisabled();
      }
    },
  },
};
</script>
<style>
.calmodal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.calmodal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.calmodal-container {
  width: fit-content;
  min-height: 177px;
  margin: 0px auto;
  background-color: unset;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 4px;
  background-color: white;
}
.calmodal-header {
  border-bottom: 1px #d6d6d6 solid;
  height: 90px;
  padding: 30px 40px;
}
.calmodal-footer {
  border-top: 1px #d6d6d6 solid;
  padding: 18px;
}
.calmodal-body {
  padding: 0;
  overflow-x: auto;
  width: 368px;
  height: 360px;
}
.modal-default-button {
  float: right;
}
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.vc-pane-container {
  height: 358px;
}
.vc-pane-layout {
  height: 358px;
}
.vc-weeks {
  height: 320px;
}
.vc-highlight {
  background-color: #003b46 !important;
}
</style>
