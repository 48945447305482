<template>
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered">
        <div class="column">
          <FraktBestillingLogo />
          <div class="container is-fullhd">
            <h1 class="title is-size-2 has-text-centered">
              {{ $t("gdpr.title") }}
            </h1>

            <div class="columns is-mobile is-centered has-padding-top-m">
              <div class="column is-half-desktop">
                <div class="box">
                  <h2 class="subtitle is-size-5 is-spaced">
                    {{ $t("gdpr.subtitle") }}
                    <a target="_blank" href="https://www.nimber.com/terms#privacy-policy">
                      {{ $t("gdpr.privacy_link_text") }}
                    </a>
                  </h2>

                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field">
                        <label class="checkbox">
                          <input type="checkbox" class="sq-25 gdprInput" v-model="accept_terms" />
                          {{ $t("gdpr.accept") }}
                          <a target="_blank" href="https://www.nimber.com/terms">
                            {{ $t("gdpr.terms_link_text") }}
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                  <h2 class="title is-size-5 has-padding-top-m">
                    {{ $t("gdpr.newsletter.title") }}
                  </h2>
                  <p>{{ $t("gdpr.newsletter.subtitle1") }}</p>
                  <br />
                  <p>{{ $t("gdpr.newsletter.subtitle2") }}</p>
                  <br />
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field">
                        <label class="checkbox">
                          <input type="checkbox" class="sq-25 gdprInput" v-model="newsletter" />
                          {{ $t("gdpr.newsletter.accept") }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-body">
                      <div class="field">
                        <label class="checkbox">
                          <input type="checkbox" class="sq-25 gdprInput" v-model="noNewsletter" />
                          {{ $t("gdpr.newsletter.deny") }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="has-padding-top-m">
                    <br />
                    <br />
                    <button
                      type="submit"
                      :disabled="!accept_terms"
                      class="button is-primary is-large is-fullwidth"
                      @click="submitgdpr()"
                    >
                      {{ $t("gdpr.continue") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FraktBestillingLogo from "@/components/FraktBestillingLogo.vue";
import UserService from "@/services/user.service";
import notification from "@/notification.js";

export default {
  name: "GDPR",
  components: { FraktBestillingLogo },

  data() {
    return {
      accept_terms: false,
      newsletter: false,
      noNewsletter: true,
    };
  },

  methods: {
    submitgdpr() {
      let data = {
        userId: this.$store.state.userId,
        hasAcceptedGdpr: this.accept_terms,
        receivesNewsletter: this.newsletter,
      };

      UserService.savegdpr(data).then(
        () => {
          this.$router.push({ path: "/dashboard/deliveries" });
        },
        (error) => {
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.$t("settings.notification_error"));
        }
      );
    },
  },
  mounted() {
    if (this.$store.state.userId === null || this.$store.state.userId === undefined) {
      this.$router.push({ path: "/login" });
    }
  },
  watch: {
    newsletter() {
      if (this.newsletter) {
        this.noNewsletter = false;
      } else {
        this.noNewsletter = true;
      }
    },
    noNewsletter() {
      if (this.noNewsletter) {
        this.newsletter = false;
      } else {
        this.newsletter = true;
      }
    },
  },
};
</script>
