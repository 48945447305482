import { createRouter, createWebHashHistory } from "vue-router";
// Auth
import ls from "localstorage-slim";
import LoginUser from "../views/auth/Login.vue";
import RegisterUser from "../views/auth/Register.vue";
import ResetPassword from "../views/auth/Reset.vue";
import ResetToken from "../views/auth/ResetToken.vue";
import EmailValidation from "../views/auth/EmailValidation.vue";
// Default Views
import NotFound from "../views/NotFound.vue";
import TOS from "../views/TermsOfService.vue";
import GDRP from "../views/GDPR.vue";
import DefaultView from "../views/DefaultView.vue";
// Dashboard
import DashboardBase from "../views/dashboard/BaseTemplate.vue";
import NewSingleDelivery from "../views/dashboard/NewSingleDelivery.vue";
import gotoNewSingleDelivery from "../views/dashboard/gotoNewSingleDelivery.vue";
import TaskBooked from "../views/dashboard/TaskBooked.vue";
import WareHouse from "../views/dashboard/WareHouse.vue";
import OpenDeliveries from "../views/dashboard/OpenDeliveries.vue";
import AddressBook from "../views/dashboard/AddressBook.vue";
import OverView from "../views/dashboard/OverView.vue";
import UsersView from "../views/dashboard/Users.vue";
import SettingsView from "../views/dashboard/Settings.vue";
import store from "../store";
import dayjs from "dayjs";
ls.config.encrypt = true;

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/dashboard",
    component: DefaultView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: "/dashboard/deliveries",
    component: DashboardBase,
    meta: { requiresAuth: true },
    children: [
      {
        name: "OpenDeliveries",
        path: "/dashboard/deliveries",
        component: OpenDeliveries,
        meta: { requiresAuth: true },
      },
      {
        name: "NewSingleDelivery",
        path: "/dashboard/tasks/new/",
        component: NewSingleDelivery,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        name: "gotoNewSingleDelivery",
        path: "/dashboard/tasks/goToNew/",
        component: gotoNewSingleDelivery,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        name: "TaskBooked",
        path: "/dashboard/booked/",
        component: TaskBooked,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        name: "WareHouse",
        path: "/dashboard/warehouse",
        meta: { requiresAuth: true },
        component: WareHouse,
      },
      {
        name: "AddressBook",
        path: "/dashboard/addressbook",
        component: AddressBook,
        meta: { requiresAuth: true },
      },
      {
        name: "OverView",
        path: "/dashboard/overview",
        component: OverView,
        meta: { requiresAuth: true },
      },
      {
        name: "UsersView",
        path: "/dashboard/users",
        component: UsersView,
        meta: { requiresAuth: true },
      },
      {
        name: "SettingsView",
        path: "/dashboard/settings",
        component: SettingsView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginUser,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterUser,
  },
  {
    path: "/reset",
    name: "reset",
    component: ResetPassword,
  },
  {
    path: "/reset/:token",
    name: "resetToken",
    component: ResetToken,
  },
  {
    path: "/validation/:token",
    name: "emailValidation",
    component: EmailValidation,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/tos",
    name: "TersmOfService",
    component: TOS,
  },
  {
    path: "/gdpr",
    name: "GDRP",
    component: GDRP,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/DefaultView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    let token = ls.get("token");
    store.commit("addStep1", []);
    store.commit("addStep2", []);

    if (token && token != null) {
      store.dispatch("auth/checktoken").then(
        (res) => {
          let valid = dayjs().isBefore(dayjs.unix(res.data.exp));
          if (res.data.exp !== undefined && valid) next();
          else next({ path: "/login" });
        },
        (error) => {
          console.error(error);
          ls.set("token", "");
          let welcome = ls.get("welcome");
          ls.clear();
          ls.set("welcome", welcome);
          localStorage.removeItem("token");
          localStorage.removeItem("vuex");
          next({ path: "/login" });
        }
      );
    } else {
      next({ path: "/login" });
    }
  } else {
    next();
  }
});

export default router;
