<template>
  <LoaderComponent v-if="loading" @escape="loading = false" />
  <div class="nt-topbar">
    <div>
      <span class="body-text-title text-dark-gray"
        >{{ $t("new_delivery.step") }} {{ step }} {{ $t("new_delivery.of") }} 5</span
      >
      <div
        style="
          height: 3px;
          background-color: #f8f7f5;
          position: absolute;
          top: 33px;
          width: 80px;
          left: 150px;
        "
      >
        &nbsp;
      </div>
      <div
        style="height: 3px; background-color: #5cdb95; position: absolute; top: 33px; left: 150px"
        :style="{ width: `${step * 16}px` }"
      >
        &nbsp;
      </div>
    </div>
    <div>
      <span class="headline-thin">{{ $t(`new_delivery.step${step}.header`) }}</span>
    </div>
    <div class="nt-topbar-close">
      <router-link to="/dashboard/deliveries">
        <button
          style="
            background-color: #f8f7f5;
            width: 42px;
            height: 42px;
            border-radius: 20px;
            border: none;
          "
        >
          <img src="@/assets/icons/PNG/times-black.png" class="v-align-middle" alt="Close" />
        </button>
      </router-link>
    </div>
  </div>
  <div>
    <div class="" style="margin-bottom: 7px">
      <div id="dashboard" class="pagecontent dash-nt">
        <Transition name="component-fade" key="1" appear>
          <div v-show="step == 1">
            <StepOne @evaluated="checkvalid" :active="step == 1" />
          </div>
        </Transition>
        <Transition name="component-fade" key="2">
          <div v-show="step == 2" class="mlr-16">
            <StepTwo @evaluated="checkvalid" :active="step == 2" />
          </div>
        </Transition>
        <Transition name="component-fade" key="3">
          <div v-show="step == 3">
            <StepThree @evaluated="checkvalid" :active="step == 3" />
          </div>
        </Transition>
        <Transition name="component-fade" key="4">
          <div v-show="step == 4">
            <StepFour @evaluated="checkvalid" :active="step == 4" />
          </div>
        </Transition>
        <Transition name="component-fade" key="4">
          <div v-show="step == 5">
            <StepFive @evaluated="checkvalid" :active="step == 5" @gotostep="setStep" />
          </div>
        </Transition>
      </div>
    </div>

    <DialogWrapper />
    <StepFiveBookModal :show="showBookModal" @loading="calloader" @close="showBookModal = false" />
  </div>
  <footer class="columns is-mobile is-centered is-vcentered footer-st-container pagecontent">
    <div class="column is-2-desktop is-half-mobile pl-2">
      <button
        @click="setStep(step - 1)"
        class="button-medium w100"
        style="background-color: #f5f7f6 !important; color: #07575b !important"
        :disabled="step == 1"
      >
        <i class="fas fa-light fa-angle-left"></i>
        {{ $t("new_delivery.progressbar.back") }}
      </button>
    </div>
    <div class="column is-8-desktop is-hidden-mobile">
      <ProgressBar
        @emitStep="setStep"
        :valid1="valid1"
        :valid2="valid2"
        :valid3="valid3"
        :valid4="valid4"
        :step="step"
      />
    </div>
    <div class="column is-2-desktop is-half-mobile pl-0">
      <button
        @click="setStep(step + 1)"
        :disabled="!$data[`valid${step}`]"
        :class="{ 'button-disabled': !$data[`valid${step}`] }"
        class="button-medium w100"
      >
        {{ $t("new_delivery.progressbar.next") }}
        <i class="fas fa-light fa-angle-right"></i>
      </button>
    </div>
  </footer>
</template>

<script>
import StepOne from "@/components/newtask/StepOne.vue";
import StepTwo from "@/components/newtask/StepTwo.vue";
import StepThree from "@/components/newtask/StepThree.vue";
import StepFour from "@/components/newtask/StepFour.vue";
import StepFive from "@/components/newtask/StepFive.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import UserService from "@/services/user.service";
import notification from "@/notification.js";
import LoaderComponent from "@/components/LoaderComponent.vue";
import StepFiveBookModal from "@/components/newtask/StepFiveBookModal.vue";
import dayjs from "dayjs";
import { DialogWrapper } from "vue3-promise-dialog";
import { confirm } from "@/ts/dialogs.ts";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export default {
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    ProgressBar,
    DialogWrapper,
    StepFiveBookModal,
    LoaderComponent,
  },
  name: "NewSingleDelivery",
  props: {},
  data() {
    return {
      step: 1,
      valid1: false,
      valid2: false,
      valid3: false,
      valid4: false,
      valid5: false,
      now: "",
      content: "",
      res: [],
      pickupDateList: [],
      showModal: false,
      showLeave: false,
      showBookModal: false,
      leave: false,
      gotPrice: false,
      loading: false,
    };
  },
  methods: {
    calloader(val) {
      this.loading = val;
    },
    save() {
      this.$store.commit("addNewTask", { id: "2", name: "User 2" });
      // const res = this.$store.state.customers;
      // console.log(res);
    },
    setStep(value) {
      if (value == 1) {
        this.step = value;
        this.gotPrice = false;
      } else if (value == 2) {
        this.saveInAddressBook();
        this.step = value;
        this.gotPrice = false;
      } else if (value == 3) {
        if (this.gotPrice) {
          this.step = value;
        } else {
          this.getEstimate();
        }

        //once it got a response set this.step = 2
        // this.$store.commit("newTaskId", "");
      } else if (value == 4) {
        this.step = value;
      } else if (value == 5) {
        this.step = value;
      } else if (value == 6) {
        this.showBookModal = true;
      }
    },
    saveInAddressBook() {
      //get sender
      let addressbook = [];
      if (this.$store.state.saveSender) {
        let sender = this.$store.state.step1.sender;
        addressbook.push({
          accountType: sender.accountType,
          companyName: sender.companyName,
          fullName: sender.fullName,
          email: sender.email,
          prefix: sender.prefix,
          mobile: sender.mobile,
          streetName: sender.streetName,
          streetNumber: sender.streetNumber,
          zip: sender.zip,
          city: sender.city,
          note: sender.note,
          belongTo: this.$store.state.userId,
        });
      }

      if (this.$store.state.saveRecipient) {
        let recipient = this.$store.state.step1.recipient;
        addressbook.push({
          accountType: recipient.accountType,
          companyName: recipient.companyName,
          fullName: recipient.fullName,
          email: recipient.email,
          prefix: recipient.prefix,
          mobile: recipient.mobile,
          streetName: recipient.streetName,
          streetNumber: recipient.streetNumber,
          zip: recipient.zip,
          city: recipient.city,
          note: recipient.note,
          belongTo: this.$store.state.userId,
        });
      }
      UserService.postAddressbook(addressbook).then(
        () => {
          addressbook = [];
        },
        (error) => {
          console.error(error);
        }
      );
    },
    checkvalid(value) {
      if (value.step === 1) {
        this.valid1 = value.valid;
      } else if (value.step === 2) {
        this.valid2 = value.valid;
      } else if (value.step === 3) {
        this.valid3 = value.valid;
      } else if (value.step === 4) {
        this.valid4 = value.valid;
      } else if (value.step === 5) {
        this.valid5 = value.valid;
      }
    },
    firstTime() {
      const seen = ls.get("welcome");
      if (seen === null || seen === undefined) {
        this.showModal = true;
        ls.set("welcome", "true");
      }
    },
    async getEstimate() {
      this.loading = true;
      let minute = "";
      let hour = "";
      let year = dayjs(this.now).year();
      let month = dayjs(this.now).month() + 1;
      let day = dayjs(this.now).date();
      if (dayjs().hour() >= 12) {
        minute = "00";
        hour = "09";
      } else {
        minute = "00";
        hour = dayjs().add(3, "hour").hour();
      }
      let shippingDate = {
        day: day,
        hour: hour,
        minute: minute,
        month: month,
        year: year,
      };
      let request = [];
      let step1 = this.$store.state.step1;
      let step2 = this.$store.state.step2;
      let belongTo = this.$store.state.userId;

      //add dimensions for pricing services
      for (let item of step2.items) {
        item.dimensions = {
          length: item.length,
          height: item.height,
          width: item.width,
        };
      }
      request.push({
        userId: belongTo,
        shippingDate: shippingDate,
        step1: step1,
        step2: step2,
      });

      await UserService.createTask(request).then(
        (res) => {
          let taskId = res.data.taskId;
          localStorage.setItem("taskId", JSON.stringify(taskId));
          if (res.data.prices.length > 0) {
            localStorage.setItem("pricings", JSON.stringify(res.data.prices));
            setTimeout(() => {
              this.gotPrice = true;
              this.setStep(3);
              this.loading = false;
            }, 50);
          } else {
            notification.toast("error", this.$t("new_delivery.step3.no_service"));
            this.loading = false;
          }
        },
        (error) => {
          console.error(error);
          notification.toast("error", this.$t("login.toast_network_error"));
          this.loading = false;
        }
      );
    },
    pickupBefore12() {
      if (dayjs().hour() >= 12) {
        this.pickupDateList.shift();
        this.now = dayjs(this.pickupDateList[0]);

        localStorage.setItem("pickupDateList", JSON.stringify(this.pickupDateList));
      } else {
        this.now = dayjs(this.pickupDateList[0]);
      }
    },
    pickupDatesList() {
      for (let i = 0; i < 24; i++) {
        if (
          this.isWeekDay(dayjs().add(i, "day").format("YYYY/MM/DD")) &&
          this.isfixedHoliday(dayjs().add(i, "day").format("YYYY/MM/DD")) &&
          this.isVariabledHoliday(dayjs().add(i, "day").format("YYYY/MM/DD"))
        ) {
          this.pickupDateList.push(dayjs().add(i, "day").format("YYYY/MM/DD"));
        }
        localStorage.setItem("pickupDateList", JSON.stringify(this.pickupDateList));
      }
      this.pickupBefore12();
    },
    isWeekDay(val) {
      return dayjs(val).day() > 0 && dayjs(val).day() < 6;
    },
    isfixedHoliday(val) {
      let currentYear = dayjs().year();
      let xmas1 = dayjs(`${currentYear}/12/24`).format("YYYY/MM/DD");
      let xmas2 = dayjs(`${currentYear}/12/25`).format("YYYY/MM/DD");
      let xmas3 = dayjs(`${currentYear}/12/26`).format("YYYY/MM/DD");
      let last = dayjs(`${currentYear}/12/31`).format("YYYY/MM/DD");
      let newyear = dayjs(`${parseInt(currentYear) + 1}/01/01`).format("YYYY/MM/DD");
      let nationalHoliday = dayjs(`${currentYear}/05/17`).format("YYYY/MM/DD");
      let labourDay = dayjs(`${currentYear}/05/01`).format("YYYY/MM/DD");
      return (
        val != xmas1 &&
        val != xmas2 &&
        val != xmas3 &&
        val != last &&
        val != newyear &&
        val != nationalHoliday &&
        val != labourDay
      );
    },
    isVariabledHoliday(val) {
      let easter1 = dayjs(this.getEaster()).subtract(3, "day").format("YYYY/MM/DD");
      let easter2 = dayjs(this.getEaster()).subtract(2, "day").format("YYYY/MM/DD");
      let easter3 = dayjs(this.getEaster()).subtract(1, "day").format("YYYY/MM/DD");
      let easter4 = dayjs(this.getEaster()).format("YYYY/MM/DD");
      let easter5 = dayjs(this.getEaster()).add(1, "day").format("YYYY/MM/DD");
      let pentecost = dayjs(this.getEaster()).add(49, "day").format("YYYY/MM/DD");
      let ascension = dayjs(this.getEaster()).add(39, "day").format("YYYY/MM/DD");
      return (
        val != easter1 &&
        val != easter2 &&
        val != easter3 &&
        val != easter4 &&
        val != easter5 &&
        val != pentecost &&
        val != ascension
      );
    },
    getEaster() {
      let currentYear = dayjs().year();
      const f = Math.floor;
      const G = currentYear % 19;
      const C = f(currentYear / 100);
      const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
      const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
      const J = (currentYear + f(currentYear / 4) + I + 2 - C + f(C / 4)) % 7;
      const L = I - J;
      const month = 3 + f((L + 40) / 44);
      const day = L + 28 - 31 * f(month / 4);
      return `${currentYear}/${month}/${day}`;
    },
  },

  mounted() {
    this.pickupDatesList();
  },
  watch: {
    step(newVal, oldVal) {
      if (oldVal == 3 && newVal == 2) {
        this.gotPrice = false;
      }
    },
  },

  beforeMount() {
    const param = parseInt(this.$route.params.step);
    this.step = param || 1;
    this.$store.commit("newTaskId", "");
  },
  async beforeRouteLeave(_to, from, next) {
    if (this.showBookModal) {
      next();
    } else if (from.name == "NewSingleDelivery" || from.name == "NewSingleDeliveryStep") {
      const s1sender = this.$store.state.step1.sender;
      const s1recipient = this.$store.state.step1.recipient;
      const lets2title = this.$store.state.step2.title;
      if (s1sender || s1recipient || lets2title) {
        if (await confirm("Are you sure ?")) {
          this.$store.commit("addStep1", "[]");
          this.$store.commit("cleanAllSteps");

          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    }
  },
};
</script>
<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease-in-out;
  height: 100vh;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
  height: 100vh;
}
</style>
