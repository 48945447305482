<template>
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered ml-0">
        <div
          class="column is-half-desktop is-half-tablet is-full-mobile p-0 max-590"
        >
          <FraktBestillingLogo />
          <h1
            class="title is-size-2 has-text-mosque"
            style="font-size: 44px !important; margin-bottom: 67px !important"
          >
            {{ $t("login.title") }}<span class="font-light">.no</span>
          </h1>
          <div class="" style="padding: 0 64px" v-if="!sent">
            <div class="has-text-black">
              <p class="body-text-title">
                {{ $t("login.forgot_password") }}
              </p>
            </div>
            <div
              class="field email mt-large"
              style="
                margin-top: 44px !important;
                margin-bottom: 48px !important;
              "
            >
              <label class="label emaillabel">{{ $t("login.username") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="form.email"
                  :placeholder="$t('login.placeholder_email')"
                  :class="{ redBorder: v$.form.email.$error }"
                  @blur="v$.form.email.$touch"
                  @keyup.enter="reset()"
                />
              </div>
              <span v-if="v$.form.email.$error" class="field-icon">
                <i class="fas fa-exclamation-circle is-red"></i>
              </span>
              <p class="help">
                <span v-if="v$.form.email.$error">{{
                  $t("required_field")
                }}</span>
              </p>
            </div>
            <div class="field extrakey">
              <label class="label">Extra key</label>
              <div class="control">
                <input
                  id="key"
                  class="input"
                  type="text"
                  v-model="extrakey"
                  placeholder="Type a safe key"
                />
              </div>
            </div>
            <div class="field mt-medium" style="margin-bottom: 52px !important">
              {{ $t("forgot_password.go_back") }}
              <router-link to="/login">
                {{ $t("login.login").toLowerCase() }}
              </router-link>
            </div>
            <div
              class="field text-center"
              style="border-top: 1px solid #d6d6d6; text-align: right"
            >
              <button
                class="button-medium mt-27px"
                :class="{ 'is-loading': loading }"
                @click="reset()"
                :disabled="this.v$.form.$invalid"
              >
                <span class="sidebaricon">
                  <img
                    src="@/assets/icons/PNG/check-circle-white.png"
                    style="
                      margin-top: -5px;
                      vertical-align: middle;
                      position: relative;
                    "
                    @mouseover="show = true"
                    @mouseleave="show = false"
                    alt="info"
                  />
                </span>
                {{ $t("forgot_password.reset") }}
              </button>
            </div>
          </div>
          <div class="auth-container" v-if="sent">
            <div class="has-text-black">
              <p class="body-text-title">
                {{ $t("forgot_password.sent_title") }}
              </p>
            </div>
            <div class="field" style="margin-top: 44px !important">
              {{ $t("forgot_password.go_back") }}

              <router-link to="/login">
                {{ $t("login.login").toLowerCase() }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FraktBestillingLogo from "@/components/FraktBestillingLogo.vue";
import notification from "@/notification.js";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import ls from "localstorage-slim";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
ls.config.encrypt = true;
export default {
  name: "ResetPassword",
  components: { FraktBestillingLogo },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sent: false,
      form: {
        email: "",
      },
      loading: false,
      extrakey: "",
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
      },
    };
  },
  methods: {
    reset() {
      const that = this;
      if (!this.v$.form.$invalid && this.extrakey.length < 1) {
        this.loading = true;
        this.axios
          .post(
            `https://authentication.${API_ENV}.${API_URL}/authentication/forgot/password/email`,
            {
              email: this.form.email,
            }
          )
          .then(function () {
            notification.toast(
              "success",
              that.$t("forgot_password.toast_success")
            );
            that.sent = true;
          })
          .catch((error) => {
            if (error == "Error: Network Error") {
              notification.toast("error", that.$t("login.toast_network_error"));
            } else {
              notification.toast(
                "success",
                that.$t("forgot_password.toast_success")
              );
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.reset_notification {
  position: absolute;
  padding: 16px 22px 20px;
  width: 479px;
  height: 80px;
  right: 40px;
  bottom: 36px;
  background: #f5f7f6;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
</style>
