<template>
  <div class="mb-5 mlr-16" v-if="active">
    <h1 class="headline3 is-hidden-touch">
      {{ $t("new_delivery.header") }}
      <span class="headline-thin">{{ $t("new_delivery.step5.header") }}</span>
    </h1>
    <div class="mt-0 mb-0">
      <div class="columns is-vcentered is-multiline p-0 m-0">
        <div class="column is-5-desktop is-12-touch is-vcentered m-0 p-0 fromToBox">
          <div class="columns is-multiline m-0">
            <div class="column pl-0 is-12-desktop is-1-touch moptionhead body-text-title s5title">
              {{ $t("new_delivery.step5.from") }}
            </div>
            <div class="column is-12-desktop s5address-body">
              <div class="columns is-mobile p-0">
                <div class="column is-6-desktop font-16 elipsis-text" v-if="active">
                  <span class="font-13">{{ $t("deliveries.sender") }}</span>
                  <br />
                  <span
                    class="technical16-title"
                    :title="
                      step1?.sender?.accountType == 'business'
                        ? step1?.sender?.companyName
                        : step1?.sender?.fullName
                    "
                    >{{
                      step1?.sender?.accountType == "business"
                        ? step1?.sender?.companyName
                        : step1?.sender?.fullName
                    }}</span
                  >
                  <br />
                  <span :title="step1?.sender?.streetName + ' ' + step1?.sender?.streetNumber"
                    >{{ step1?.sender?.streetName }} {{ step1?.sender?.streetNumber }}</span
                  >
                  <br />
                  <span :title="step1?.sender?.zip + ' ' + step1?.sender?.city"
                    >{{ step1?.sender?.zip }} {{ step1?.sender?.city }}</span
                  >
                </div>
                <div class="column is-6-desktop font-16 elipsis-text">
                  <span class="font-13">{{ $t("addressbook.modal.contactperson") }} </span>
                  <br />
                  <span class="technical16-title" :title="step1?.sender?.fullName">{{
                    step1?.sender?.fullName
                  }}</span>
                  <br />
                  <span :title="step1?.sender?.mobile">{{ step1?.sender?.mobile }}</span>
                  <br />
                  <span :title="step1?.sender?.email">{{ step1?.sender?.email }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-1-desktop is-hidden-touch m-0 p-0"></div>
        <div class="column is-5-desktop is-12-touch is-vcentered m-0 fromToBox">
          <div class="columns is-multiline m-0">
            <div class="column pl-0 is-12-desktop is-1-touch moptionhead body-text-title s5title">
              {{ $t("new_delivery.step5.to") }}
            </div>

            <div class="column is-12-desktop s5address-body">
              <div class="columns is-mobile p-0">
                <div class="column is-6-desktop font-16 elipsis-text" v-if="active">
                  <span class="font-13">{{ $t("deliveries.recipient") }}</span>
                  <br />
                  <span
                    class="technical16-title"
                    :title="
                      step1?.recipient?.accountType == 'business'
                        ? step1?.recipient?.companyName
                        : step1?.recipient?.fullName
                    "
                    >{{
                      step1?.recipient?.accountType == "business"
                        ? step1?.recipient?.companyName
                        : step1?.recipient?.fullName
                    }}</span
                  >
                  <br />
                  <span :title="step1?.recipient?.streetName + ' ' + step1?.recipient?.streetNumber"
                    >{{ step1?.recipient?.streetName }} {{ step1?.recipient?.streetNumber }}</span
                  >
                  <br />
                  <span :title="step1?.recipient?.zip + ' ' + step1?.recipient?.city"
                    >{{ step1?.recipient?.zip }} {{ step1?.recipient?.city }}</span
                  >
                </div>
                <div class="column is-6-desktop font-16 elipsis-text">
                  <span class="font-13">{{ $t("addressbook.modal.contactperson") }} </span>
                  <br />
                  <span class="technical16-title" :title="step1?.recipient?.fullName">{{
                    step1?.recipient?.fullName
                  }}</span>
                  <br />
                  <span :title="step1?.recipient?.mobile">{{ step1?.recipient?.mobile }}</span>
                  <br />
                  <span :title="step1?.recipient?.email">{{ step1?.recipient?.email }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-1-desktop is-12-touch is-vcentered m-0 has-text-centered pt-0">
          <button
            class="button button-medium-empty-rounded is-hidden-touch"
            @click="goToStep(1)"
            style="padding: 0 !important"
          >
            <img src="@/assets/icons/PNG/edit-gray.png" style="width: 19px" alt="edit" />
          </button>
          <button
            class="float-right button button-medium-empty is-hidden-desktop mb-5"
            @click="goToStep(1)"
          >
            <img src="@/assets/icons/PNG/edit.png" alt="edit" />

            {{ $t("new_delivery.step5.edit_location") }}
          </button>
        </div>
      </div>
      <!-- package start -->
      <hr class="mt-0 is-hidden-touch" />
      <div class="columns is-vcentered is-multiline is-mobile p-0 m-0">
        <div class="column pl-0 is-12-desktop moptionhead body-text-title pt-0 pb-0">
          {{ $t("new_delivery.step2.packages") }}
        </div>
      </div>
      <div class="columns is-vcentered is-multiline is-mobile p-0 m-0">
        <div class="column ml-0 pl-0">
          <div class="s5package">
            <i class="pr-1 has-text-gray far fal fa-tally"></i>
            <span class="has-text-gray technical18 is-hidden-touch"
              >{{ $t("new_delivery.step2.total_count") }}&nbsp;</span
            >
            <span class="technical18-bold"
              >{{ totalItems() }}&nbsp;
              <span class="is-hidden-touch"> {{ $t("new_delivery.step2.items_count") }}</span></span
            >
          </div>
        </div>
        <div class="column">
          <div class="s5package">
            <i class="pr-1 has-text-gray far fa-regular fa-cube"></i>
            <span class="has-text-gray technical18 nowrap is-hidden-touch"
              >{{ $t("new_delivery.step2.total_volume") }} </span
            ><span class="technical18-bold">&nbsp;{{ totalVolume() }} m³</span>
          </div>
        </div>
        <div class="column mr-0 pr-0">
          <div class="s5package">
            <i class="pr-1 has-text-gray far fal fa-weight-hanging"></i>
            <span class="has-text-gray technical18 is-hidden-touch"
              >{{ $t("new_delivery.step2.total_weight") }} </span
            ><span class="technical18-bold">&nbsp;{{ totalWeight() }} kg</span>
          </div>
        </div>
        <div class="column is-1-desktop is-12-mobile is-vcentered m-0 pl-0 has-text-centered">
          <button class="button button-medium-empty-rounded is-hidden-touch" @click="goToStep(2)">
            <img src="@/assets/icons/PNG/edit-gray.png" alt="edit" />
          </button>
          <button
            class="float-right button button-medium-empty is-hidden-desktop mb-5"
            @click="goToStep(2)"
          >
            <img src="@/assets/icons/PNG/edit.png" alt="edit" />

            {{ $t("new_delivery.step5.edit_package") }}
          </button>
        </div>
      </div>
      <!-- package end -->
      <!-- service start -->
      <hr class="mt-0 is-hidden-touch" />
      <div class="columns is-vcentered is-multiline is-mobile p-0 m-0">
        <div class="column pl-0 is-12-desktop moptionhead body-text-title pt-0 pb-3">
          {{ $t("new_delivery.step5.service") }}
        </div>
      </div>
      <div class="columns is-vcentered is-multiline is-mobile p-0 m-0 is-hidden-touch">
        <div class="column pl-0 pr-0">
          <div class="p-0 m-0 selectedHeads5 columns is-vcentered" style="display: inline-flex">
            <div class="column is-1 p-0">
              <img
                :src="serviceLogo(selecteds3?.selected?.company)"
                alt="right"
                style="vertical-align: middle"
              />
            </div>
            <div class="column p-0">
              <span class="technical18-bold nowrap">
                {{
                  $t(
                    `new_delivery.step3.${selecteds3?.selected?.company}.${selecteds3?.selected?.service}.title`
                  )
                }} </span
              ><br />
              <span class="technical13">{{ rename(selecteds3?.selected?.company) }}</span>
            </div>
            <div class="column is-2 has-text-centered p-2">
              <div class="expected selectedExpected">
                {{
                  $t(
                    `new_delivery.step3.${selecteds3?.selected?.company}.${selecteds3?.selected?.service}.expected`
                  )
                }}
              </div>
            </div>
            <div class="column is-2 font-16 pl-0 pr-0">
              <span class="technical18-bold"
                >&nbsp;{{ parseFloat(selecteds3?.selected?.price).toFixed(0) }}</span
              >
              {{ $t("new_delivery.step3.eks_mva") }}
            </div>
          </div>
        </div>
        <div class="column is-1 is-vcentered m-0 pl-0 has-text-centered">
          <button class="button button-medium-empty-rounded" @click="goToStep(3)">
            <img src="@/assets/icons/PNG/edit-gray.png" alt="edit" />
          </button>
        </div>
      </div>
      <!-- mobile -->
      <div class="columns is-vcentered is-multiline is-mobile p-0 m-0 is-hidden-desktop">
        <div class="column is-12 pl-0 pr-0">
          <div class="p-0 m-0 selectedHeads5 mhu columns is-mobile is-multiline is-vcentered">
            <div class="column is-2 p-0">
              <img :src="serviceLogo(selecteds3?.selected?.company)" alt="right" />
            </div>
            <div class="column is-10 p-0">
              <span class="technical18-bold nowrap">
                {{
                  $t(
                    `new_delivery.step3.${selecteds3?.selected?.company}.${selecteds3?.selected?.service}.title`
                  )
                }} </span
              ><br />
              <span class="technical13">{{ selecteds3?.selected?.company }}</span>
            </div>

            <div class="column is-12 p-0">
              <span
                class="technical13"
                v-html="
                  $t(
                    `new_delivery.step3.${selecteds3?.selected?.company}.${selecteds3?.selected?.service}.description`
                  )
                "
              ></span>
            </div>

            <div class="column is-6 has-text-centered p-2">
              <div class="expected selectedExpected">
                {{
                  $t(
                    `new_delivery.step3.${selecteds3?.selected?.company}.${selecteds3?.selected?.service}.expected`
                  )
                }}
              </div>
            </div>
            <div class="column is-6 font-16 pl-0 pr-0 has-text-right">
              <span class="technical18-bold"
                >&nbsp;{{ parseFloat(selecteds3?.selected?.price).toFixed(0) }}</span
              >
              {{ $t("new_delivery.step3.eks_mva") }}
            </div>
          </div>
        </div>
        <div class="column is-1-desktop is-12-mobile is-vcentered m-0 pl-0 has-text-centered">
          <button
            class="float-right button button-medium-empty is-hidden-desktop mb-5"
            @click="goToStep(3)"
          >
            <img src="@/assets/icons/PNG/edit.png" alt="edit" />

            {{ $t("new_delivery.step5.edit_service") }}
          </button>
        </div>
      </div>
      <!-- mobile -->
      <!-- service end -->
      <!-- options start -->
      <hr class="mt-3 mb-0 is-hidden-touch" />
      <div class="columns is-vcentered is-multiline p-0 m-0">
        <div class="column fromToBox is-4 is-vcentered m-0 pl-0">
          <div class="columns is-multiline m-0">
            <div class="column pl-0 is-12-desktop moptionhead body-text-title">
              {{ $t("new_delivery.step4.pickup_date") }}
            </div>
            <div class="column is-12-desktop s5package" style="padding: 17px">
              <div class="columns p-0">
                <div class="column technical18-bold" v-if="active">
                  {{ selecteds4?.date }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column fromToBox is-7 is-vcentered m-0 pr-0">
          <div class="columns is-multiline m-0">
            <div class="column pl-0 is-12 moptionhead body-text-title">
              {{ $t("new_delivery.step5.options") }}
            </div>

            <div class="column p-0 is-12 selectedHeads5" v-show="selecteds3?.selectedExtra">
              <div class="columns is-mobile p-0 m-0 is-vcentered">
                <div class="column p-0 is-2">
                  <img :src="extraLogo(selecteds3?.selectedExtra)" alt="right" />
                </div>
                <div class="column is-6-desktop font-16" v-if="active && selecteds3?.selectedExtra">
                  <p style="margin-top: auto; margin-bottom: auto">
                    {{ $t(`new_delivery.step3.extra.${selecteds3?.selectedExtra}.title`) }}
                  </p>
                </div>
                <div class="column is-4-desktop font-16" v-if="active && selecteds3?.selectedExtra">
                  <span class="technical18-bold">&nbsp;{{ selecteds3?.extraPrice }}</span>
                  {{ $t("new_delivery.step3.eks_mva") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="column is-1 is-vcentered m-0 pl-0 has-text-centered is-hidden-touch"
          style="margin-top: 55px !important"
        >
          <button class="button button-medium-empty-rounded" @click="goToStep(4)">
            <img src="@/assets/icons/PNG/edit-gray.png" alt="edit" />
          </button>
        </div>
        <div class="column is-1 is-vcentered m-0 pl-0 has-text-centered is-hidden-desktop">
          <button
            class="float-right button button-medium-empty is-hidden-desktop mb-5"
            @click="goToStep(4)"
          >
            <img src="@/assets/icons/PNG/edit.png" alt="edit" />

            {{ $t("new_delivery.step5.edit_option") }}
          </button>
        </div>
      </div>

      <!-- options end -->
      <hr class="mt-0 mb-3 is-hidden-touch" />
      <div class="columns is-vcentered is-multiline is-mobile p-0 m-0">
        <div class="column is-half pl-0">
          <p style="font-size: 24px; line-height: 29px">
            {{ $t("new_delivery.step3.total") }}
          </p>
        </div>
        <div class="column is-half pr-0 has-text-right">
          <p class="font-16">
            <span class="technical18-bold">&nbsp;{{ totalPrice(selecteds3) }}</span>
            {{ $t("new_delivery.step3.eks_mva") }}
          </p>
        </div>
      </div>
      <hr class="mt-0 mb-0" />
    </div>
  </div>
</template>
<script>
import nimberLogo from "@/assets/images/deliveries/logista.png";
import postnordLogo from "@/assets/images/deliveries/postnord.png";
import bringLogo from "@/assets/images/deliveries/bring.png";
import emptyLogo from "@/assets/images/deliveries/empty.png";
import helperLogo from "@/assets/images/extra/helper.png";
import returnLogo from "@/assets/images/extra/return.png";
import disposalLogo from "@/assets/images/extra/disposal.png";
import installationLogo from "@/assets/images/extra/installation.png";

export default {
  name: "StepFive",
  props: ["active"],
  emits: ["evaluated", "gotostep"],
  data() {
    return {
      selecteds3: [],
      selecteds4: [],
      step1: [],
      step2: [],
      isComplete: true,
      logoImg: {
        nimber: nimberLogo,
        postnord: postnordLogo,
        bring: bringLogo,
      },
      extraLogoImg: {
        helper: helperLogo,
        disposal: disposalLogo,
        return: returnLogo,
        installation: installationLogo,
      },
    };
  },
  methods: {
    rename(value) {
      return value === "nimber" ? "logista" : value;
    },
    extraLogo(value) {
      return this.extraLogoImg[value] ? this.extraLogoImg[value] : emptyLogo;
    },
    serviceLogo(value) {
      return this.logoImg[value] ? this.logoImg[value] : emptyLogo;
    },
    getSteps() {
      this.selecteds3 = this.$store.state.step3;
      this.selecteds4 = this.$store.state.step4;
      this.step1 = this.$store.state.step1;
      this.step2 = this.$store.state.step2;
      this.basePrice = parseInt(this.selecteds3.selected.price);
      this.extraPrice = parseInt(this.selecteds3.extraPrice);
    },
    totalItems() {
      return this.step2.items.reduce((a, b) => parseFloat(a) + (parseFloat(b.quantity) || 0), 0);
    },
    totalWeight() {
      return this.step2.items.reduce(
        (a, b) => parseFloat(a) + (parseFloat(b.weight) * parseFloat(b.quantity) || 0),
        0
      );
    },
    totalVolume() {
      let totalVolume = 0;
      this.step2.items.forEach((item) => {
        totalVolume +=
          (parseInt(item.width) / 100) *
          (parseInt(item.height) / 100) *
          (parseInt(item.length) / 100) *
          parseInt(item.quantity);
      });
      // set to use max 2 decimals
      const countDecimals = function (value) {
        if (value % 1 != 0)
          if (value.toString().split(".")[1].length < 3) {
            return value.toString().split(".")[1].length;
          } else {
            return 2;
          }
        return 0;
      };
      return isNaN(totalVolume) ? 0 : totalVolume.toFixed(countDecimals(totalVolume));
    },
    totalPrice(val) {
      const base = parseFloat(val?.selected?.price).toFixed(0);
      const extra = parseFloat(val?.extraPrice).toFixed(0);
      return parseInt(base) + parseInt(extra);
    },
    goToStep(val) {
      this.$emit("gotostep", val);
    },
  },
  mounted() {
    this.$emit("evaluated", { step: 5, valid: true });
    if (this.active) {
      this.getSteps();
      setTimeout(() => {
        this.$emit("evaluated", { step: 5, valid: true });
      }, 150);
    }
  },
  watch: {
    active() {
      this.getSteps();
    },
  },
};
</script>
<style></style>
