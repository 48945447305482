<template>
  <div class="columns open-single-head is-vcentered is-hidden-mobile">
    <div class="column is-2 technical18 p-0">
      {{ $t("deliveries.description") }}
    </div>
    <div class="column is-2 technical18 p-0">{{ $t("deliveries.status") }}</div>
    <div class="column is-2 technical18 p-0">
      {{ $t("deliveries.created_at") }}
    </div>
    <div class="column is-3 p-0">
      <span class="technical18-bold">{{ $t("deliveries.sender") }}</span>
    </div>
    <div class="column is-3 p-0">
      <span class="technical18-bold">{{ $t("deliveries.recipient") }}</span>
    </div>
  </div>
  <div
    class="columns open-single is-vcentered"
    :class="{ 'is-mobile': isTablet }"
    v-for="(single, i) in paginated"
    :key="i"
    @click="showSingle(single.id)"
  >
    <div class="column is-2 technical18 p-0">
      <div class="column is-2 technical18 p-0 is-hidden-desktop delheadmob">
        <p>{{ $t("deliveries.description") }}</p>
      </div>
      <div class="deldesmob">
        {{ single.description }}
      </div>
    </div>
    <div class="column is-2 technical18 p-0">
      <div class="column is-2 technical18 p-0 is-hidden-desktop delheadmob">
        <p>{{ $t("deliveries.status") }}</p>
      </div>
      <div class="deldesmob">
        <button
          class="status-btn"
          :style="`background-color:${statusColor(single.status)};`"
        ></button>

        {{ $t(`deliveries.${single.status}`) }}
      </div>
    </div>
    <div class="column is-2 caption-text p-0">
      <div class="column is-2 technical18 p-0 is-hidden-desktop delheadmob">
        <p>{{ $t("deliveries.created_at") }}</p>
      </div>
      <div class="deldesmob">{{ formatDate(single.created_at) }}</div>
    </div>
    <div class="column is-3 p-0">
      <div class="column is-3 p-0 is-hidden-desktop delheadmob">
        <span class="technical18-bold delheadmob"
          ><p>{{ $t("deliveries.sender") }}</p></span
        >
      </div>
      <span class="technical18-bold deldesmob"> {{ single.sender.company }}</span>
      <br />
      <span class="caption-text ml-100px">{{ single.sender.area }}, {{ single.sender.city }}</span>
    </div>
    <div class="column is-3 p-0">
      <div class="column is-3 p-0 is-hidden-desktop delheadmob">
        <span class="technical18-bold delheadmob"
          ><p>{{ $t("deliveries.recipient") }}</p></span
        >
      </div>
      <span class="technical18-bold">{{ single.recipient.company }}</span>
      <br />
      <span class="caption-text ml-100px"
        >{{ single.recipient.area }}, {{ single.recipient.city }}</span
      >
    </div>
  </div>
  <div
    class="columns is-vcentered ms-0"
    style="margin-top: 50px !important; margin-bottom: 50px !important"
  >
    <div class="column is-6 p-0">
      <span>{{ results }}</span>
    </div>
    <div class="column is-6 p-0 cont-bottom-r">
      <Pagination
        :page_size="page_size"
        :page="paginate.currentPage"
        :pages="paginate.pages"
        @page="updatePage"
        :key="paginate.pages"
      />
    </div>
  </div>
  <DeliveriesModal :show="showModal" @close="closeSingle()" :taskId="selected" />
</template>
<script>
import Pagination from "@/components/PaginationComponent.vue";
import DeliveriesModal from "@/components/DeliveriesModal.vue";
import dayjs from "dayjs";

export default {
  name: "OpenComponent",
  components: { Pagination, DeliveriesModal },
  props: ["data", "changed", "paginate"],
  emits: ["updatecall"],
  data() {
    return {
      page_size: 20,
      page: 1,
      width: 0,
      showModal: false,
      selected: 0,
      results: "",
      isTablet: false,
    };
  },
  methods: {
    showSingle(single) {
      this.selected = single;
      this.showModal = !this.showModal;
    },
    closeSingle() {
      this.selected = 0;
      this.showModal = false;
      this.$emit("updatecall");
    },
    statusColor(val) {
      let color = "";
      switch (val) {
        case "in-transit":
          color = "#FFD60A";
          break;
        case "picked-up":
          color = "#FF9F0A";
          break;
        case "waiting-pickup":
          color = "#D6D6D6";
          break;
        case "delivered":
          color = "#5CDB95";
          break;
        case "cancelled":
          color = "#FF0033";
          break;
        default:
          break;
      }
      return color;
    },
    pagLength() {
      let that = this;
      // const notPage1 = this.page > 1 ? "1" : "";
      // let tot = 0;
      // tot = this.page * 10 > this.data.length ? this.data.length : this.page * 10;
      // // this.results = `${this.page - 1}${notPage1} to ${tot} from ${this.data.length} results`;
      let page = that.$t("deliveries.page");
      let oftext = that.$t("deliveries.oftext");
      let fromtext = that.$t("deliveries.fromtext");
      let resultstext = that.$t("deliveries.resultstext");
      this.results = `${page} ${that.paginate.currentPage} ${oftext} ${that.paginate.pages} ${fromtext} ${that.paginate.total} ${resultstext}`;
    },
    updatePage(value) {
      this.page = value;
      this.$emit("updatecall", this.page);
    },
    formatDate(value) {
      return dayjs(value).format("DD/MM/YYYY");
    },
    checkWidth() {
      this.width = window.innerWidth;
      this.isTablet = !!(this.width < 769 && this.width > 600);
    },
  },
  mounted() {
    this.checkWidth();
    setTimeout(() => {
      this.pagLength();
      this.checkWidth();
    }, 100);
    window.addEventListener("resize", this.checkWidth);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkWidth);
  },
  computed: {
    pages() {
      return Math.ceil(this.data.length / this.page_size);
    },
    indexStart() {
      return (this.page - 1) * this.page_size;
    },
    indexEnd() {
      return this.indexStart + this.page_size;
    },
    paginated() {
      return this.data;
    },
  },
  watch: {
    changed(oldVal, newVal) {
      if (oldVal != newVal) {
        this.page = 1;
        this.pagLength();
      }
    },
    paginate() {
      this.pagLength();
    },
  },
};
</script>
<style></style>
