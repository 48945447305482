<template>
  <div class="mb-5 mlr-16" v-if="ready">
    <h1 class="headline3 bottom64 is-hidden-touch">
      {{ $t("new_delivery.header") }}
      <span class="headline-thin">{{ $t("new_delivery.step3.header") }}</span>
    </h1>
    <div class="columns mt-0 mb-0">
      <div class="ml-3 column p-0 pos-relative">
        <h2 class="body-text-title step2label">
          {{ $t("new_delivery.step3.available_services") }}
          <p class="dis-inline ml-12px is-hidden-touch">
            <ToolTip
              vertical="top"
              horizontal="left"
              theme="light"
              :text="$t('new_delivery.step3.available_services_tooltip')"
            />
          </p>
        </h2>
        <div class="step3price">
          <span class="headline3 is-hidden-touch"
            ><span class="headline-thin" v-if="ready">{{ $t("new_delivery.step3.total") }} </span>
            {{ calculatePrice() }},-
          </span>
          <span
            class="is-hidden-desktop mr-3"
            style="
              font-size: 24px;
              line-height: 29px;

              color: #afafaf;
            "
            v-if="ready"
            :class="{ 'has-text-black': isComplete }"
            >{{ $t("new_delivery.step3.total") }}</span
          >
          <span class="technical13 float-right">
            <span
              v-if="ready"
              class="is-hidden-desktop"
              style="font-size: 24px; line-height: 29px; text-align: right; color: #afafaf"
              :class="{ 'has-text-black': isComplete }"
              >{{ calculatePrice() }},-</span
            >
            <span class="ml-2">{{ $t("new_delivery.step3.eks_mva") }}</span></span
          >
        </div>

        <div v-show="isComplete" class="selectedGreenS2">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
              fill="#5CDB95"
            />
          </svg>
        </div>
        <hr class="mt-2 mb-4 is-hidden-touch" />
      </div>
      <hr class="mt-0 mb-4 is-hidden-desktop" />
    </div>

    <div class="columns is-mobile is-vcentered m-0 technical13 singleColor is-hidden-touch">
      <div class="column is-4 p-0">
        {{ $t("new_delivery.step3.service_provider") }}
      </div>
      <div class="column is-3 p-0">
        {{ $t("new_delivery.step3.description") }}
      </div>
      <div class="column is-2 p-0">
        {{ $t("new_delivery.step3.expected_delivery") }}
      </div>
      <div class="column is-2 p-0 has-text-centered">
        {{ $t("new_delivery.step3.price") }}
      </div>
      <div class="column is-1 p-0"></div>
    </div>
    <hr class="mt-4 mb-1 is-hidden-touch" />
    <div v-if="ready">
      <div
        class="single-address-result columns is-mobile is-multiline is-flex is-vcentered mb-1"
        :class="{ selectedSingle: service.selectedField == i }"
        v-for="(single, i) in response"
        :key="i"
        @click="select(single, i)"
      >
        <div class="column is-1-desktop is-2-mobile p-0 is-flex is-vcentered ml-0">
          <img
            :src="serviceLogo(single.company)"
            alt="right"
            v-if="ready"
            style="max-width: 78px"
          />
        </div>
        <div class="column is-3-desktop is-8-mobile p-0">
          <span class="body-text-small-title">
            <p style="line-height: 120%">
              <span class="technical18-bold" v-if="ready">{{
                $t(`new_delivery.step3.${single.company}.${single.service}.title`)
              }}</span>
              <br />
              <span class="technical13">{{ rename(single.company) }}</span>
            </p>
          </span>
        </div>
        <div class="column is-hidden-desktop is-1-mobile p-0 has-text-right" v-if="ready">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
              :fill="service.selectedField == i ? '#5CDB95' : '#D6D6D6'"
            />
          </svg>
        </div>

        <div class="column is-3-desktop is-12-mobile p-0 mlm" v-if="ready">
          <p
            class="caption-text prod-desc-s3"
            v-if="ready"
            style="
              letter-spacing: 0;
              line-height: 120%;
              margin-right: 10px;
              padding-top: 10px;
              padding-bottom: 10px;
            "
            v-html="$t(`new_delivery.step3.${single.company}.${single.service}.description`)"
          ></p>
        </div>
        <div class="column is-2-desktop is-6-mobile p-0 pl-1" v-if="ready">
          <div
            class="expected"
            :class="{ selectedExpected: service.selectedField == i }"
            v-if="ready"
          >
            {{ $t(`new_delivery.step3.${single.company}.${single.service}.expected`) }}
          </div>
        </div>
        <div
          class="column is-2-desktop is-6-mobile p-0 trm has-text-right pr-5 nowrap"
          v-if="ready"
        >
          <span class="technical16-title mr-1" v-if="ready"
            >{{ parseFloat(single.price).toFixed(0) }}
          </span>
          <span class="technical13 font-light"> {{ $t("new_delivery.step3.eks_mva") }}</span>
        </div>
        <div class="column is-1 p-0 is-hidden-touch" v-if="ready">
          <svg
            v-if="ready"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
              :fill="service.selectedField == i ? '#5CDB95' : '#D6D6D6'"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <span class="extraTitle is-hidden-touch">{{ $t("new_delivery.step3.extra_services") }}</span>
    </div>
    <div class="columns mt-5 mb-0">
      <div class="column p-0 pos-relative">
        <h2 class="body-text-title step2label">
          {{ $t("new_delivery.step3.extra_services") }}
        </h2>
        <div class="step3price">
          <span
            class="is-hidden-desktop"
            style="font-size: 24px; line-height: 29px; color: #afafaf"
            :class="{
              'has-text-black': service.extraPrice != null && service.extraPrice > 0,
            }"
            >{{ $t("new_delivery.step3.total") }}</span
          >
          <span class="technical13 float-right">
            <span
              style="font-size: 24px; line-height: 29px; text-align: right; color: #afafaf"
              :class="{
                'has-text-black': service.extraPrice != null && service.extraPrice > 0,
              }"
              >{{ service.extraPrice != null ? service.extraPrice.toFixed(0) : 0 }},-</span
            >
            {{ $t("new_delivery.step3.eks_mva") }}</span
          >
        </div>

        <div v-show="service.extraPrice != null && service.extraPrice > 0" class="selectedGreenS2">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
              fill="#5CDB95"
            />
          </svg>
        </div>
        <hr class="mt-2 mb-4 is-hidden-touch" />
      </div>
      <hr class="mt-0 mb-4 is-hidden-dekstop" />
    </div>

    <div
      class="single-address-result columns is-mobile is-vcentered mb-1 not-available-extra"
      v-if="Object.keys(service.extra).length === 0"
    >
      <div
        class="column is-12 p-0 is-flex is-vcentered ml-0 extraContent technical16-title pl-2"
        style="max-height: fit-content; min-height: 0 !important"
      >
        {{ $t("new_delivery.step3.not_available") }}
      </div>
    </div>
    <div
      class="single-address-result columns is-mobile is-multiline is-vcentered mb-1"
      :class="{
        selectedSingle: service.selectedExtraVal != null && service.selectedExtraVal == j,
      }"
      v-else
      v-for="(single, j) in service.extra"
      :key="j"
      @click="selectExtra(single, j)"
    >
      <div class="column is-1-desktop is-2-mobile p-0 is-flex is-vcentered ml-0">
        <img :src="extraLogo(single)" alt="right" style="max-width: 76px" />
      </div>
      <div class="column is-3-desktop is-7-mobile p-0 is-flex is-vcentered ml-0 technical16-title">
        {{ $t(`new_delivery.step3.extra.${single}.title`) }}
      </div>
      <div class="column is-2-mobile p-0 is-hidden-desktop has-text-right">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
            :fill="service.selectedExtraVal == j ? '#5CDB95' : '#D6D6D6'"
          />
        </svg>
      </div>

      <div class="column is-5-desktop is-12-mobile p-0 mlm">
        <p
          class="caption-text elipsis-text prod-desc-s3"
          style="letter-spacing: 0; line-height: 120%; margin-right: 10px"
        >
          {{ $t(`new_delivery.step3.extra.${single}.description`) }}
        </p>
      </div>
      <div class="column is-2-desktop is-12-mobile mprice p-0">
        <span class="technical16-title mr-1">{{ extraprice[single] }}</span>
        <span class="is-hidden-desktop">{{ $t("new_delivery.step3.eks_mva") }}</span>
      </div>
      <div class="column is-1 p-0 is-hidden-touch">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
            :fill="service.selectedExtraVal == j ? '#5CDB95' : '#D6D6D6'"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import ToolTip from "@/components/ToolTip.vue";
import nimberLogo from "@/assets/images/deliveries/logista.png";
import postnordLogo from "@/assets/images/deliveries/postnord.png";
import bringLogo from "@/assets/images/deliveries/bring.png";
import emptyLogo from "@/assets/images/deliveries/empty.png";
import helperLogo from "@/assets/images/extra/helper.png";
import returnLogo from "@/assets/images/extra/return.png";
import disposalLogo from "@/assets/images/extra/disposal.png";
import installationLogo from "@/assets/images/extra/installation.png";

export default {
  name: "StepThree",
  components: { ToolTip },
  emits: ["evaluated"],
  props: ["active"],
  data() {
    return {
      ready: false,
      extraprice: {
        helper: 550,
        disposal: 550,
        return: 320,
        installation: 450,
      },
      service: {
        selected: [],
        selectedField: null,
        extra: [],
        selectedExtraVal: null,
        selectedExtra: "",
        extraPrice: null,
      },
      isComplete: false,
      response: [],
      logoImg: {
        nimber: nimberLogo,
        postnord: postnordLogo,
        bring: bringLogo,
      },
      extraLogoImg: {
        helper: helperLogo,
        disposal: disposalLogo,
        return: returnLogo,
        installation: installationLogo,
      },
    };
  },
  methods: {
    rename(value) {
      return value === "nimber" ? "logista" : value;
    },
    serviceLogo(value) {
      return this.logoImg[value] ? this.logoImg[value] : emptyLogo;
    },

    extraLogo(value) {
      return this.extraLogoImg[value] ? this.extraLogoImg[value] : emptyLogo;
    },
    select(val, i) {
      this.service.extra = [];
      this.isComplete = false;
      this.service.selectedExtraVal = null;
      this.service.selectedExtra = "";
      this.service.extraPrice = 0;
      this.service.selected = this.service.selectedField == i ? [] : val;
      this.service.selectedField = this.service.selectedField == i ? null : i;
      this.service.extra = val.extra ? val.extra : [];
    },
    selectExtra(val, i) {
      if (i === this.service.selectedExtraVal) {
        this.service.selectedExtraVal = null;
        this.service.extraPrice = 0;
        this.service.selectedExtra = "";
      } else {
        this.service.extraPrice = this.extraprice[val];
        this.service.selectedExtra = val;
        this.service.selectedExtraVal = parseInt(i);
      }
    },
    calculatePrice() {
      let value = 0;
      if (this.service.selected.price) {
        value = parseFloat(this.service.selected.price) + parseFloat(this.service.extraPrice);
      }
      return value;
    },
    getPricings() {
      let local = localStorage.getItem("pricings");
      let parsed = JSON.parse(local);
      this.response = parsed;
      this.ready = true;
    },
  },
  mounted() {},
  watch: {
    "service.selected": function () {
      if (
        this.service.selected &&
        this.service.selected.company === "nimber" &&
        this.service.selected.service === "furniture_delivery"
      ) {
        this.service.extra = ["helper", "return", "disposal", "installation"];
      } else {
        this.service.extra = [];
      }
      this.calculatePrice();
      if ("price" in this.service.selected) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
    },
    isComplete() {
      if (this.isComplete) {
        this.$emit("evaluated", { step: 3, valid: true });
        this.$store.commit("addStep3", this.service);
      } else {
        this.$emit("evaluated", { step: 3, valid: false });
        this.$store.commit("addStep3", []);
      }
    },
    "service.selectedExtra": function () {
      this.$store.commit("addStep3", this.service);
    },
    active() {
      if (this.active) {
        this.getPricings();
      }
    },
  },
};
</script>
<style>
.selectedSingle {
  border: 1px solid #5cdb95 !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  color: black !important;
  border-radius: 3px;
}
.singleColor {
  color: #6e6e6e;
}
.expected {
  padding: 4px 8px;
  background: #f8f7f5;
  border-radius: 2px;
  font-size: 15px;
  line-height: 120%;
  align-items: center;
  color: #6e6e6e;
  width: fit-content;
}
.selectedExpected {
  color: white !important;
  background: #5cdb95 !important;
}
.extraTitle {
  color: #6e6e6e;
  font-size: 15px;
}
.extraContent {
  color: #afafaf;
}
</style>
