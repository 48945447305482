<template>
  <LoaderComponent v-if="loading" />
  <h1 class="title is-size-3">
    {{ $t("settings.tabs.personal_info") }}
  </h1>
  <hr />

  <div class="field is-horizontal columns">
    <div class="column pl-0 mobileCol2Settings">
      <div class="field">
        <label class="label">{{ $t("register.first_name") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.firstName"
            :placeholder="$t('register.placeholder_first_name')"
            :class="{ redBorder: v$.form.firstName.$error }"
            @blur="v$.form.firstName.$touch && changed(true)"
          />
        </div>
        <span v-if="v$.form.firstName.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="v$.form.firstName.$error">{{ $t("required_field") }} </span>
        </p>
      </div>
      <div class="field">
        <label class="label">{{ $t("register.last_name") }}</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.lastName"
            :placeholder="$t('register.placeholder_last_name')"
            :class="{ redBorder: v$.form.lastName.$error }"
            @blur="v$.form.lastName.$touch && changed(true)"
          />
        </div>
        <span v-if="v$.form.lastName.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="v$.form.lastName.$error">{{ $t("required_field") }} </span>
        </p>
      </div>
    </div>
    <div :class="{ 'pr-0': !isMobile }" class="column mobileCol2Settings">
      <div class="field prefix">
        <label class="label" style="width: 200px">{{ $t("register.personal_phone") }}</label>
        <div class="control dropdown-trigger">
          <input
            class="input"
            type="text"
            v-model="form.prefix"
            :placeholder="$t('addressbook.searchinput')"
            @click="toggleDropdown()"
            readonly
          />
        </div>
        <div name="arrowBox" class="arrowBox">
          <img src="@/assets/icons/PNG/chevron-right.png" alt="right" />
        </div>
        <div
          role="menu"
          style="
            position: absolute;
            width: calc(303%);
            background: rgb(248, 247, 245);
            z-index: 1;
            max-height: 300px;
            overflow-y: scroll;
          "
          v-if="showDropdown"
        >
          <div
            class="dropdown-content prefix-content box-shadow-unset"
            v-for="(country, i) in countrycodes"
            :key="i"
          >
            <div class="dropdown-item columns mt-0 mb-0" @click="selectPrefix(country.dial_code)">
              <div class="column">
                <span class="body-text-small text-black">{{ country.name }}</span>
                <span class="body-text-small text-gray ml-10px">{{ country.dial_code }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field phonenumber">
        <label class="label mobilelabel">&nbsp; </label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.mobile"
            :class="{
              redBorder: !this.validPhone || v$.form.mobile.$error,
            }"
            :placeholder="$t('register.placeholder_personal_phone')"
            @blur="checkPhone() && changed(true)"
          />
        </div>
        <span v-if="!this.validPhone || v$.form.mobile.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="!this.validPhone || v$.form.mobile.$error">{{ $t("required_field") }}</span>
        </p>
      </div>
      <div class="field email">
        <label class="label emaillabel"
          >{{ $t("register.personal_email") }}
          <p class="dis-inline">
            <ToolTip
              vertical="top"
              horizontal="left"
              theme="light"
              text="here goes the assistive text"
            />
          </p>
        </label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="form.email"
            :placeholder="$t('register.placeholder_personal_email')"
            :class="{ redBorder: v$.form.email.$error }"
            @blur="v$.form.email.$touch"
            style="
              background-color: #ffffff !important;
              border: 1px solid hsl(0deg, 0%, 48%) !important;
            "
            disabled
          />
        </div>
        <span v-if="v$.form.email.$error" class="field-icon">
          <i class="fas fa-exclamation-circle is-red"></i>
        </span>
        <p class="help">
          <span v-if="v$.form.email.$error">{{ $t("required_field") }}</span>
        </p>
      </div>
    </div>
  </div>
  <hr />

  <div class="mb-6">
    <button
      type="submit"
      :disabled="blockSubmit()"
      @click="save()"
      class="button-medium has-sherpa float-right"
    >
      {{ $t("settings.save") }}
      <span>
        <img
          src="@/assets/icons/PNG/check-circle-white.png"
          style="margin-top: -5px; vertical-align: middle; position: relative"
          @mouseover="show = true"
          @mouseleave="show = false"
          alt="info"
        />
      </span>
    </button>
  </div>
  <h1 class="title is-size-3" style="padding-top: 32px">
    {{ $t("settings.tabs.account_info") }}
  </h1>
  <hr />
  <div class="field is-horizontal columns">
    <div class="column pl-0 mobileCol2Settings">
      <div class="field">
        <label class="label">{{ $t("settings.account.old_password") }}</label>
        <div class="control">
          <input
            class="input"
            type="password"
            id="oldPassword"
            v-model="updatePasswordForm.oldPassword"
            :placeholder="$t('settings.account.old_password')"
            :class="{ redBorder: v$.updatePasswordForm.oldPassword.$error }"
            @blur="v$.updatePasswordForm.oldPassword.$touch"
          />
        </div>
        <span class="field-icon">
          <i id="eye-oldPassword" class="fas fa-eye" @click="showHidePassword('oldPassword')"></i>
        </span>
        <p class="help">
          <span v-if="v$.updatePasswordForm.oldPassword.$error">{{ $t("required_field") }} </span>
        </p>
      </div>
      <div class="field">
        <label class="label"
          >{{ $t("settings.account.new_password") }}
          <p class="dis-inline">
            <ToolTip
              vertical="top"
              horizontal="left"
              theme="light"
              :text="$t('settings.account.new_password_tooltip')"
            />
          </p>
        </label>
        <div class="control">
          <input
            class="input"
            id="newPassword"
            type="password"
            v-model="updatePasswordForm.newPassword"
            :placeholder="$t('settings.account.new_password_placeholder')"
            :class="{ redBorder: v$.updatePasswordForm.newPassword.$error }"
            @blur="v$.updatePasswordForm.newPassword.$touch"
          />
        </div>
        <span class="field-icon">
          <i id="eye-newPassword" class="fas fa-eye" @click="showHidePassword('newPassword')"></i>
        </span>
        <p class="help">
          <span v-if="v$.updatePasswordForm.newPassword.$error">{{ $t("required_field") }} </span>
        </p>
      </div>
    </div>
    <div :class="{ 'pl-0': isMobile }" class="column pr-0">
      <div class="field" v-if="!isMobile" style="visibility: hidden">
        <label class="label"
          >{{ $t("settings.account.new_password") }}
          &nbsp;
        </label>
        <div class="control">
          <input class="input" type="text" />
        </div>
        <span class="field-icon">
          <i id="eye-newPassword" class="fas fa-eye" @click="showHidePassword('newPassword')"></i>
        </span>
        <p class="help">
          <span>&nbsp; </span>
        </p>
      </div>
      <div class="field">
        <label class="label">{{ $t("settings.account.repeat_new_password") }} </label>
        <div class="control">
          <input
            class="input"
            type="password"
            id="repeatPassword"
            v-model="updatePasswordForm.repeatPassword"
            :placeholder="$t('settings.account.repeat_new_password_placeholder')"
            :class="{ redBorder: v$.updatePasswordForm.repeatPassword.$error }"
            @blur="v$.updatePasswordForm.repeatPassword.$touch"
          />
        </div>
        <span class="field-icon">
          <i
            id="eye-repeatPassword"
            class="fas fa-eye"
            @click="showHidePassword('repeatPassword')"
          ></i>
        </span>
        <p class="help">
          <span v-if="v$.updatePasswordForm.repeatPassword.$error"
            >{{ $t("required_field") }}
          </span>
        </p>
      </div>
    </div>
  </div>
  <hr />

  <div :class="{ mobilePaddingBottom: isMobile }" class="mb-6">
    <button
      type="submit"
      :disabled="this.v$.updatePasswordForm.$invalid"
      @click="updatePassword()"
      class="button-medium has-sherpa float-right"
    >
      {{ $t("settings.save") }}
      <span>
        <img
          src="@/assets/icons/PNG/check-circle-white.png"
          style="margin-top: -5px; vertical-align: middle; position: relative"
          @mouseover="show = true"
          @mouseleave="show = false"
          alt="info"
        />
      </span>
    </button>
  </div>
</template>
<script>
import countrycodes from "@/countrycodes.json";
import ToolTip from "@/components/ToolTip.vue";
import notification from "@/notification.js";
import UserService from "@/services/user.service";
import LoaderComponent from "@/components/LoaderComponent.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
const API_URL = process.env.VUE_APP_BASE_URL;
const API_ENV = process.env.VUE_APP_ENV;
export default {
  components: { ToolTip, LoaderComponent },
  name: "SettingsProfile",
  props: ["isMobile", "active"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      countrycodes,
      showDropdown: false,
      isFocusDel: false,
      canSave: true,
      dataChanged: false,
      selectedPrefix: "",
      validPhone: true,
      belongTo: "",
      loading: true,
      form: {
        firstName: "",
        lastName: "",
        prefix: "+47",
        mobile: "",
        email: "",
      },
      updatePasswordForm: {
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
      },
    };
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        mobile: {
          required,
        },
        email: {
          required,
          email,
        },
      },
      updatePasswordForm: {
        oldPassword: {
          required,
          min: minLength(8),
          valid(value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /\d/.test(value);
            // const containsSpecial = /[#?!@$%^&*-]/.test(value);
            return containsUppercase && containsLowercase && containsNumber;
          },
        },
        newPassword: {
          required,
          min: minLength(8),
          valid(value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /\d/.test(value);
            // const containsSpecial = /[#?!@$%^&*-]/.test(value);
            return containsUppercase && containsLowercase && containsNumber;
          },
        },
        repeatPassword: {
          required,
          valid(value) {
            return value === this.updatePasswordForm.newPassword;
          },
        },
      },
    };
  },
  methods: {
    showHidePassword(val) {
      const x = document.getElementById(val);
      const eye = document.getElementById("eye-" + val);
      eye.classList.toggle("fa-eye");
      eye.classList.toggle("fa-eye-slash");
      x.type = x.type === "text" ? "password" : "text";
    },
    changed(res) {
      this.dataChanged = res;
    },
    blockSubmit() {
      let block = true;
      if (this.dataChanged) {
        if (!this.v$.form.$invalid) {
          block = false;
        }
      }
      return block;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectPrefix(prefix) {
      this.selectedPrefix = prefix;
      this.form.prefix = prefix;
      this.showDropdown = false;
    },
    save() {
      const that = this;
      UserService.saveProfile(this.belongTo, this.form).then(
        () => {
          notification.toast("success", this.$t("settings.notification_saved"));
          that.changed(false);
        },
        (error) => {
          this.content =
            (error.response && error.response.data && error.response.data.message) || error.message;
          error.toString();
          notification.toast("error", this.$t("settings.notification_error"));
        }
      );
    },
    async checkPhone() {
      const prefix = this.selectedPrefix ? this.selectedPrefix : this.form.prefix;
      const fullnumber = prefix + this.form.mobile;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      };
      if (this.form.mobile.length > 2) {
        this.axios
          .get(
            `https://authentication.${API_ENV}.${API_URL}/authentication/validate/phone/${fullnumber}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status_message === "Success") {
              this.validPhone = true;
              this.verifiedPhone = true;
            } else {
              this.validPhone = false;
              // this.v$.form.mobile.$touch();
              if (this.loading) {
                notification.toast("error", this.$t("register.wrong_phone"));
                this.loading = false;
              }
            }
          });
      }
    },
    getId() {
      this.belongTo = this.$store.state.userId;
    },
    getData() {
      UserService.getProfile(this.belongTo).then(
        (response) => {
          //console.log(response);
          this.form.firstName = response.data.User.firstName;
          this.form.lastName = response.data.User.lastName;
          this.form.prefix = response.data.User.prefix;
          this.form.mobile = response.data.User.mobile;
          this.form.email = response.data.User.email;
          this.loading = false;
        },
        (error) => {
          if (error == "Error: Network Error") {
            this.content = this.$t("login.toast_network_error");
          } else {
            this.content = error.response && error.response.data && error.response.data.message;
            error.message || error.toString();
          }
          notification.toast("error", this.$t("forgot_password.problem"));
          this.loading = false;
        }
      );
    },
    updatePassword() {
      let passData = {
        oldPassword: this.updatePasswordForm.oldPassword,
        newPassword: this.updatePasswordForm.newPassword,
        userId: this.belongTo,
      };
      const that = this;
      UserService.resetOldNew(passData).then(
        () => {
          notification.toast("success", this.$t("settings.notification_saved"));
          that.changed(false);
          that.updatePasswordForm.oldPassword = "";
          that.updatePasswordForm.newPassword = "";
          that.updatePasswordForm.repeatPassword = "";
        },
        (error) => {
          this.content =
            (error.response && error.response.data && error.response.data.message) || error.message;
          error.toString();
          notification.toast("error", this.$t("settings.notification_error"));
        }
      );
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
  },
  mounted() {
    this.getId();
    if (this.active) {
      this.getData();
    }
  },
  watch: {
    active() {
      if (this.active) {
        this.getData();
      } else {
        this.loading = true;
      }
    },
  },
};
</script>
<style scoped>
hr {
  background-color: #e7e1dc;
  border: none;
  height: 1px;
  width: 100%;
}
.field {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
