<template>
  <div class="dis-flex">
    <button
      class="button-medium-empty-control"
      @mouseover="showClone = true"
      @mouseleave="showClone = false"
      @click="clone(id)"
    >
      <img
        src="@/assets/icons/PNG/clone.png"
        class="s2-control-img"
        :alt="$t('new_delivery.step2.tooltip_clone')"
        @mouseover="showClone = true"
      />
      <div class="s2-control-div" @click="clone(id)">&nbsp;</div>
    </button>
    <button
      class="button-medium-empty-control"
      @mouseover="showPlus = true"
      @mouseleave="showPlus = false"
      @click="saveTemplate(id)"
    >
      <img
        src="@/assets/icons/PNG/plus-gray.png"
        class="s2-control-img"
        :alt="$t('new_delivery.step2.tooltip_save_template')"
        @mouseover="showPlus = true"
      />
      <div class="s2-control-div" @click="saveTemplate(id)">&nbsp;</div>
    </button>
    <button
      class="button-medium-empty-control"
      @mouseover="showCancel = true"
      @mouseleave="showCancel = false"
      @click="cancel(id)"
    >
      <img
        src="@/assets/icons/PNG/times-gray.png"
        class="s2-control-img"
        :alt="$t('new_delivery.step2.tooltip_remove')"
        @mouseover="showCancel = true"
      />
      <div class="s2-control-div" @click="cancel(id)">&nbsp;</div>
    </button>
  </div>
  <div class="tooltip-clone dis-inline" v-if="showClone">
    <div
      :class="`tooltip-body tooltip-${vertical}-${horizontal} tooltip-${theme}`"
    >
      {{ $t("new_delivery.step2.tooltip_clone") }}
    </div>
  </div>
  <div class="tooltip-plus dis-inline" v-if="showPlus">
    <div
      :class="`tooltip-body tooltip-${vertical}-${horizontal} tooltip-${theme}`"
    >
      {{ $t("new_delivery.step2.tooltip_save_template") }}
    </div>
  </div>
  <div class="tooltip-cancel dis-inline" v-if="showCancel">
    <div
      :class="`tooltip-body tooltip-${vertical}-${horizontal} tooltip-${theme}`"
    >
      {{ $t("new_delivery.step2.tooltip_remove") }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Step2Control",
  props: ["vertical", "horizontal", "theme", "id"],
  emits: ["controlclone", "controlsavetemplate", "controlcancel"],

  components: {},
  data() {
    return {
      showClone: false,
      showPlus: false,
      showCancel: false,
    };
  },
  methods: {
    clone(value) {
      this.showClone = false;
      this.$emit("controlclone", value);
    },
    saveTemplate(value) {
      this.showPlus = false;
      this.$emit("controlsavetemplate", value);
    },
    cancel(value) {
      this.showCancel = false;
      this.$emit("controlcancel", value);
    },
  },
};
</script>
<style></style>
