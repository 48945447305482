<template>
  <section class="section is-paddingless">
    <div class="columns is-gapless">
      <SideBar />

      <div class="column ml288" id="maincontent">
        <router-view v-slot="{ Component, route }">
          <transition name="component-fade" mode="out-in">
            <div :key="route.name" :id="route.name">
              <component :is="Component" />
            </div>
          </transition>
        </router-view>
      </div>
    </div>
  </section>
  <div v-if="isAdmin" class="adminbox">
    <p><b>ADMIN</b></p>
    <p class="elipsis-text" :title="$store.state.userId">
      Your ID: <b>{{ $store.state.userId }}</b>
    </p>
    <p
      class="elipsis-text"
      :title="
        $store.state.becomeData.firstName +
        ' ' +
        $store.state.becomeData.lastName
      "
    >
      {{ $store.state.becomeData.firstName }}
      {{ $store.state.becomeData.lastName }}
    </p>
    <p class="elipsis-text" :title="$store.state.becomeData.email">
      <b>{{ $store.state.becomeData.email }}</b>
    </p>
  </div>
</template>
<script>
import SideBar from "@/components/Sidebar.vue";

export default {
  name: "DashBoard",
  components: {
    SideBar,
  },
  data() {
    return {
      isAdmin: false,
    };
  },
  methods: {
    checkSidebar() {
      const width = window.innerWidth;
      const main = document.getElementById("maincontent");
      width < 1120
        ? main.classList.remove("ml288")
        : main.classList.add("ml288");
    },
  },
  mounted() {
    this.isAdmin = this.$store.state.accountType === "admin" ? true : false;
    setTimeout(() => {
      this.checkSidebar();
    }, 50);
    window.addEventListener("resize", this.checkSidebar);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkSidebar);
  },
};
</script>
<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease-in-out;
  height: 100vh;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 100vh;
}

.adminbox {
  position: fixed;
  top: 5px;
  right: 5px;
  text-align: right;
  font-size: 14px;
  border: 2px solid #003b46;
  border-radius: 5px;
  padding: 5px;
  max-width: 150px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  background-color: white;
  filter: drop-shadow(5px 5px 5px #003b46);
}
</style>
