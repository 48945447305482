import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from "axios";
import VueAxios from "vue-axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { PromiseDialog } from "vue3-promise-dialog";
// import { Notifier } from "@airbrake/browser";
import store from "./store";
import router from "./router";
// const envType = process.env.VUE_APP_ENV;
// const airbrake = new Notifier({
//   environment: envType,
//   projectId: 438267,
//   projectKey: "2ea16ff3fadd403c0e308068492f7989",
//   instrumentation: {
//     console: false,
//   },
// });
axios.defaults.baseURL = `https://${process.env.VUE_APP_ENV}.${process.env.VUE_APP_BASE_URL}`;
axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  locale: "nb",
  fallbackLocale: "en",
  globalInjection: true,
  warnHtmlMessage: false,
  warnHtmlInMessage: "off",
  messages: loadLocaleMessages(),
});
require("@/assets/main.scss");

const toastOptions = {
  hideProgressBar: true,
  timeout: 5000,
  position: "bottom-right",
  maxToasts: 3,
};
const app = createApp(App).use(store).use(router);

// app.config.errorHandler = function (err, _vm, info) {
//   airbrake.notify({
//     error: err,
//     params: { info },
//     context: { severity: "warning" },
//   });
// };

app.use(i18n);
app.use(Toast, toastOptions);
app.use(VueAxios, axios);
app.component("v-select", vSelect);
app.provide("axios", app.config.globalProperties.axios);
app.use(PromiseDialog);
app.mount("#app");
