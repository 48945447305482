<template>
  <LoaderComponent v-if="showLoader" @escape="showLoader = false" />
  <div class="container">
    <h1 class="title is-1">{{ $t("deliveries.title") }}</h1>
    <div id="tabs-with-content">
      <div class="tabs deltabs">
        <ul>
          <li>
            <button
              class="tabBtn technical18-bold"
              :class="{ selected: active == 0 }"
              @click="setActive(0)"
            >
              {{ $t("deliveries.open") }}
            </button>
          </li>
          <li>
            <button
              class="tabBtn technical18-bold"
              :class="{ selected: active == 1 }"
              @click="setActive(1)"
            >
              {{ $t("deliveries.completed") }}
            </button>
          </li>
          <li class="pos-absolute search-deliveries">
            <div class="field mt37">
              <div class="control has-icons-right">
                <input
                  class="input"
                  type="text"
                  v-model="search"
                  :placeholder="$t('addressbook.searchinput')"
                />
                <span class="icon is-small is-right" style="margin-top: 6.5px">
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8594 16.7383L13.6055 12.4844C13.5 12.4141 13.3945 12.3438 13.2891 12.3438H12.832C13.9219 11.0781 14.625 9.39062 14.625 7.5625C14.625 3.55469 11.3203 0.25 7.3125 0.25C3.26953 0.25 0 3.55469 0 7.5625C0 11.6055 3.26953 14.875 7.3125 14.875C9.14062 14.875 10.793 14.207 12.0938 13.1172V13.5742C12.0938 13.6797 12.1289 13.7852 12.1992 13.8906L16.4531 18.1445C16.6289 18.3203 16.9102 18.3203 17.0508 18.1445L17.8594 17.3359C18.0352 17.1953 18.0352 16.9141 17.8594 16.7383ZM7.3125 13.1875C4.18359 13.1875 1.6875 10.6914 1.6875 7.5625C1.6875 4.46875 4.18359 1.9375 7.3125 1.9375C10.4062 1.9375 12.9375 4.46875 12.9375 7.5625C12.9375 10.6914 10.4062 13.1875 7.3125 13.1875Z"
                      fill="#6E6E6E"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <section v-show="active == 0" class="tab-content">
          <OpenComponent
            :data="openData"
            :changed="this.search"
            :paginate="paginationOpen"
            @updatecall="getAllOpenTasks"
          />
        </section>
        <section v-show="active == 1" class="tab-content">
          <OpenComponent
            :data="completedData"
            :changed="this.search"
            :paginate="paginationComplete"
            @updatecall="getAllCompletedTasks"
          />
        </section>
      </div>
    </div>
  </div>
  <WelcomeModal :show="showModal" @close="closeWelcome()" />
</template>
<script>
import WelcomeModal from "@/components/WelcomeModal.vue";
import OpenComponent from "@/components/OpenComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import UserService from "@/services/user.service";
import ls from "localstorage-slim";

ls.config.encrypt = true;
export default {
  components: {
    OpenComponent,
    WelcomeModal,
    LoaderComponent,
  },
  name: "OpenDeliveries",
  props: {},
  data() {
    return {
      showModal: false,
      showLoader: true,
      search: "",
      active: 0,
      data: [],
      openData: [],
      completedData: [],
      request: {
        page: 1,
        perPage: 20,
        filter: {
          state: "complete",
          creatorId: "",
          tracking: ["in-transit", "waiting-pickup", "picked-up", "sent"],
        },
      },
      paginationOpen: {
        currentPage: 1,
        total: 0,
        pages: 1,
      },
      paginationComplete: {
        currentPage: 1,
        total: 0,
        pages: 1,
      },
      requestComplete: {
        page: 1,
        perPage: 20,
        filter: {
          state: "complete",
          creatorId: "",
          tracking: ["delivered", "cancelled"],
        },
      },
    };
  },
  methods: {
    firstTime() {
      const seen = ls.get("welcome");
      if (seen === null || seen === undefined) {
        this.showModal = true;
      }
    },
    closeWelcome() {
      this.showModal = false;
      ls.set("welcome", "true");
    },
    getAllOpenTasks(val) {
      if (val !== undefined) {
        this.request.page = val;
      }
      this.showLoader = true;
      this.openData = [];
      this.request.filter.creatorId = this.$store.state.userId;
      UserService.getAllTasks(this.request).then(
        (response) => {
          let tasks = response.data.tasks;
          for (let task of tasks) {
            if (
              task.tracking == "in-transit" ||
              task.tracking == "waiting-pickup" ||
              task.tracking == "picked-up" ||
              task.tracking == "sent"
            ) {
              let sender = {
                company: task.senderName,
                area: task.pickupStreet + " ," + task.pickupStreetNumber,
                city: task.pickupCity,
              };
              let recipient = {
                company: task.recipientName,
                area: task.deliveryStreet + " ," + task.deliveryStreetNumber,
                city: task.deliveryCity,
              };
              this.openData.push({
                id: task.id,
                description: task.title,
                created_at: task.createdAt,
                status: task.tracking,
                sender,
                recipient,
              });
            }
          }
          this.paginationOpen = {
            total: response.data.total,
            currentPage: response.data.page,
            pages: response.data.pageCount,
          };
          setTimeout(() => {
            this.showLoader = false;
          }, 300);
        },
        (error) => {
          let content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          console.error(content);
          setTimeout(() => {
            this.showLoader = false;
          }, 300);
        }
      );
    },
    getAllCompletedTasks(val) {
      if (val !== undefined) {
        this.requestComplete.page = val;
      }
      this.showLoader = true;
      this.completedData = [];
      this.requestComplete.filter.creatorId = this.$store.state.userId;
      UserService.getAllTasks(this.requestComplete).then(
        (response) => {
          let tasks = response.data.tasks;
          for (let task of tasks) {
            if (task.tracking == "delivered" || task.tracking == "cancelled") {
              let sender = {
                company: task.senderName,
                area: task.pickupStreet + " ," + task.pickupStreetNumber,
                city: task.pickupCity,
              };
              let recipient = {
                company: task.recipientName,
                area: task.deliveryStreet + " ," + task.deliveryStreetNumber,
                city: task.deliveryCity,
              };
              this.completedData.push({
                id: task.id,
                description: task.title,
                created_at: task.createdAt,
                status: task.tracking,
                sender,
                recipient,
              });
            }
          }
          this.paginationComplete = {
            total: response.data.total,
            currentPage: response.data.page,
            pages: response.data.pageCount,
          };
          setTimeout(() => {
            this.showLoader = false;
          }, 300);
        },
        (error) => {
          let content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          console.error(content);
          setTimeout(() => {
            this.showLoader = false;
          }, 300);
        }
      );
    },
    filtering() {
      return this.data.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k].company).toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
    setActive(value) {
      this.showLoader = true;
      setTimeout(() => {
        this.showLoader = false;
        this.active = value;
      }, 300);
      this.search = "";
    },
    separateData() {
      this.openData = this.filteredData.filter(
        (o) =>
          o.status == "in-transit" ||
          o.status == "waiting-pickup" ||
          o.status == "picked-up" ||
          o.status == "sent"
      );
      this.completedData = this.filteredData.filter(
        (o) => o.status == "delivered" || o.status == "cancelled"
      );
      this.showLoader = false;
    },
    async checkGdpr() {
      await UserService.getgdpr(this.$store.state.userId).then(
        (res) => {
          //console.log(res);
          let gdpr = res.data.hasAcceptedGdpr;
          if (!gdpr || gdpr === null) {
            this.$router.push({ path: "/gdpr" });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
  },
  mounted() {
    this.checkGdpr();
    this.firstTime();
    this.getAllOpenTasks();
    //this.separateData();
  },
  computed: {
    filteredData() {
      return this.filtering();
    },
  },
  watch: {
    active() {
      if (this.active == 0) {
        this.getAllOpenTasks();
      }
      if (this.active == 1) {
        this.getAllCompletedTasks();
      }
    },
    search() {
      if (this.active == 0 && this.paginationOpen.total > 0) {
        this.request.filter.search = this.search;
        this.getAllOpenTasks();
      }
      if (this.active == 1 && this.paginationComplete.total > 0) {
        this.requestComplete.filter.search = this.search;
        this.getAllCompletedTasks();
      }
    },
  },
};
</script>
<style scoped>
.tabs ul {
  border-bottom-width: 0 !important;
}
</style>
