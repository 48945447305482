<template>
  <section class="section">
    <div class="login-container is-fullhd">
      <div class="columns is-mobile is-centered">
        <div
          class="column is-half-desktop is-half-tablet is-full-mobile p-0 max-590"
        >
          <FraktBestillingLogo />
          <h1
            class="title is-size-2 has-text-mosque mb-title has-text-centered"
            style="font-size: 68px !important"
          >
            404
          </h1>
          <div class="auth-container">
            <div
              class="field mt-medium forgot-field forgot-field has-text-centered"
              style="margin-bottom: 52px !important"
            >
              <span class="has-text-weight-normal has-text-mosque ml-12px">
                Page not found
              </span>
            </div>
            <div
              class="field"
              style="border-top: 1px solid #d6d6d6; text-align: center"
            >
              <router-link to="/">
                <button class="button-medium mt-27px">Home</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FraktBestillingLogo from "@/components/FraktBestillingLogo.vue";

export default {
  name: "NotFound",
  components: { FraktBestillingLogo },

  data() {
    return {};
  },

  methods: {},
};
</script>
