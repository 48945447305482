<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="{ 'deliveries-modal': !cancellationRequest }">
          <div class="deliveries-modal-header">
            <h1 class="deliveries-modal-title" v-if="!cancellationRequest">
              {{ $t("deliveries.modal_title") }}
              <br class="is-hidden-desktop" />
              <span class="delmodtitle">{{ this.selected.title }}</span>
            </h1>
            <h1 class="deliveries-modal-title" v-if="cancellationRequest">
              {{ $t("deliveries.cancellation_request_title") }}
            </h1>
            <button class="delete deliveries-close" aria-label="close" @click="close()"></button>
          </div>

          <div class="deliveries-modal-body" :class="{ 'mob-body-del': !cancellationRequest }">
            <div v-if="!cancellationRequest" class="columns mb-0 ml-0">
              <div class="column is-3 pl-0" v-if="ready">
                <span class="deliveries-modal-label">{{ $t("deliveries.modal_status") }}</span>
                <hr class="deliveries-hr mt-0" />

                <div class="columns is-mobile">
                  <div class="column is-4 has-text-centered">
                    <div class="pos-relative">
                      <button
                        class="status-btn-big mb-55 v-top"
                        :style="`background-color:${statusColor(this.selected.tracking)};`"
                      ></button>
                      <div class="statusbar1"></div>
                    </div>

                    <div class="pos-relative">
                      <button
                        class="status-btn-small mb-55 v-top"
                        :style="`background-color:${statusColor(this.selected.tracking)};`"
                      ></button>
                      <div class="statusbar2"></div>
                    </div>

                    <div class="pos-relative">
                      <button
                        class="status-btn-small mb-55 v-top"
                        :style="`background-color:${statusColor(this.selected.tracking)};`"
                      ></button>
                      <div class="statusbar3"></div>
                    </div>

                    <div class="pos-relative">
                      <button
                        class="status-btn-big mb-55 v-top"
                        :style="`background-color:${statusColor(this.selected.tracking)};`"
                      ></button>
                    </div>
                  </div>
                  <div class="column is-8">
                    <div class="mb-48" v-if="this.selected.tracking === 'waiting-pickup'">
                      <span style="min-height: 24px">{{ $t(`deliveries.waiting-pickup`) }}</span>
                    </div>
                    <div class="mb-48" v-else>
                      <p style="min-height: 24px">&nbsp;</p>
                    </div>

                    <div class="mb-48" v-if="this.selected.tracking === 'picked-up'">
                      <span style="min-height: 24px">{{ $t(`deliveries.picked-up`) }}</span>
                    </div>
                    <div class="mb-48" v-else>
                      <p style="min-height: 12px">&nbsp;</p>
                    </div>

                    <div class="mb-48" v-if="this.selected.tracking === 'in-transit'">
                      <span style="min-height: 24px">{{ $t(`deliveries.in-transit`) }}</span>
                    </div>
                    <div class="mb-48" v-else>
                      <p style="min-height: 12px">&nbsp;</p>
                    </div>

                    <div
                      class="mb-48"
                      v-if="
                        this.selected.tracking === 'delivered' ||
                        this.selected.tracking === 'cancelled'
                      "
                    >
                      <span style="min-height: 24px">{{
                        $t(`deliveries.${this.selected.tracking}`)
                      }}</span>
                    </div>
                    <div class="mb-48" v-else>
                      <p style="min-height: 24px">&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-9">
                <div class="columns mb-0 mr-0 ml-0">
                  <div class="column pl-0 pr-0">
                    <span class="deliveries-modal-label">{{
                      $t("deliveries.modal_packages")
                    }}</span>
                    <hr class="deliveries-hr mt-0" />
                    <div class="columns is-mobile mb-0" style="margin-left: 3px">
                      <div class="column delColGray mob-flex-grow-un">
                        <img
                          src="/icons/SVG/items.svg"
                          alt="items"
                          style="transform: translate(0px, 7px)"
                        />
                        <span class="is-hidden-mobile">{{
                          $t("new_delivery.step2.items_count")
                        }}</span>
                        <span class="technical16-title pl-2">{{ this.items.length }}</span>
                      </div>
                      <div class="column delColGray mob-flex-grow-un">
                        <img
                          src="/icons/SVG/volume.svg"
                          alt="volume"
                          style="transform: translate(0px, 7px)"
                        />
                        <span class="is-hidden-mobile">{{ $t("deliveries.modal_volume") }} </span>
                        <span class="technical16-title pl-2"> {{ volume }} m³</span>
                      </div>
                      <div class="column delColGray mob-flex-grow-un">
                        <img
                          src="/icons/SVG/weight.svg"
                          alt="weight"
                          style="transform: translate(0px, 7px)"
                        />
                        <span class="is-hidden-mobile"> {{ $t("new_delivery.step2.weight") }}</span>
                        <span class="technical16-title pl-2">{{ weight / 1000 }} Kg</span>
                      </div>
                      <div
                        class="column delColGray mob-flex-grow-un"
                        style="padding-top: 21px !important"
                      >
                        <span class="is-hidden-mobile">{{ $t("new_delivery.step3.price") }}</span>
                        <span class="technical16-title pl-2"
                          >{{ this.selected.totalPrice }} {{ this.selected.currency }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns mb-0 mr-0 ml-0">
                  <div class="column pl-0 pr-0">
                    <span class="deliveries-modal-label">{{
                      $t("deliveries.modal_service_provider")
                    }}</span>
                    <hr class="deliveries-hr mt-0" />
                    <div
                      class="column delColGray mob-flex-grow-un mr-0 p-0"
                      style="margin-top: -14px"
                    >
                      <div class="columns p-0 ml-0">
                        <div class="column p-0 flex-b-unset">
                          <img
                            :src="serviceLogo(this.selected.service)"
                            alt="right"
                            v-if="show"
                            style="vertical-align: middle; max-height: 59px"
                          />
                        </div>
                        <div class="column p-0 flex-b-unset">
                          <span class="technical16-title">{{
                            formatText(this.selected.serviceType)
                          }}</span
                          ><br />
                          <span class="caption-text">{{ this.selected.service }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column pr-0">
                    <span class="deliveries-modal-label">{{
                      $t("deliveries.modal_extra_services")
                    }}</span>
                    <hr class="deliveries-hr mt-0" />
                    <div
                      class="column delColGray mob-flex-grow-un mr-0 p-0"
                      style="margin-top: -14px"
                    >
                      <div class="columns p-0 ml-0">
                        <div class="column p-0 flex-b-unset">
                          <img :src="extraLogo(this.selected.options)" alt="right" />
                        </div>
                        <div class="column p-0 flex-b-unset">
                          <span class="pl-2 pb-0 technical16-title">{{
                            this.selected.options
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns mb-0 mr-0 ml-0">
                  <div class="column pl-0 pr-0">
                    <span class="deliveries-modal-label">{{
                      $t("deliveries.modal_tracking_number")
                    }}</span>
                    <hr class="deliveries-hr mt-0" />
                    <div class="column delColGray mob-flex-grow-un mr-0" style="margin-top: -14px">
                      <i class="pr-1 has-text-gray far fal fa-tally"></i>

                      {{ this.selected.referenceNumber }}
                    </div>
                  </div>
                  <div class="column pr-0">
                    <span class="deliveries-modal-label">{{ $t("deliveries.modal_task_id") }}</span>
                    <hr class="deliveries-hr mt-0" />
                    <div class="column delColGray mob-flex-grow-un mr-0" style="margin-top: -14px">
                      <i class="pr-1 has-text-gray far fal fa-tally"></i>
                      {{ this.selected.id }}
                    </div>
                  </div>
                </div>
                <div class="columns mb-0 mr-0 ml-0">
                  <div class="column pl-0 pr-0 has-text-centered">
                    <a :href="this.selected.labelsUrl" target="_blank"
                      ><span class="button">{{ $t("new_delivery.sent.print") }}</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-if="cancellationRequest" style="height: 200px">
              <div class="field">
                <label class="label">{{ $t("deliveries.cancellation_request_text") }}</label>
                <div class="control">
                  <textarea
                    class="textarea is-small"
                    v-model="userdata.comment"
                    :placeholder="$t('addressbook.modal.placeholder_comment')"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="deliveries-modal-footer">
            <slot name="footer">
              <button class="red-button" @click="cancellationReq()" v-if="!cancellationRequest">
                <img src="@/assets/icons/PNG/red-dialog.png" alt="right" />
                {{ $t("deliveries.cancellation_request_title") }}
              </button>
              <button
                class="button-medium"
                @click="cancellationSend()"
                v-if="cancellationRequest"
                :disabled="userdata.comment.length < 1"
              >
                <img
                  src="@/assets/icons/PNG/check-circle-white.png"
                  style="margin-top: -5px; vertical-align: middle; position: relative"
                  alt="right"
                />
                {{ $t("deliveries.cancellation_send") }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import notification from "@/notification.js";
import UserService from "@/services/user.service";
import nimberLogo from "@/assets/images/deliveries/logista.png";
import postnordLogo from "@/assets/images/deliveries/postnord.png";
import bringLogo from "@/assets/images/deliveries/bring.png";
import emptyLogo from "@/assets/images/deliveries/empty.png";
import helperLogo from "@/assets/images/extra/helper.png";
import returnLogo from "@/assets/images/extra/return.png";
import disposalLogo from "@/assets/images/extra/disposal.png";
import installationLogo from "@/assets/images/extra/installation.png";

export default {
  props: {
    show: Boolean,
    taskId: Number,
  },
  emits: ["close"],
  data() {
    return {
      cancellationRequest: false,
      selected: {},
      ready: false,
      items: {},
      volume: 0,
      weight: 0,
      request: {
        page: 1,
        perPage: 20,
        filter: {},
      },
      logoImg: {
        nimber: nimberLogo,
        postnord: postnordLogo,
        bring: bringLogo,
      },
      extraLogoImg: {
        helper: helperLogo,
        disposal: disposalLogo,
        return: returnLogo,
        installation: installationLogo,
      },
      userdata: {
        userId: "",
        name: "",
        company: "",
        email: "",
        phone: "",
        jobId: "",
        comment: "",
      },
      // date: "",
    };
  },
  methods: {
    formatText(val) {
      if (val) {
        return val.replaceAll("_", " ");
      }
      return "";
    },
    serviceLogo(value) {
      return this.logoImg[value] ? this.logoImg[value] : emptyLogo;
    },
    extraLogo(value) {
      return this.extraLogoImg[value] ? this.extraLogoImg[value] : emptyLogo;
    },
    totalVolume() {
      let totalVolume = 0;
      this.items.forEach((item) => {
        totalVolume +=
          (parseInt(item.width) / 100) *
          (parseInt(item.height) / 100) *
          (parseInt(item.length) / 100);
      });
      // set to use max 2 decimals
      const countDecimals = function (value) {
        if (value % 1 != 0)
          if (value.toString().split(".")[1].length < 3) {
            return value.toString().split(".")[1].length;
          } else {
            return 2;
          }
        return 0;
      };
      this.volume = isNaN(totalVolume) ? 0 : totalVolume.toFixed(countDecimals(totalVolume));
    },
    totalWeight() {
      let totalWeight = 0;
      this.items.forEach((item) => {
        totalWeight += parseInt(item.weight);
      });
      const countDecimals = function (value) {
        if (value % 1 != 0)
          if (value.toString().split(".")[1].length < 3) {
            return value.toString().split(".")[1].length;
          } else {
            return 2;
          }
        return 0;
      };
      this.weight = isNaN(totalWeight) ? 0 : totalWeight.toFixed(countDecimals(totalWeight));
    },
    statusColor(val) {
      let color = "";
      switch (val) {
        case "in-transit":
          color = "#FFD60A";
          break;
        case "picked-up":
          color = "#FF9F0A";
          break;
        case "waiting-pickup":
          color = "#D6D6D6";
          break;
        case "delivered":
          color = "#5CDB95";
          break;
        case "cancelled":
          color = "#FF0033";
          break;
      }
      return color;
    },
    cancellationReq() {
      this.cancellationRequest = true;
    },

    cancellationSend() {
      this.userdata.userId = this.$store.state.userId;
      this.userdata.jobId = this.taskId;
      UserService.requestCancellation(this.userdata).then(
        () => {
          notification.toast("success", this.$t("deliveries.cancellation_sent"));
          this.userdata.comment = "";
          this.close();
        },
        (error) => {
          console.error(error);
          notification.toast("error", this.$t("forgot_password.problem"));
        }
      );
    },
    getUserData() {
      UserService.getProfile(this.$store.state.userId).then(
        (response) => {
          this.userdata.userId = this.$store.state.userId;
          this.userdata.name = `${response.data.User.firstName} ${response.data.User.lastName}`;
          this.userdata.company = response.data.Company?.companyName
            ? response.data.Company.companyName
            : "";
          this.userdata.email = response.data.User.email;
          this.userdata.phone = `${response.data.User.prefix}${response.data.User.mobile}`;
        },
        (error) => {
          console.error(error);
          if (error == "Error: Network Error") {
            this.content = this.$t("login.toast_network_error");
          } else {
            this.content = error.response && error.response.data && error.response.data.message;
            error.message || error.toString();
          }
          notification.toast("error", this.$t("forgot_password.problem"));
          this.loading = false;
        }
      );
    },
    getSingleTask() {
      this.request.filter.id = this.taskId;
      this.selected = [];
      UserService.getAllTasks(this.request).then(
        (response) => {
          this.selected = response.data.tasks[0];
          this.items = response.data.items;
          this.totalVolume();
          this.totalWeight();
          this.tracking();
          this.ready = true;
        },
        (error) => {
          let content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          console.error(content);
          setTimeout(() => {
            this.showLoader = false;
          }, 300);
        }
      );
    },
    tracking() {
      let request = {
        taskId: this.taskId,
      };
      UserService.tracking(request).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          let content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          console.error(content);
        }
      );
    },
    close() {
      this.cancellationRequest = false;
      this.userdata.comment = "";
      this.selected = {};
      this.$emit("close");
    },
  },
  mounted() {
    this.getUserData();
    if (this.show) {
      this.tracking();
      setTimeout(() => {
        this.getSingleTask();
      }, 100);
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.tracking();
        setTimeout(() => {
          this.getSingleTask();
        }, 100);
      }
    },
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.deliveries-close {
  position: absolute !important;
  right: 28px;
  top: 40px;
  background-color: rgba(29, 29, 31, 0.8);
}
.modal-container {
  max-width: 960px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.deliveries-modal {
  background-color: white;
  border-radius: 4px;
}
.deliveries-modal-header {
  height: 100px;
  border-bottom: 1px #d6d6d6 solid;
  padding: 36px 40px;
  position: relative !important;
}
.deliveries-close {
  position: absolute;
  right: 28px;
  top: 40px;
  background-color: rgba(29, 29, 31, 0.8);
}
.deliveries-modal-body {
  padding: 20px 40px 0;
}
.button-bottom-deliveries-modal {
  width: 113px !important;
  height: 43px !important;
  background-color: #003b46 !important;
  color: white !important;
  border-radius: 3px !important;
  padding: 16px 24px !important;
  font-family: "Proxima Nova A Semibold" !important;
  font-size: 16px !important;
  line-height: auto !important;
  letter-spacing: 0% !important;
  border: 0 !important;
}
.deliveries-modal-footer {
  height: 83px;
  border-top: 1px #d6d6d6 solid;
  padding: 20px 40px;
  text-align: right;
}
.deliveries-modal-image {
  max-width: 948px;
  min-width: calc(100% - 128px);
  height: 229px;
  background-color: #003b46 !important;
  margin-top: 47px;
}
.deliveries-modal-label {
  font-size: 15px;
  line-height: 18px;

  color: #6e6e6e;
}
.deliveries-hr {
  color: #d6d6d6;
}
.delColGray {
  background: #f8f7f5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-right: 10px;
  font-family: "Proxima Nova A";
  font-size: 18px;
  line-height: 26px;
  padding: 16px !important;
  flex-basis: unset !important;
}
.flex-b-unset {
  flex-basis: unset !important;
}
.red-button {
  border: none !important;
  color: #ff2d55 !important;
  padding: 12px 20px !important;
  font-size: 16px;
  background: none !important;
}
</style>
