<template>
  <div class="mb-5 mlr-16" v-if="active">
    <h1 class="headline3 bottom64 is-hidden-mobile">
      {{ $t("new_delivery.header") }}
      <span class="headline-thin">{{ $t("new_delivery.step4.header") }}</span>
    </h1>
    <div class="mt-0 mb-0">
      <div id="options-header" class="columns is-vcentered is-multiline is-mobile p-0 mb-0">
        <div class="column is-2-desktop is-10-mobile is-vcentered m-0 moptionhead">
          <h2 class="body-text-title step2label step4m">
            {{ $t("new_delivery.step4.options_for") }}
          </h2>
        </div>
        <div class="column is-1-mobile m-0 p-0 is-hidden-desktop mprice">
          <div v-show="isComplete">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
                fill="#5CDB95"
              />
            </svg>
          </div>
        </div>

        <div class="column is-7-desktop is-12-mobile m-0 mlm-0 p-0">
          <div
            class="p-0 pr-5 m-0 selectedHead columns is-vcentered"
            style="display: inline-flex; max-width: 100%"
          >
            <div class="column is-2 is-2-mobile p-0">
              <img :src="serviceLogo(selected?.selected?.company)" alt="right" />
            </div>
            <div class="column p-0 is-7">
              <p class="technical18-bold nowrap" style="overflow: hidden; text-overflow: ellipsis">
                {{
                  $t(
                    `new_delivery.step3.${selected?.selected?.company}.${selected?.selected?.service}.title`
                  )
                }}
              </p>

              <p class="technical13">
                {{ rename(selected?.selected?.company) }}
              </p>
            </div>
            <div class="column is-3 is-hidden-mobile has-text-centered p-2">
              <div class="expected selectedExpected">
                {{
                  $t(
                    `new_delivery.step3.${selected?.selected?.company}.${selected?.selected?.service}.expected`
                  )
                }}
              </div>
            </div>
          </div>
          <!-- <div
            class="float-right p-1 m-0 ml-3 selectedHeadExtra columns is-vcentered is-hidden-mobile"
            style="display: inline-block"
            v-show="selected?.selectedExtraVal != null"
          >
            <div class="column p-0">
              <span class="technical18-bold">{{ $t("new_delivery.step3.extra_services") }}</span
              ><br />
              <span class="technical13">{{ selected?.selectedExtra }}</span>
            </div>
          </div> -->
          <div
            class="is-hidden-desktop float-right p-1 m-0 ml-3 selectedHeadExtras4 columns is-vcentered"
            style="display: inline-block"
            v-if="selected?.selectedExtra"
          >
            <img :src="extraLogo(selected?.selectedExtra)" alt="right" />
          </div>
        </div>
        <div class="column is-3-desktop is-12-mobile pl-0 pr-0">
          <span class="headline3 is-hidden-mobile"
            ><span class="headline-thin mr-1">{{ $t("new_delivery.step3.total") }} </span>
            <span class="is-hidden-mobile"> {{ totalPrice }},-</span>
          </span>
          <span
            class="is-hidden-desktop mr-4"
            style="
              font-size: 24px;
              line-height: 29px;

              color: #afafaf;
            "
            :class="{ 'has-text-black': isComplete }"
            >{{ $t("new_delivery.step3.total") }}</span
          >
          <span class="technical13" :class="{ 'float-right': width < 415 }">
            <span
              class="is-hidden-desktop"
              style="font-size: 24px; line-height: 29px; text-align: right; color: #afafaf"
              :class="{ 'has-text-black': isComplete }"
              >{{ totalPrice }},-</span
            >

            {{ $t("new_delivery.step3.eks_mva") }}</span
          >
          <div v-show="isComplete" class="is-hidden-mobile dis-inline ml-3">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
                fill="#5CDB95"
              />
            </svg>
          </div>
        </div>
        <!--<div class="column is-1-desktop m-0 p-0 is-hidden-mobile">
          <div v-show="isComplete">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
                fill="#5CDB95"
              />
            </svg>
          </div>
        </div>-->
      </div>
      <hr class="mt-2 mb-4 is-hidden-mobile" />
      <hr class="mt-0 mb-4 is-hidden-desktop" />
      <div class="columns is-vcentered p-0 mb-0" style="margin-top: 50px">
        <div class="column is-9">
          <span class="technical13">{{ $t("new_delivery.step4.pickup_options_text") }}</span>
          <hr class="mt-2 mb-4" />

          <div
            class="columns m-0 mt-2 pb-2"
            v-for="(single, i) in data.pickupOptions"
            :key="i"
            @click="selectPickupOption(i)"
          >
            <div
              class="p-0 m-0 selectedHeadOptions columns is-vcentered"
              style="display: inline-flex"
              :class="{
                selectedSingle: selectedPickupOption != null && selectedPickupOption == i,
              }"
            >
              <div class="column is-2-desktop is-2-mobile p-0 ml-1 mr-2 sar-top">
                <span
                  class="technical13-bold afafaf"
                  :class="{ 'text-black': selectedPickupOption === i }"
                  >{{ $t(`new_delivery.step4.pickup_options.${single}.title`) }}</span
                >
              </div>
              <div class="column p-0">
                <span
                  class="technical13 afafaf"
                  :class="{ 'text-black': selectedPickupOption === i }"
                  >{{ $t(`new_delivery.step4.pickup_options.${single}.description`) }}
                </span>
              </div>
              <div class="column is-1-desktop is-1-mobile has-text-centered p-0 sar-top">
                <div v-show="isComplete">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
                      :fill="selectedPickupOption == i ? '#5CDB95' : '#D6D6D6'"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="columns m-0 mt-5 is-hidden-mobile">
            <span class="technical13">{{ $t("new_delivery.step4.comment") }}</span>
          </div>
          <div class="columns m-0 mt-2 is-hidden-mobile">
            <div class="field">
              <div class="control">
                <textarea
                  class="textarea is-small"
                  style="min-height: 2em"
                  v-model="pickupComment"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div
          class="column is-3"
          v-if="this.data.pickupOptions[this.selectedPickupOption] === 'pickup'"
        >
          <span class="technical13">{{ $t("new_delivery.step4.pickup_date") }}</span>
          <hr class="mt-2 mb-4" />
          <div
            class="columns m-0 mt-2 pb-2"
            v-for="(single, j) in data.pickupDate"
            :key="j"
            @click="selectPickupDate(j)"
          >
            <div
              class="p-0 m-0 selectedHeadOptions selpickDate is-mobile columns is-vcentered"
              style="display: inline-flex"
              :class="{
                selectedSingle: selectedPickupDate != null && selectedPickupDate == j,
              }"
            >
              <div class="column is-10 p-0 ml-1">
                <span class="technical13-bold">{{ formatDate(single) }}</span>
                <br />
                <span class="technical13" style="font-size: 14px">{{
                  $t("new_delivery.step4.pickupDateDesc")
                }}</span>
              </div>

              <div class="column is-2 has-text-centered p-0">
                <div>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
                      :fill="selectedPickupDate == j ? '#5CDB95' : '#D6D6D6'"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            class="p-0 m-0 selectedHeadOptions selpickDate is-mobile columns is-vcentered mt-3 selectedSingle"
            style="display: inline-flex"
            v-if="fromCal"
          >
            <div class="column is-10 p-0 ml-1">
              <span class="technical13-bold">{{ formatDate(this.selectedDate) }}</span>
              <br />
              <span class="technical13" style="font-size: 14px">{{
                $t("new_delivery.step4.pickupDateDesc")
              }}</span>
            </div>

            <button class="edit-close-cal" aria-label="close" @click="resetCalendarDate()">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          class="column is-3"
          v-if="this.data.pickupOptions[this.selectedPickupOption] === 'hand-in'"
        >
          <span class="technical13">{{ $t("new_delivery.step4.pickup_date") }}</span>
          <hr class="mt-2 mb-4" />
          <div
            class="columns m-0 mt-2"
            v-for="(single, j) in data.pickupDate"
            :key="j"
            @click="selectPickupDate(j)"
            :class="{
              selectedSingle: selectedPickupDate != null && selectedPickupDate == j,
            }"
          >
            <div
              class="p-0 m-0 selectedHeadOptions selpickDate is-mobile columns is-vcentered"
              style="display: inline-flex"
            >
              <div class="column is-10 p-0 ml-1">
                <span class="technical13-bold">{{ formatDate(single) }}</span>
                <br />
                <span class="technical13" style="font-size: 14px">{{
                  $t("new_delivery.step4.pickupDateDesc")
                }}</span>
              </div>

              <div class="column is-2 has-text-centered p-0">
                <div>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25ZM7.98047 13.8906C7.76953 14.1016 7.38281 14.1016 7.17188 13.8906L3.51562 10.2344C3.30469 10.0234 3.30469 9.63672 3.51562 9.42578L4.32422 8.65234C4.53516 8.40625 4.88672 8.40625 5.09766 8.65234L7.59375 11.1133L12.8672 5.83984C13.0781 5.59375 13.4297 5.59375 13.6406 5.83984L14.4492 6.61328C14.6602 6.82422 14.6602 7.21094 14.4492 7.42188L7.98047 13.8906Z"
                      :fill="selectedPickupDate == j ? '#5CDB95' : '#D6D6D6'"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            class="p-0 m-0 selectedHeadOptions selpickDate is-mobile columns is-vcentered mt-3 selectedSingle"
            style="display: inline-flex"
            v-if="fromCal"
          >
            <div class="column is-10 p-0 ml-1">
              <span class="technical13-bold">{{ formatDate(this.selectedDate) }}</span>
              <br />
              <span class="technical13" style="font-size: 14px">{{
                $t("new_delivery.step4.pickupDateDesc")
              }}</span>
            </div>

            <button class="edit-close-cal" aria-label="close" @click="resetCalendarDate()">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </div>
          <!---<span class="technical13">{{ $t("new_delivery.step4.hand_in_locations") }}</span>
          <hr class="mt-2 mb-4" />
          <HandInMap />-->
        </div>
      </div>
      <div class="columns">
        <div class="column is-7-desktop is-hidden-mobile">
          <input type="checkbox" class="sq-25" />
          <span class="save-default-text ml-2">{{ $t("new_delivery.step4.save_default") }}</span>
        </div>
        <div
          v-if="
            this.data.pickupOptions[this.selectedPickupOption] === 'pickup' ||
            this.data.pickupOptions[this.selectedPickupOption] === 'hand-in'
          "
          v-show="!fromCal"
          class="column is-5-desktop is-12-mobile has-text-right"
          @click="openCal()"
        >
          <span class="sidebaricon">
            <img
              src="@/assets/icons/PNG/calendar-green.png"
              class="s2-control-img"
              alt="Save as template"
            /> </span
          ><span class="technical18-bold is-mosque-blue">{{
            $t("new_delivery.step4.view_calendar")
          }}</span>
        </div>
      </div>
      <div class="columns m-0 mt-5 is-hidden-desktop">
        <span class="technical13">{{ $t("new_delivery.step4.comment") }}</span>
      </div>
      <div class="columns m-0 mt-2 is-hidden-desktop mb-4">
        <div class="field mb-4">
          <div class="control">
            <textarea
              class="textarea is-small"
              style="min-height: 2em"
              v-model="pickupComment"
            ></textarea>
            {{ pickupComment }}
          </div>
        </div>
      </div>
      <div class="is-hidden-desktop">
        <input type="checkbox" class="sq-25" />
        <span class="save-default-text ml-2">{{ $t("new_delivery.step4.save_default") }}</span>
      </div>
    </div>
  </div>
  <Calendar
    :show="showCalendar"
    :company="selected?.selected?.company"
    @selectedDate="setSelectedDate"
    @close="showCalendar = false"
  />
</template>
<script>
import dayjs from "dayjs";
import nimberLogo from "@/assets/images/deliveries/logista.png";
import postnordLogo from "@/assets/images/deliveries/postnord.png";
import bringLogo from "@/assets/images/deliveries/bring.png";
import emptyLogo from "@/assets/images/deliveries/empty.png";
import helperLogo from "@/assets/images/extra/helper.png";
import returnLogo from "@/assets/images/extra/return.png";
import disposalLogo from "@/assets/images/extra/disposal.png";
import installationLogo from "@/assets/images/extra/installation.png";
import Calendar from "@/components/newtask/Step4Calendar.vue";
//import HandInMap from "@/components/newtask/HandInMap.vue";
require("dayjs/locale/nb");
export default {
  name: "StepFour",
  props: ["active"],
  emits: ["evaluated"],
  components: { Calendar },
  data() {
    return {
      selected: [],
      selecteds4: {
        option: "",
        date: "",
        pickupComment: "",
      },
      screen: 0,
      pickupComment: "",
      width: "",
      basePrice: 0,
      extraPrice: 0,
      totalPrice: 0,
      fromCal: false,
      showCalendar: false,
      isComplete: true,
      selectedDate: "",
      selectedPickupOption: null,
      selectedPickupDate: null,
      logoImg: {
        nimber: nimberLogo,
        postnord: postnordLogo,
        bring: bringLogo,
      },
      extraLogoImg: {
        helper: helperLogo,
        disposal: disposalLogo,
        return: returnLogo,
        installation: installationLogo,
      },
      data: {
        pickupOptions: ["hand-in", "pickup", "next-delivery"],
        pickupDate: [
          "Friday 21.01",
          "Friday 21.01",
          "Friday 21.01",
          "Friday 21.01",
          "Friday 21.01",
        ],
      },
    };
  },
  methods: {
    rename(value) {
      return value === "nimber" ? "logista" : value;
    },
    openCal() {
      this.showCalendar = true;
      this.selectPickupDate(this.selectedPickupDate);
    },
    resetCalendarDate() {
      this.fromCal = false;
      this.selectedDate = "";
    },
    extraLogo(value) {
      return this.extraLogoImg[value] ? this.extraLogoImg[value] : emptyLogo;
    },
    setSelectedDate(val) {
      this.fromCal = true;
      this.selectedDate = dayjs(val).format("YYYY/MM/DD");
      this.selecteds4.date = this.selectedDate;
      this.selecteds4.pickupComment = this.pickupComment;
      this.$store.commit("addStep4", this.selecteds4);
      this.$emit("evaluated", { step: 4, valid: true });
    },
    serviceLogo(value) {
      return this.logoImg[value] ? this.logoImg[value] : emptyLogo;
    },
    getStep3() {
      this.selected = this.$store.state.step3;
      this.basePrice = parseInt(this.selected.selected.price);
      this.extraPrice = parseInt(this.selected.extraPrice);
      this.calculatePrice();
    },
    calculatePrice() {
      this.totalPrice = this.basePrice + this.extraPrice;
    },
    selectPickupOption(i) {
      if (this.selected?.selected?.company == "bring") {
        this.selectedPickupOption = this.selectedPickupOption == i ? null : i;
        this.selecteds4.option = this.data.pickupOptions[this.selectedPickupOption];
        this.selecteds4.pickupComment = this.pickupComment;
        this.$store.commit("addStep4", this.selecteds4);
      } else {
        if (i == 0) {
          this.selectedPickupOption = 1;
        } else {
          this.selectedPickupOption = this.selectedPickupOption == i ? null : i;
        }
        this.selecteds4.option = this.data.pickupOptions[this.selectedPickupOption];
        this.selecteds4.pickupComment = this.pickupComment;
        this.$store.commit("addStep4", this.selecteds4);
      }
    },
    selectPickupDate(j) {
      this.fromCal = false;
      this.selectedPickupDate = this.selectedPickupDate == j ? null : j;
      this.selecteds4.date = this.data.pickupDate[this.selectedPickupDate];
      this.selecteds4.pickupComment = this.pickupComment;
      this.$store.commit("addStep4", this.selecteds4);
    },
    selectNext() {
      this.selectedPickupDate = this.data.pickupDate[0];
      this.selecteds4.option = "next";
      this.selecteds4.date = this.data.pickupDate[0];
    },
    isMobile() {
      this.screen = window.innerWidth;
      const ophead = document.getElementById("options-header");
      if (this.screen < 769 && ophead != null) {
        ophead.classList.add("is-multiline");
      } else if (this.screen > 769 && ophead != null) {
        if (ophead.classList.contains("is-multiline")) {
          ophead.classList.remove("is-multiline");
        }
      }
    },
    getDates() {
      let loaded = localStorage.getItem("pickupDateList");
      loaded = JSON.parse(loaded);
      if (this.selected?.selected?.company === "nimber") {
        if (loaded.includes("2024/01/17")) {
          let getIndex = loaded.indexOf("2024/01/17");
          loaded.splice(getIndex, 1);
        }
      }

      this.data.pickupDate = loaded.slice(0, 5);
      //this.data.pickupDate
    },
    formatDate(val) {
      let day = "";
      if (this.$i18n.locale) {
        let tempday = dayjs(val).locale(this.$i18n.locale).format("dddd");
        day = tempday[0].toUpperCase() + tempday.substring(1);
      } else {
        day = dayjs(val).format("dddd");
      }
      let date = dayjs(val).format("DD.MM");
      return `${day} ${date}`;
    },
  },
  mounted() {
    this.isMobile();
    window.addEventListener("resize", this.isMobile);
    this.selectedPickupOption = 1;
    if (this.active) {
      this.getStep3();
      this.getDates();
      this.width = window.innerWidth;
      // default pickup option
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.isMobile);
  },
  watch: {
    active() {
      this.getStep3();
      this.getDates();
    },
    selectedPickupOption() {
      if (this.selectedPickupOption == 2) {
        this.selectNext();
        setTimeout(() => {
          this.$emit("evaluated", { step: 4, valid: true });
          this.$store.commit("addStep4", this.selecteds4);
        }, 100);
      }
      if (this.selectedPickupOption == 0) {
        //this.selectNext();
        // setTimeout(() => {
        //   this.$emit("evaluated", { step: 4, valid: true });
        //   this.$store.commit("addStep4", this.selecteds4);
        // }, 100);
      } else if (this.selectedPickupOption == 2) {
        this.selectNext();

        setTimeout(() => {
          this.$emit("evaluated", { step: 4, valid: true });
          this.$store.commit("addStep4", this.selecteds4);
        }, 100);
      } else {
        this.$emit("evaluated", { step: 4, valid: false });
      }
    },
    selectedPickupDate() {
      if (this.selectedPickupDate != null) {
        this.$emit("evaluated", { step: 4, valid: true });
      }
    },
    selectedDate() {
      if (!this.fromCal) {
        this.resetCalendarDate();
      }
    },
    pickupComment() {
      this.selecteds4.pickupComment = this.pickupComment;
      this.$store.commit("addStep4", this.selecteds4);
    },
  },
};
</script>
<style>
.selectedHead {
  background: #f8f7f5;
  border: 1px solid #f8f7f5;
  border-radius: 3px;
  padding: 0;
  height: 58px;
  /* width: fit-content !important; */
  min-width: 270px;
}
.selectedHeadExtra {
  background: #f8f7f5;
  border: 1px solid #f8f7f5;
  border-radius: 3px;
  padding: 0;
  height: 58px;
  width: fit-content !important;
  min-width: 100px;
}
.selectedHeadOptions {
  background: #ffffff;
  border: 1px solid #f8f7f5;
  border-radius: 3px;
  padding: 0;
  min-height: 58px;
}
.selectedHeads {
  padding: 0;
  height: 58px;
  width: fit-content !important;
}
.save-default-text {
  font-family: "Proxima Nova A";
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #66a5ad;
}
.selectedHeadExtras4 {
  background: #f8f7f5;
  border: 1px solid #f8f7f5;
  border-radius: 3px;
  padding: 0;
  height: 58px;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: 58px;
}
.edit-close-cal {
  background-color: rgb(255, 255, 255) !important;
  border: 0 !important;
}
@media (max-width: 414px) {
  .sar-top {
    margin-bottom: 5%;
  }
}
</style>
