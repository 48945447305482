<template>
  <LoaderComponent v-if="loading" />
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="templatemodal-wrapper">
        <div class="templatemodal-container">
          <div class="templatemodal-header pos-relative">
            <span class="headline3">{{ $t("new_delivery.step5.modal.title") }}</span>
            <button class="import-close" aria-label="close" @click="close()">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </div>
          <div class="templatemodal-body">
            <div class="body-p">
              {{ $t("new_delivery.step5.modal.body", [capitalize(rename(selectedProvider))]) }}
              <div class="field mt-5 reg-mobile-hr">
                <label class="checkbox">
                  <input type="checkbox" class="sq-25" v-model="tos" />
                </label>
                <p style="margin-top: -33px; margin-left: 43px; color: #66a5ad">
                  {{ $t("register.tos") }}
                  <a :href="tosLink" style="color: #66a5ad !important" target="_blank"
                    ><span class="underline">{{ $t("register.tos_link") }}</span></a
                  >
                  <!-- <span class="dis-inline ml-12px is-hidden-touch">
                    <ToolTip
                      vertical="top"
                      horizontal="left"
                      theme="light"
                      :text="$t('new_delivery.step5.modal.tooltip')"
                    />
                  </span>  -->
                </p>
              </div>
            </div>
          </div>
          <div class="templatemodal-footer">
            <button class="button-medium-empty-b float-left elipsis-text max40vw" @click="close()">
              <i class="pr-3 fas fa-light fa-angle-left"></i>
              {{ $t("new_delivery.leave_modal.back") }}
            </button>
            <button
              class="button-medium float-right elipsis-text max40vw"
              :disabled="!tos || isBooking"
              @click="book()"
            >
              {{ $t("new_delivery.step5.modal.send") }}
              <i class="pl-3 fas fa-light fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
//import ToolTip from "@/components/ToolTip.vue";
import notification from "@/notification.js";
import UserService from "@/services/user.service";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
  props: {
    show: Boolean,
  },
  components: { LoaderComponent },
  emits: ["close", "loading"],
  data() {
    return {
      loading: false,
      selecteds3: [],
      selectedProvider: "provider",
      selectedProduct: "",
      selectedDate: "",
      tosLink: "",
      taskId: 0,
      tos: false,
      tosData: {},
      request: {},
      isBooking: false,
    };
  },

  methods: {
    rename(value) {
      return value === "nimber" ? "logista" : value;
    },
    tosCheck() {
      let data = {
        userId: this.$store.state.userId,
        serviceName: this.selectedProvider,
      };
      UserService.checkSingleTos(data)
        .then((res) => {
          this.tos = res.data.accepted;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    close() {
      this.$emit("close");
    },
    getTos() {
      UserService.getAllTos(this.$store.state.userId).then((res) => {
        this.tosData = res.data;
        for (let single of this.tosData) {
          if (single.service == this.selectedProvider) {
            this.tosLink = single.link;
          }
        }
      });
    },
    acceptTos() {
      let data = {
        serviceName: this.selectedProvider,
        userId: this.$store.state.userId,
      };
      UserService.acceptSingleTos(data).then((res) => {
        console.log(res);
      });
    },
    book() {
      this.$emit("loading", true);
      this.isBooking = true;
      this.loading = true;
      this.selecteds3 = this.$store.state.step3;
      let base = parseFloat(this.selecteds3?.selected?.price).toFixed(0);
      let extra = parseFloat(this.selecteds3?.extraPrice).toFixed(0);
      let totalPrice = parseInt(base) + parseInt(extra);
      let selectedExtra = this.selecteds3.selectedExtra;

      this.acceptTos();
      this.request.provider = this.selectedProvider;
      this.request.nimberProduct = this.selectedProduct;
      this.request.pickupDate = this.selectedDate;
      this.request.taskId = this.taskId;
      this.request.extra = selectedExtra;
      this.request.totalPrice = totalPrice;
      this.request.pickupComment = this.$store.state.step4.pickupComment;

      UserService.bookTask(this.request).then(
        (res) => {
          if (res.data.labelsUrl) {
            let response = {
              label: res.data.labelsUrl,
              taskId: res.data.taskId,
              service: res.data.service,
              referenceNumber: res.data.referenceNumber,
            };
            this.$store.commit("addStep5", response);
            notification.toast("success", this.$t("new_delivery.step5.success"));
            setTimeout(() => {
              this.$router.push("/dashboard/booked/");
            }, 300);
          } else if (this.selectedProvider == "postnord" && res.data.error) {
            let errRes = "";
            for (let i = 0; i < res.data.error.length; i++) {
              errRes += res.data.error[i].message + " ";
            }

            notification.toast("error", errRes);
          } else if (this.selectedProvider == "bring" && res.data.error) {
            let errResBring = "";
            for (let i = 0; i < res.data.error.length; i++) {
              errResBring += res.data.error[i][0].message + " ";
            }
            notification.toast("error", errResBring);
          } else {
            notification.toast("error", this.$t("forgot_password.problem"));
          }
          this.$emit("loading", false);
          this.loading = false;
          this.isBooking = false;
        },
        (error) => {
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          this.loading = false;
          notification.toast("error", this.$t("forgot_password.problem"));
          this.loading = false;
          this.isBooking = false;
        }
      );
      // send to booking api

      // pass parameters of the task when success to display the label link
      //
      //send provider
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
  },
  mounted() {
    if (this.$store.state.step3.selected?.company) {
      this.selectedProvider = this.$store.state.step3.selected?.company;
      this.selectedProduct = this.$store.state.step3.selected?.service;
    }
    this.selectedDate = this.$store.state.step4.date;

    this.taskId = localStorage.getItem("taskId");
  },
  watch: {
    show() {
      if (this.$store.state.step3.selected?.company) {
        this.selectedProvider = this.$store.state.step3.selected?.company;
        this.selectedProduct = this.$store.state.step3.selected?.service;
        this.getTos();
        this.tosCheck();
      }
      this.selectedDate = this.$store.state.step4.date;
      this.taskId = localStorage.getItem("taskId");
    },
  },
};
</script>
<style>
.templatemodal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.templatemodal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.templatemodal-container {
  width: 624px;
  min-height: 335px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 4px;
  max-width: 100vw;
}
.templatemodal-header {
  border-bottom: 1px #d6d6d6 solid;
  height: 90px;
  padding: 30px 40px;
}
.templatemodal-footer {
  border-top: 1px #d6d6d6 solid;
  padding: 18px;
}
.templatemodal-body {
  padding: 30px 40px;
  max-height: 162px;
  overflow-x: auto;
}
.modal-default-button {
  float: right;
}
.modal-enter-from {
  opacity: 0;
}
.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.import-close {
  position: absolute !important;
  right: 30px;
  background: none;
  border: none;
  top: calc(50% - 6px);
}
</style>
