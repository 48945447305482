<template>
  <div v-if="show" :class="`banner-${type}`">
    <div class="banner-icon" :class="{ step1pos: place === 'step1' }">
      <i class="fa fa-solid fa-info" v-if="type === 'info'"></i>
      <i class="fa fa-solid fa-info-circle" v-if="type === 'warning'"></i>
      <i class="fa-solid fa-exclamation" v-if="type === 'error'"></i>
    </div>
    <div class="banner-text" v-html="text"></div>
    <div
      class="banner-close"
      :class="{ step1pos: place === 'step1' }"
      @click="$emit('close')"
    >
      <img
        src="@/assets/icons/PNG/times-black.png"
        class="v-align-middle"
        alt="Close"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "BannerComponent",
  props: ["show", "type", "text", "place"],
  emits: ["close"],
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.fa-exclamation {
  background-color: #ff2d55 !important;
  width: 1.5em;
  border-radius: 3px;
  height: 1.5em;
  padding-top: 4px;
  color: white !important;
  font-weight: bold;
  text-align: center;
  font-size: 75%;
}
.banner-close {
  color: #afafaf !important;
}
@media (max-width: 414px) {
  .step1pos {
    margin-bottom: 20%;
  }
}
</style>
