<template>
  <Transition name="modal">
    <div class="modal-mask">
      <div class="leavemodal-wrapper">
        <div class="leavemodal-container">
          <div class="leavemodal-header pos-relative">
            <span class="headline3">{{
              $t("new_delivery.leave_modal.header")
            }}</span>
            <button
              class="import-close"
              aria-label="close"
              @click="closeDialog(false)"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </button>
          </div>

          <div class="leavemodal-body">
            <p class="body-p">
              {{ $t("new_delivery.leave_modal.body") }}
            </p>
          </div>

          <div class="leavemodal-footer">
            <button class="button-medium-empty-b" @click="closeDialog(false)">
              <img
                src="@/assets/icons/PNG/chevron-left.png"
                class="leavemodal-btn-img"
                alt="Close"
              />

              {{ $t("new_delivery.leave_modal.back") }}
            </button>
            <button
              class="button-medium float-right"
              @click="closeDialog(true)"
            >
              <img
                src="@/assets/icons/PNG/times.png"
                class="leavemodal-btn-img"
                alt="Close"
              />
              {{ $t("new_delivery.leave_modal.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import { closeDialog } from "vue3-promise-dialog";

export default {
  props: {},
  data() {
    return {
      leave: false,
      closeDialog,
    };
  },
  methods: {},
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.leavemodal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.leavemodal-container {
  width: 624px;
  min-height: 263px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 4px;
}
.leavemodal-header {
  border-bottom: 1px #d6d6d6 solid;
  height: 90px;
  padding: 30px 40px;
}

.leavemodal-body {
  padding: 30px 40px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.leavemodal-footer {
  border-top: 1px #d6d6d6 solid;
  padding: 18px;
}
.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.import-close {
  position: absolute !important;
  right: 30px;
  background: none;
  border: none;
  top: calc(50% - 6px);
}
</style>
