<template>
  <Transition name="slidein">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="kolli-container addKolliModal">
          <div class="kolli-modal-header">
            <h1 class="welcome-modal-title"></h1>
            <button class="delete kolli-close" aria-label="close" @click="$emit('close')"></button>
          </div>

          <div class="kolli-modal-body">
            <h2 class="title is-size-3">Opprett kolli</h2>
            <div class="field mt-xsmall">
              <label class="label">{{ $t("create.placeholders.length") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="kolli.length"
                  :placeholder="$t('create.placeholders.length')"
                  :class="{ redBorder: v$.kolli.length.$error }"
                  @blur="v$.kolli.length.$touch"
                />
              </div>
              <span v-if="v$.kolli.length.$error" class="field-icon">
                <i class="fas fa-exclamation-circle is-red"></i>
              </span>
              <p class="help">
                <span v-if="v$.kolli.length.$error">{{ $t("required_field") }}</span>
              </p>
            </div>

            <div class="field mt-xsmall">
              <label class="label">{{ $t("create.placeholders.height") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="kolli.height"
                  :placeholder="$t('create.placeholders.height')"
                  :class="{ redBorder: v$.kolli.height.$error }"
                  @blur="v$.kolli.height.$touch"
                />
              </div>
              <span v-if="v$.kolli.height.$error" class="field-icon">
                <i class="fas fa-exclamation-circle is-red"></i>
              </span>
              <p class="help">
                <span v-if="v$.kolli.height.$error">{{ $t("required_field") }}</span>
              </p>
            </div>

            <div class="field mt-xsmall">
              <label class="label">{{ $t("create.placeholders.width") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="kolli.width"
                  :placeholder="$t('create.placeholders.width')"
                  :class="{ redBorder: v$.kolli.width.$error }"
                  @blur="v$.kolli.width.$touch"
                />
              </div>
              <span v-if="v$.kolli.width.$error" class="field-icon">
                <i class="fas fa-exclamation-circle is-red"></i>
              </span>
              <p class="help">
                <span v-if="v$.kolli.width.$error">{{ $t("required_field") }}</span>
              </p>
            </div>

            <div class="field mt-xsmall">
              <label class="label">{{ $t("create.placeholders.weight") }}</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="kolli.weight"
                  :placeholder="$t('create.placeholders.weight')"
                  :class="{ redBorder: v$.kolli.weight.$error }"
                  @blur="v$.kolli.weight.$touch"
                />
              </div>
              <span v-if="v$.kolli.weight.$error" class="field-icon">
                <i class="fas fa-exclamation-circle is-red"></i>
              </span>
              <p class="help">
                <span v-if="v$.kolli.weight.$error">{{ $t("required_field") }}</span>
              </p>
            </div>

            <button
              class="button is-primary is-medium has-sherpa"
              id="single-delivery-step-2"
              @click="$emit('close')"
            >
              {{ $t("addressbook.add") }}
            </button>
          </div>

          <div class="welcome-modal-footer"></div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  props: {
    show: Boolean,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      kolli: {},
      data_kollis: [],
    };
  },
  validations() {
    return {
      kolli: {
        length: {
          required,
        },
        height: {
          required,
        },
        weight: {
          required,
        },
        width: {
          required,
        },
      },
    };
  },
  methods: {},
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.kolli-close {
  position: absolute !important;
  top: 40px;
  background-color: rgba(16, 16, 17, 0.8);
}
.modal-container {
  max-width: 960px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.slidein-enter-from {
  opacity: 0;
}

.slidein-leave-to {
  opacity: 0;
}

.slidein-enter-from .kolli-container {
  transform: translate(400px, 0);
}

.slidein-leave-to .kolli-container {
  transform: translate(400px, 0);
}

.slidein-enter-from,
.slidein-leave-to {
  transition: 1s !important;
}
.addKolliModal {
  background-color: white;
  border-radius: 0px !important;
  width: 320px !important;
}
.kolli-modal-header {
  height: 100px;
  border-bottom: 0px #d6d6d6 solid !important;
  padding: 40px 40px;
  position: relative !important;
}
.kolli-close {
  position: absolute;
  top: 40px;
  background-color: rgba(15, 15, 17, 0.8) !important;
}
.kolli-modal-body {
  padding: 20px 40px 0;
}
.button-bottom-welcome-modal {
  width: 113px !important;
  height: 43px !important;
  background-color: #003b46 !important;
  color: white !important;
  border-radius: 3px !important;
  padding: 16px 24px !important;
  font-family: "Proxima Nova A Semibold" !important;
  font-size: 16px !important;
  line-height: auto !important;
  letter-spacing: 0% !important;
  border: 0 !important;
}
.welcome-modal-footer {
  height: 83px;
  border-top: 0px #d6d6d6 solid !important;
  padding: 20px 64px;
}
.welcome-modal-image {
  max-width: 948px;
  min-width: calc(100% - 128px);
  height: 229px;
  background-color: #003b46 !important;
  margin-top: 47px;
}
.kolli-container {
  width: 400px !important;
  background-color: #fff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
  transition: all 0.3s ease;
  height: 100vh;
  margin-left: auto !important;
}
</style>
