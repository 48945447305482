<template>
  <div class="columns is-mobile is-hidden-mobile mb--15">
    <div id="progressContainer" class="column pos-relative">
      <button @click="emitStep(1)" class="deliveryNav deliveryNavActive"></button>
      <div id="orderProgressBar1" :class="{ orderProgressActive: step >= 2 }"></div>
      <div class="progressBarText technical13">
        {{ $t("new_delivery.progressbar.addressbook") }}
      </div>
    </div>
    <div id="progressContainer2" class="column pos-relative">
      <button
        @click="emitStep(2)"
        :disabled="!valid1"
        class="deliveryNav"
        :class="{ deliveryNavActive: step >= 3 }"
      ></button>
      <div id="orderProgressBar2" :class="{ orderProgressActive: step >= 3 }"></div>
      <div class="progressBarText technical13" :class="{ 'is-fountain-blue': !valid1 }">
        {{ $t("new_delivery.progressbar.items") }}
      </div>
    </div>
    <div id="progressContainer3" class="column pos-relative">
      <button
        @click="emitStep(3)"
        :disabled="!valid2"
        class="deliveryNav"
        :class="{ deliveryNavActive: step >= 4 }"
      ></button>
      <div id="orderProgressBar3" :class="{ orderProgressActive: step >= 4 }"></div>
      <div class="progressBarText technical13" :class="{ 'is-fountain-blue': !valid2 }">
        {{ $t("new_delivery.progressbar.services") }}
      </div>
    </div>
    <div id="progressContainer4" class="column pos-relative">
      <button
        @click="emitStep(4)"
        :disabled="!valid3"
        class="deliveryNav"
        :class="{ deliveryNavActive: step >= 5 }"
      ></button>
      <div id="orderProgressBar4" :class="{ orderProgressActive: step === 5 }"></div>
      <div class="progressBarText technical13" :class="{ 'is-fountain-blue': !valid3 }">
        {{ $t("new_delivery.progressbar.options") }}
      </div>
    </div>
    <div class="column pos-relative">
      <button :disabled="!valid3" class="deliveryNav"></button>
      <div class="progressBarText technical13" :class="{ 'is-fountain-blue': !valid4 }">
        {{ $t("new_delivery.progressbar.review") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",
  props: ["step", "valid1", "valid2", "valid3", "valid4"],
  emits: ["emitStep"],
  components: {},
  data() {
    return {};
  },
  methods: {
    emitStep(value) {
      this.$emit("emitStep", value);
    },
    getWidthProgress() {
      if (document.getElementById("progressContainer")) {
        let padding = window.getComputedStyle(
          document.getElementById("progressContainer"),
          null
        ).padding;
        const cont = document.getElementById("progressContainer").offsetWidth;
        const cont2 = document.getElementById("progressContainer2").offsetWidth;
        const cont3 = document.getElementById("progressContainer3").offsetWidth;
        const cont4 = document.getElementById("progressContainer4").offsetWidth;
        const prog1 = document.getElementById("orderProgressBar1");
        prog1.setAttribute(
          "style",
          `width: calc(${cont}px); left: calc(${cont / 2}px + calc(${padding} / 2));`
        );
        const prog2 = document.getElementById("orderProgressBar2");
        prog2.setAttribute(
          "style",
          `width: calc(${cont2}px ); left: calc(${cont / 2}px - calc(${padding} / 2) );`
        );
        const prog3 = document.getElementById("orderProgressBar3");
        prog3.setAttribute(
          "style",
          `width: calc(${cont3}px); left: calc(${cont / 2}px - calc(${padding} / 2) );`
        );
        const prog4 = document.getElementById("orderProgressBar4");
        prog4.setAttribute(
          "style",
          `width: calc(${cont4}px); left: calc(${cont / 2}px - calc(${padding} / 2) );`
        );
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          prog1.setAttribute(
            "style",
            `top: calc(50% - 9px); width: calc(${cont}px); left: calc(${
              cont / 2
            }px + calc(${padding} / 2));`
          );
          prog2.setAttribute(
            "style",
            `top: calc(50% - 9px); width: calc(${cont2}px ); left: calc(${
              cont / 2
            }px - calc(${padding} / 2) );`
          );
          prog3.setAttribute(
            "style",
            `top: calc(50% - 9px); width: calc(${cont3}px); left: calc(${
              cont / 2
            }px - calc(${padding} / 2) );`
          );
          prog4.setAttribute(
            "style",
            `top: calc(50% - 9px); width: calc(${cont4}px); left: calc(${
              cont / 2
            }px - calc(${padding} / 2) );`
          );
        }
      }
    },
  },
  created() {
    //
  },
  mounted() {
    setTimeout(() => {
      this.getWidthProgress();
    }, 100);
    window.addEventListener("resize", this.getWidthProgress);
  },
};
</script>
<style></style>
