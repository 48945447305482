import { useToast } from "vue-toastification";

const timeout = 5000;
const position = "top-center";
const toast = useToast();
const notification = {
  toast(type, value) {
    switch (type) {
      case "success":
        toast.success(value, {
          timeout,
          position,
          color: "#F5F7F6",
          icon: "fas fa-check",
        });
        break;
      case "error":
        toast.error(value, {
          timeout,
          position,
          icon: "fas fa-exclamation-circle",
        });
        break;
      case "default":
        toast.default(value, {
          timeout,
          position,
          icon: "fas fa-info",
        });
        break;
      case "info":
        toast.info(value, {
          timeout,
          position,
          icon: "fas fa-info-circle",
        });
        break;
      case "warning":
        toast.warning(value, {
          timeout,
          position,
          icon: "fas fa-exclamation",
        });
        break;
      case "clear":
        toast.clear();
        break;
      default:
        toast.default(value, {
          timeout,
          position,
          icon: "fas fa-info",
        });
    }
  },
};

export default notification;
