<template>
  <LoaderComponent v-if="showLoader" @escape="showLoader = false" />

  <div class="container addresscont" :key="key">
    <h1 class="headline3 fit-content inline addressbookTitle">
      <i class="fa-solid fa-exclamatio-circle"></i>{{ $t("addressbook.title") }}
    </h1>
    <div class="fit-content inline floatr mob-full">
      <button
        class="button-large-inverted mr15 mob-half elipsis-text"
        @click="showImport = !showImport"
      >
        <span class="sidebaricon">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5018 3.44461L14.5526 0.495703C14.2362 0.179297 13.8073 0 13.3611 0H6.18715C5.25586 0.00351563 4.5 0.759375 4.5 1.69066V9.28371H0.421875C0.188789 9.28371 0 9.4725 0 9.70559V10.5493C0 10.7824 0.188789 10.9712 0.421875 10.9712H10.125V12.6896C10.125 13.0662 10.581 13.2539 10.8464 12.9867L13.3696 10.4446C13.5436 10.2691 13.5436 9.98613 13.3696 9.8107L10.8464 7.26855C10.581 7.00137 10.125 7.1891 10.125 7.56562V9.28406H6.18715V1.69066H11.8111V5.34621C11.8111 5.81379 12.1873 6.18961 12.6548 6.18961H16.3125V16.3129H6.18715V12.6587H4.5V16.3129C4.5 17.2441 5.25586 18 6.18715 18H16.31C17.2417 18 18 17.2441 18 16.3129V4.63957C18 4.19344 17.8182 3.76102 17.5018 3.44461ZM13.4982 4.50246V1.82777L16.1733 4.50246H13.4982Z"
              fill="#66A5AD"
            />
          </svg>
        </span>
        {{ $t("addressbook.import") }}
      </button>
      <button class="button-large-inverted mob-half" @click="addAddressModal()">
        <span class="sidebaricon">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4688 6.40625H7.84375V0.78125C7.84375 0.640625 7.70312 0.5 7.5625 0.5H6.4375C6.26172 0.5 6.15625 0.640625 6.15625 0.78125V6.40625H0.53125C0.355469 6.40625 0.25 6.54688 0.25 6.6875V7.8125C0.25 7.98828 0.355469 8.09375 0.53125 8.09375H6.15625V13.7188C6.15625 13.8945 6.26172 14 6.4375 14H7.5625C7.70312 14 7.84375 13.8945 7.84375 13.7188V8.09375H13.4688C13.6094 8.09375 13.75 7.98828 13.75 7.8125V6.6875C13.75 6.54688 13.6094 6.40625 13.4688 6.40625Z"
              fill="#07575B"
            />
          </svg>
        </span>
        {{ $t("addressbook.add") }}
      </button>
    </div>

    <div class="field mt37" style="margin-bottom: 27px !important">
      <BannerComponent
        :show="showBanner"
        type="info"
        :text="$t('banner.addressbook')"
        @close="showBanner = false"
      />
      <div class="control has-icons-right" v-show="!showBanner">
        <input
          class="input"
          type="text"
          ref="searchfield"
          v-model="search"
          :placeholder="$t('addressbook.searchinput')"
        />
        <span class="icon is-small is-right">
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8594 16.7383L13.6055 12.4844C13.5 12.4141 13.3945 12.3438 13.2891 12.3438H12.832C13.9219 11.0781 14.625 9.39062 14.625 7.5625C14.625 3.55469 11.3203 0.25 7.3125 0.25C3.26953 0.25 0 3.55469 0 7.5625C0 11.6055 3.26953 14.875 7.3125 14.875C9.14062 14.875 10.793 14.207 12.0938 13.1172V13.5742C12.0938 13.6797 12.1289 13.7852 12.1992 13.8906L16.4531 18.1445C16.6289 18.3203 16.9102 18.3203 17.0508 18.1445L17.8594 17.3359C18.0352 17.1953 18.0352 16.9141 17.8594 16.7383ZM7.3125 13.1875C4.18359 13.1875 1.6875 10.6914 1.6875 7.5625C1.6875 4.46875 4.18359 1.9375 7.3125 1.9375C10.4062 1.9375 12.9375 4.46875 12.9375 7.5625C12.9375 10.6914 10.4062 13.1875 7.3125 13.1875Z"
              fill="#6E6E6E"
            />
          </svg>
        </span>
      </div>
    </div>
    <div v-if="filteredAddressbook.length > 0" class="w100 m-pt-168" style="z-index: 1">
      <div class="mb17 columns is-vcentered ms-0 is-hidden-mobile">
        <div class="column is-1 is-hidden-mobile"></div>
        <div class="column pl-0 is-3 is-one-third-mobile inline-block">
          <span class="caption-text is-black">{{ $t("addressbook.list.head.name") }}</span>
        </div>
        <div class="column pl-0 is-7 is-two-thirds-mobile inline-block">
          <span class="caption-text is-black">{{ $t("addressbook.list.head.contact") }}</span>
        </div>
        <div class="column is-1 is-hidden-mobile"></div>
      </div>
      <hr style="margin: 0 0 4px 0" />
      <div
        class="single-address-result columns is-mobile is-vcentered"
        v-for="(data, i) in filteredAddressbook"
        :key="i"
        style="z-index: 1"
      >
        <div class="column is-1 p-0 sar-top" @click="showOptions = ''">
          <svg
            v-if="data.accountType == 'business'"
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="addr-ml-27"
          >
            <path
              d="M11.5008 13.75H4.75079V8.125H2.50079V17.125C2.50079 17.7578 2.99298 18.25 3.62579 18.25H12.6258C13.2234 18.25 13.7508 17.7578 13.7508 17.125V8.125H11.5008V13.75ZM22.5399 5.27734L19.5516 0.777344C19.3406 0.460938 18.9891 0.25 18.6024 0.25H4.36407C3.97735 0.25 3.62579 0.460938 3.41485 0.777344L0.426569 5.27734C-0.0656183 6.01562 0.461725 7 1.37579 7H21.6258C22.5047 7 23.032 6.01562 22.5399 5.27734ZM18.2508 17.6875C18.2508 18.0039 18.4969 18.25 18.8133 18.25H19.9383C20.2195 18.25 20.5008 18.0039 20.5008 17.6875V8.125H18.2508V17.6875Z"
              fill="#AFAFAF"
            />
          </svg>
          <svg
            v-if="data.accountType == 'individual'"
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="addr-ml-27"
          >
            <path
              d="M11.1289 10.9375C10.1094 10.9375 9.65234 11.5 8 11.5C6.3125 11.5 5.85547 10.9375 4.83594 10.9375C2.23438 10.9375 0.125 13.082 0.125 15.6836V16.5625C0.125 17.5117 0.863281 18.25 1.8125 18.25H14.1875C15.1016 18.25 15.875 17.5117 15.875 16.5625V15.6836C15.875 13.082 13.7305 10.9375 11.1289 10.9375ZM14.1875 16.5625H1.8125V15.6836C1.8125 13.9961 3.14844 12.625 4.83594 12.625C5.36328 12.625 6.17188 13.1875 8 13.1875C9.79297 13.1875 10.6016 12.625 11.1289 12.625C12.8164 12.625 14.1875 13.9961 14.1875 15.6836V16.5625ZM8 10.375C10.7773 10.375 13.0625 8.125 13.0625 5.3125C13.0625 2.53516 10.7773 0.25 8 0.25C5.1875 0.25 2.9375 2.53516 2.9375 5.3125C2.9375 8.125 5.1875 10.375 8 10.375ZM8 1.9375C9.82812 1.9375 11.375 3.48438 11.375 5.3125C11.375 7.17578 9.82812 8.6875 8 8.6875C6.13672 8.6875 4.625 7.17578 4.625 5.3125C4.625 3.48438 6.13672 1.9375 8 1.9375Z"
              fill="#AFAFAF"
            />
          </svg>
        </div>
        <div class="column is-3 is-hidden-mobile p-0" @click="showOptions = ''">
          <p class="body-text-small-title">
            {{ data.companyName.length > 0 ? data.companyName : data.fullName }}
          </p>
          <p class="caption-text">{{ data.streetName }}, {{ data.streetNumber }} {{ data.city }}</p>
        </div>
        <div
          class="column is-7-mobile is-hidden-desktop is-hidden-tablet"
          style="padding-left: 22px; line-height: 12px"
          @click="showOptions = ''"
        >
          <span class="body-text-small-title">
            {{ data.companyName.length > 0 ? data.companyName : data.fullName }}</span
          >
          <br />
          <span class="caption-text"
            >{{ data.streetName }}, {{ data.streetNumber }} {{ data.city }}</span
          >
          <br />
          <span class="body-text-small-title">{{
            data.accountType == "business" ? data.fullName : ""
          }}</span>
          <br v-if="data.accountType == 'business'" />
          <span class="caption-text">{{ data.prefix + data.mobile }}</span>
        </div>
        <div class="column is-6-desktop is-hidden-mobile p-0" @click="showOptions = ''">
          <p class="body-text-small-title">
            {{ data.accountType == "business" ? data.fullName : "" }}
          </p>
          <p class="caption-text">{{ data.prefix + data.mobile }}</p>
        </div>
        <div class="column is-2 p-0 pos-relative sar-top">
          <div class="columns is-mobile is-vcentered">
            <div class="column is-center" @click="showOptions = ''">
              <p class="">
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    v-if="data.pinned"
                    d="M4.75009 12.3807V18.5015L5.61026 19.7914C5.79581 20.0695 6.2048 20.0695 6.39035 19.7914L7.25013 18.5015V12.3807C6.84427 12.4557 6.42746 12.5002 6.00011 12.5002C5.57276 12.5002 5.15595 12.4557 4.75009 12.3807ZM6.00011 0C2.89341 0 0.375 2.51841 0.375 5.62511C0.375 8.73181 2.89341 11.2502 6.00011 11.2502C9.10681 11.2502 11.6252 8.73181 11.6252 5.62511C11.6252 2.51841 9.10681 0 6.00011 0ZM6.00011 2.96881C4.53524 2.96881 3.34381 4.16024 3.34381 5.62511C3.34381 5.88371 3.13365 6.09387 2.87505 6.09387C2.61645 6.09387 2.40629 5.88371 2.40629 5.62511C2.40629 3.64343 4.01882 2.03129 6.00011 2.03129C6.25871 2.03129 6.46887 2.24145 6.46887 2.50005C6.46887 2.75865 6.25871 2.96881 6.00011 2.96881Z"
                    fill="#5CDB95"
                  />
                  <path
                    v-else
                    d="M4.75009 12.3807V18.5015L5.61026 19.7914C5.79581 20.0695 6.2048 20.0695 6.39035 19.7914L7.25013 18.5015V12.3807C6.84427 12.4557 6.42746 12.5002 6.00011 12.5002C5.57276 12.5002 5.15595 12.4557 4.75009 12.3807ZM6.00011 0C2.89341 0 0.375 2.51841 0.375 5.62511C0.375 8.73181 2.89341 11.2502 6.00011 11.2502C9.10681 11.2502 11.6252 8.73181 11.6252 5.62511C11.6252 2.51841 9.10681 0 6.00011 0ZM6.00011 2.96881C4.53524 2.96881 3.34381 4.16024 3.34381 5.62511C3.34381 5.88371 3.13365 6.09387 2.87505 6.09387C2.61645 6.09387 2.40629 5.88371 2.40629 5.62511C2.40629 3.64343 4.01882 2.03129 6.00011 2.03129C6.25871 2.03129 6.46887 2.24145 6.46887 2.50005C6.46887 2.75865 6.25871 2.96881 6.00011 2.96881Z"
                    fill="#D6D6D6"
                  />
                </svg>
              </p>
            </div>
            <div class="column is-center" @click="showOptions = ''">
              <p class="">
                <svg
                  width="21"
                  height="17"
                  viewBox="0 0 21 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    v-if="data.note?.length > 0"
                    d="M15.0001 6C15.0001 2.90625 11.6954 0.375 7.68762 0.375C3.64465 0.375 0.37512 2.90625 0.37512 6C0.37512 7.23047 0.867308 8.32031 1.71106 9.23438C1.21887 10.3242 0.445433 11.168 0.445433 11.168C0.37512 11.2383 0.339964 11.3789 0.37512 11.4844C0.445433 11.5898 0.515745 11.625 0.65637 11.625C1.922 11.625 2.97668 11.2031 3.75012 10.7461C4.87512 11.3086 6.24621 11.625 7.68762 11.625C11.6954 11.625 15.0001 9.12891 15.0001 6ZM19.2892 13.7344C20.0978 12.8203 20.6251 11.7305 20.6251 10.5C20.6251 8.17969 18.7267 6.14062 16.0548 5.29688C16.09 5.54297 16.1251 5.78906 16.1251 6C16.1251 9.72656 12.3282 12.75 7.68762 12.75C7.3009 12.75 6.91418 12.75 6.56262 12.7148C7.65246 14.7188 10.254 16.125 13.3126 16.125C14.754 16.125 16.09 15.8086 17.215 15.2461C17.9884 15.7031 19.0431 16.125 20.3439 16.125C20.4493 16.125 20.5548 16.0898 20.59 15.9844C20.6251 15.8789 20.6251 15.7383 20.5197 15.668C20.5197 15.668 19.7462 14.8242 19.2892 13.7344Z"
                    fill="#5CDB95"
                  />
                  <path
                    v-else
                    d="M15.0001 6C15.0001 2.90625 11.6954 0.375 7.68762 0.375C3.64465 0.375 0.37512 2.90625 0.37512 6C0.37512 7.23047 0.867308 8.32031 1.71106 9.23438C1.21887 10.3242 0.445433 11.168 0.445433 11.168C0.37512 11.2383 0.339964 11.3789 0.37512 11.4844C0.445433 11.5898 0.515745 11.625 0.65637 11.625C1.922 11.625 2.97668 11.2031 3.75012 10.7461C4.87512 11.3086 6.24621 11.625 7.68762 11.625C11.6954 11.625 15.0001 9.12891 15.0001 6ZM19.2892 13.7344C20.0978 12.8203 20.6251 11.7305 20.6251 10.5C20.6251 8.17969 18.7267 6.14062 16.0548 5.29688C16.09 5.54297 16.1251 5.78906 16.1251 6C16.1251 9.72656 12.3282 12.75 7.68762 12.75C7.3009 12.75 6.91418 12.75 6.56262 12.7148C7.65246 14.7188 10.254 16.125 13.3126 16.125C14.754 16.125 16.09 15.8086 17.215 15.2461C17.9884 15.7031 19.0431 16.125 20.3439 16.125C20.4493 16.125 20.5548 16.0898 20.59 15.9844C20.6251 15.8789 20.6251 15.7383 20.5197 15.668C20.5197 15.668 19.7462 14.8242 19.2892 13.7344Z"
                    fill="#D6D6D6"
                  />
                </svg>
              </p>
            </div>
            <div class="column is-center" @click="showSingleAddressOptions(data.id)">
              <p name="showMenu">
                <svg
                  width="3"
                  height="13"
                  viewBox="0 0 3 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="3" height="13" fill="#E5E5E5" />
                  <path
                    d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z"
                    fill="#D6D6D6"
                  />
                  <path
                    d="M3 6.5C3 7.32843 2.32843 8 1.5 8C0.671573 8 0 7.32843 0 6.5C0 5.67157 0.671573 5 1.5 5C2.32843 5 3 5.67157 3 6.5Z"
                    fill="#D6D6D6"
                  />
                  <path
                    d="M3 11.5C3 12.3284 2.32843 13 1.5 13C0.671573 13 0 12.3284 0 11.5C0 10.6716 0.671573 10 1.5 10C2.32843 10 3 10.6716 3 11.5Z"
                    fill="#D6D6D6"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div v-if="showOptions == data.id" class="boxOptions">
          <button class="boxOptionsBtn" @click="editAddressbook(data.id)">
            <span class="sidebaricon">
              <svg
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5078 3.2041C14.3672 3.06348 14.1562 3.06348 14.0156 3.2041L6.35156 10.8682L6 14.1377C5.92969 14.5596 6.31641 14.9463 6.73828 14.876L10.0078 14.5244L17.6719 6.86035C17.8125 6.71973 17.8125 6.50879 17.6719 6.36816L14.5078 3.2041ZM20.2031 2.39551L18.4805 0.672852C17.9531 0.145508 17.0742 0.145508 16.5469 0.672852L15.3164 1.90332C15.1758 2.04395 15.1758 2.25488 15.3164 2.39551L18.4805 5.55957C18.6211 5.7002 18.832 5.7002 18.9727 5.55957L20.2031 4.3291C20.7305 3.80176 20.7305 2.92285 20.2031 2.39551ZM13.875 12.4502V16.001H2.625V4.75098H10.6758C10.8164 4.75098 10.9219 4.71582 10.9922 4.64551L12.3984 3.23926C12.6445 2.95801 12.4688 2.50098 12.082 2.50098H2.0625C1.11328 2.50098 0.375 3.27441 0.375 4.18848V16.5635C0.375 17.5127 1.11328 18.251 2.0625 18.251H14.4375C15.3516 18.251 16.125 17.5127 16.125 16.5635V11.0439C16.125 10.6572 15.668 10.4814 15.3867 10.7275L13.9805 12.1338C13.9102 12.2041 13.875 12.3096 13.875 12.4502Z"
                  fill="#AFAFAF"
                />
              </svg>
            </span>
            {{ $t("addressbook.edit") }}
          </button>
          <button class="boxOptionsBtn" @click="pinAddress(data.id, data.pinned)">
            <span v-if="data.pinned">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.75011 13.3807V19.5015L10.6103 20.7914C10.7958 21.0695 11.2048 21.0695 11.3904 20.7914L12.2502 19.5015V13.3807C11.8443 13.4557 11.4275 13.5002 11.0001 13.5002C10.5728 13.5002 10.156 13.4557 9.75011 13.3807ZM11.0001 0.999994C7.89343 0.999994 5.37502 3.5184 5.37502 6.6251C5.37502 9.73181 7.89343 12.2502 11.0001 12.2502C14.1068 12.2502 16.6252 9.73181 16.6252 6.6251C16.6252 3.5184 14.1068 0.999994 11.0001 0.999994ZM11.0001 3.9688C9.53526 3.9688 8.34383 5.16023 8.34383 6.6251C8.34383 6.8837 8.13367 7.09386 7.87507 7.09386C7.61647 7.09386 7.40631 6.8837 7.40631 6.6251C7.40631 4.64343 9.01885 3.03128 11.0001 3.03128C11.2587 3.03128 11.4689 3.24144 11.4689 3.50004C11.4689 3.75864 11.2587 3.9688 11.0001 3.9688Z"
                  fill="#AFAFAF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.7072 0.293303C22.0977 0.683822 22.0977 1.31699 21.7072 1.70752L1.91866 21.4966C1.52815 21.8871 0.89498 21.8871 0.504451 21.4966C0.113921 21.1061 0.113912 20.4729 0.504431 20.0824L20.293 0.293322C20.6835 -0.0972072 21.3167 -0.0972157 21.7072 0.293303Z"
                  fill="#AFAFAF"
                />
              </svg>

              {{ $t("addressbook.unpin") }}
            </span>
            <span v-else>
              <span class="sidebaricon">
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.75009 12.3807V18.5015L5.61026 19.7914C5.79581 20.0695 6.2048 20.0695 6.39035 19.7914L7.25013 18.5015V12.3807C6.84427 12.4557 6.42746 12.5002 6.00011 12.5002C5.57276 12.5002 5.15595 12.4557 4.75009 12.3807ZM6.00011 0C2.89341 0 0.375 2.51841 0.375 5.62511C0.375 8.73181 2.89341 11.2502 6.00011 11.2502C9.10681 11.2502 11.6252 8.73181 11.6252 5.62511C11.6252 2.51841 9.10681 0 6.00011 0ZM6.00011 2.96881C4.53524 2.96881 3.34381 4.16024 3.34381 5.62511C3.34381 5.88371 3.13365 6.09387 2.87505 6.09387C2.61645 6.09387 2.40629 5.88371 2.40629 5.62511C2.40629 3.64343 4.01882 2.03129 6.00011 2.03129C6.25871 2.03129 6.46887 2.24145 6.46887 2.50005C6.46887 2.75865 6.25871 2.96881 6.00011 2.96881Z"
                    fill="#AFAFAF"
                  />
                </svg>
              </span>
              {{ $t("addressbook.pin") }}
            </span>
          </button>
          <button class="boxOptionsBtn" @click="deleteAddress(data.id)">
            <span class="sidebaricon">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16113 6.25098L10.7822 2.66504L11.5205 1.92676C11.626 1.82129 11.626 1.64551 11.5205 1.50488L10.7471 0.731445C10.6064 0.625977 10.4307 0.625977 10.3252 0.731445L6.00098 5.09082L1.6416 0.731445C1.53613 0.625977 1.36035 0.625977 1.21973 0.731445L0.446289 1.50488C0.34082 1.64551 0.34082 1.82129 0.446289 1.92676L4.80566 6.25098L0.446289 10.6104C0.34082 10.7158 0.34082 10.8916 0.446289 11.0322L1.21973 11.8057C1.36035 11.9111 1.53613 11.9111 1.6416 11.8057L6.00098 7.44629L9.58691 11.0674L10.3252 11.8057C10.4307 11.9111 10.6064 11.9111 10.7471 11.8057L11.5205 11.0322C11.626 10.8916 11.626 10.7158 11.5205 10.6104L7.16113 6.25098Z"
                  fill="#AFAFAF"
                />
              </svg>
            </span>
            {{ $t("addressbook.remove") }}
          </button>
        </div>
      </div>
    </div>

    <div v-else class="w100" v-show="!showBanner">
      <div class="mb17 columns is-vcentered ms-0">
        <div class="column">
          <h3 class="m-mt-168 has-text-centered">
            {{ $t("addressbook.noresults") }}
          </h3>
        </div>
      </div>
    </div>

    <div
      class="columns is-vcentered ms-0"
      style="margin-top: 50px; margin-bottom: 50px"
      v-show="!showBanner"
    >
      <div class="column is-6 p-0">
        <span>{{ results }}</span>
      </div>
      <div class="column is-6 p-0 cont-bottom-r">
        <Pagination
          :page_size="page_size"
          :page="page"
          :pages="pages"
          @page="updatePage"
          :key="pages"
        />
      </div>
    </div>
  </div>
  <AddModal :show="showAddModal" @close="closeAddModal()" />
  <EditModal :show="showEditModal" @closeEdit="closeEditModal()" :selected="selected.toString()" />
  <ImportModal :show="showImport" @closeImport="closeImportModal()" />
</template>
<script>
import UserService from "@/services/user.service";
import notification from "@/notification.js";
import LoaderComponent from "@/components/LoaderComponent.vue";
import AddModal from "@/components/addressbook/AddModal.vue";
import EditModal from "@/components/addressbook/EditModal.vue";
import ImportModal from "@/components/addressbook/ImportModal.vue";
import Pagination from "@/components/PaginationComponent.vue";
import BannerComponent from "@/components/BannerComponent.vue";

export default {
  components: {
    AddModal,
    EditModal,
    ImportModal,
    Pagination,
    LoaderComponent,
    BannerComponent,
  },
  name: "AddressBook",
  props: {},

  data() {
    return {
      showLoader: true,
      page_size: 10,
      showBanner: false,
      page: 1,
      results: "",
      header: ["id", "Company", "Name", "email", "mobile", "City", "Country"],
      addressbook: [],
      search: "",
      showAddModal: false,
      showEditModal: false,
      selected: "",
      showOptions: "",
      key: 0,
      showImport: false,
    };
  },
  methods: {
    getAddressbook() {
      UserService.getAddressbook().then(
        (response) => {
          this.$store.commit("addAddressbook", response.data);
          this.addressbook = this.$store.state.addressbook;
          if (this.addressbook.length == 0) {
            this.showBanner = true;
          }
          this.pagLength();
          this.showLoader = false;
        },
        (error) => {
          console.error(error);
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.$t("addressbook.server_connection_error"));
          this.showLoader = false;
        }
      );
    },
    editAddressbook(id) {
      this.showSingleAddressOptions(id);
      this.selected = id;
      this.showEditModal = true;
    },
    addAddressModal() {
      this.showAddModal = !this.showAddModal;
    },
    pinAddress(id, pinned) {
      this.showSingleAddressOptions(id);
      const message = !pinned
        ? this.$t("addressbook.pinned_notification")
        : this.$t("addressbook.unpinned_notification");
      const entry = {
        id,
        pinned: !pinned,
      };

      UserService.updateAddressbook(entry).then(
        () => {
          this.getAddressbook();
          notification.toast("success", message);
        },
        (error) => {
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.content);
        }
      );
    },
    deleteAddress(value) {
      const { length } = this.addressbook;

      UserService.delAddressbook(value).then(
        () => {
          notification.toast("success", this.$t("addressbook.removed_notification"));
          this.getAddressbook();
          if (length - 1 <= 10 && this.page > 1) {
            this.page -= 1;
          }
        },
        (error) => {
          this.content = error.response && error.response.data && error.response.data.message;
          error.message || error.toString();
          notification.toast("error", this.content);
        }
      );
    },
    closeAddModal() {
      this.getAddressbook();
      this.showAddModal = false;
      this.key++;
    },
    closeImportModal() {
      this.getAddressbook();
      this.showImport = false;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.selected = "";
    },
    pagLength() {
      const notPage1 = this.page > 1 ? "1" : "";
      let tot = 0;
      if (this.search == "") {
        tot = this.page * 10 > this.addressbook.length ? this.addressbook.length : this.page * 10;

        this.results = `${this.page - 1}${notPage1} to ${tot} from ${
          this.addressbook.length
        } results`;
      } else {
        const addTot = this.page > 1 ? (this.pages - 1) * 10 : 0;
        tot =
          this.page * 10 > this.filteredAddressbook.length
            ? this.filteredAddressbook.length + addTot
            : this.page * 10;
        this.results = `${this.page - 1}${notPage1} to ${tot} from ${
          this.filteredAddressbook.length
        } results`;
      }
    },
    showSingleAddressOptions(value) {
      this.showOptions = this.showOptions == value ? "" : value;
    },
    updatePage(value) {
      this.page = value;
    },
    filtering() {
      if (!this.showAddModal && !this.showEditModal) {
        this.showLoader = true;
        setTimeout(() => {
          this.showLoader = false;

          this.$refs.searchfield.focus();
        }, 100);
      }

      return this.addressbook.filter((o) =>
        Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    checkClick(e) {
      if (e.target.getAttribute("name") != "showMenu") {
        this.showOptions = "";
      }
    },
  },
  mounted() {
    this.getAddressbook();
    this.pagLength();
    window.addEventListener("click", this.checkClick);
  },
  computed: {
    filteredAddressbook() {
      const filtered = this.filtering();
      return filtered.slice(this.indexStart, this.indexEnd);
    },
    pages() {
      const begin = Math.ceil(this.addressbook.length / this.page_size);
      const filtered = this.filtering();
      const filter = Math.ceil(filtered.length / this.page_size);
      this.pagLength();
      return this.search.length > 0 ? filter : begin;
    },
    indexStart() {
      return (this.page - 1) * this.page_size;
    },
    indexEnd() {
      return this.indexStart + this.page_size;
    },
  },
  watch: {
    search() {
      this.page = 1;
      this.pagLength();
    },
  },
};
</script>
<style scoped>
.fit-content {
  width: fit-content;
}
.inline {
  display: inline;
}
.floatr {
  float: right;
}
.mr15 {
  margin-right: 15px;
}
.mt37 {
  margin-top: 37px;
}
.control.has-icons-left .icon,
.control.has-icons-right .icon {
  height: 100% !important;
  right: 10px !important;
}
.mt27 {
  margin-top: 27px;
}
.w100 {
  width: 100%;
}
.mb17 {
  margin-bottom: 17px;
}
.ms-0 {
  margin-left: 0;
  margin-right: 0;
}
.columns:not(:last-child) {
  margin-bottom: 4px;
}
.boxOptions {
  position: absolute;
  right: 14px;
  margin-top: 218px;
  background-color: #f2f2f2;
  width: 223px;
  height: 178px;
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}
.boxOptionsBtn {
  height: 59px;
  width: 223px;
  border: none;
  font-size: 18px;
  line-height: 26px;
  color: #6e6e6e;
  text-align: left;
  padding-left: 20px;
}
.body-p {
  max-width: 425px;
}
.is-bottom {
  position: fixed;
  bottom: calc(14px + 35px);
  right: 0;
  width: calc(100% - 288px);
  z-index: 999;
}
.cont-bottom-l {
  padding-left: 80px !important;
}
.cont-bottom-r {
  text-align: right;
}
@media (max-width: 414px) {
  .sar-top {
    margin-bottom: 14%;
  }
}
</style>
